import React from "react";
import NotificationApp from "./Notifications.js";
import {Box,Grommet,Text} from "grommet"
import { Divider } from '@mui/material';

const theme = {
    global: {
      colors: {
        brand: '#397aa3',
        'accent-1': 'white',
        'focus': '#397aa3',
        "my-text-color": "",
        background: '#ecedf3',
      },
      font: {
        family: '',
        size: '18px',
        height: '20px',
      },
      menu:{
        icons:{
          color:'white',
          },
      },
    },
  };

  /**
   * Componente che mostra tutte le notifiche dell'utente
   */
const Notification=(props)=>{
    return(
        <Grommet theme={theme}>          
            <Box height="98vh" width="100%" direction="row" >
                <Box width="100%" overflow="auto" aligns="center" pad="medium">
                    <Text color="#397aa3" size="large" weight="bold" alignSelf="start">NOTIFICHE</Text>
                    <Divider/>
                    <NotificationApp notifications={props.notifications} getNotification={props.getNotification}/>
                </Box>
            </Box>
        </Grommet>
    )
}

export default Notification;