import {Box, Button, Text} from "grommet";
import {List} from "grommet-icons";
import React from "react";

const SideBarButton = (props) => {
    const Icon = props?.icon;

    const badge = props.responseNotRead && props.responseNotRead.length > 0 ? {
        background: 'status-critical',
        value: props.responseNotRead.length,
        max: 99,
        size: 'small'
    } : undefined;

    return (
        <Button 
            style={{
                width: !props?.smallSideBar ? '200px' : '50px',
                fontSize: '10px',
                background: props?.pageName === props?.actualPage ? '#397aa3' : '#FFFFFF',
                color: 'white',
                fontWeight: 'bold',
                textTransform: 'uppercase',
                borderRadius: '20px'
            }}
            badge={badge}
            onClick={() => {
                props?.setPage(props?.pageName);
            }}
        >
            <Box pad="10px" direction="row" align="center" gap="medium">
                {Icon && <Icon color={props?.pageName !== props?.actualPage ? 'black' : '#FFFFFF'} />}
                {
                    !props?.smallSideBar &&
                    <Text size="xsmall" color={props?.pageName !== props?.actualPage ? 'black' : '#FFFFFF'}>
                        {props?.name}
                    </Text>
                }
            </Box>
        </Button>
    );
};

export default SideBarButton;
