import React, { useState } from "react";
import {
  Box,
  DateInput,
  Heading,
  Form,
  FormField,
  TextInput,
  Button,
  Select,
  CheckBox,
  Anchor,
  FileInput
} from "grommet";
import moment from 'moment';
import { useLazyQuery,useMutation } from '@apollo/client';
import {QUERY_MACHINE_TYPE, UPSERT_MACHINE, QUERY_JOBS_MACHINE_V2, QUERY_CANEDGE} from "./machineGQL"
import TreeViewAree from "../../treeView/TreeView";
import { GET_TREE } from "../aree/areeGQL";
import { tenant, tenantTypeFirstUpperCase } from "../../tenant";
import {
  extractIdFormData,
  GOLF_ADD_ATTACHMENT,
  GOLF_GET_ATTACHMENT,
  QUERY_CONTENT_TYPE,
  sortTree
} from "../../../utilis/utils";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download.js";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import FolderIcon from "@mui/icons-material/Folder.js";
import ListItemText from "@mui/material/ListItemText";
import {Typography} from "@mui/material";

export default function AddMac(props) {

  const [formData,setFormData]=React.useState({hourly_cost:0,hourly_rental_cost:0,accessories:false});
  const [upsertMutationMachine]=useMutation(UPSERT_MACHINE);
  const [machineTypeList,setMachineTypeList]=React.useState([]);
  const [canEdgeList,setCanEdgeList]=React.useState([]);
  const [canSel,setCanSel]=React.useState(null);
  const [jobsList,setJobsList]=React.useState([]);
  const [selectedNodes,setSelectedNodes]=React.useState([]);
  const [selectedJobs,setSelectedJobs]=React.useState([]);
  const [tree,setTree]=React.useState([]);
  const [clickedNodes,setClickedNodes]=React.useState([]);
  const [contentType,setContentType]=React.useState();
  const [elencoFile,setElencoFile]=React.useState([]);
  const [addAttachment]=useMutation(GOLF_ADD_ATTACHMENT);
  const [ queryGetMachineType
  ] = useLazyQuery(QUERY_MACHINE_TYPE, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => { 
      setMachineTypeList(data["machineType_machinetype"]);
    },
    notifyOnNetworkStatusChange: true, // did the work
  });
  const [ queryGetTree
  ] = useLazyQuery(GET_TREE, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const tree=data[`${tenant}`]["whoami"].profile.treeArea   //[`areas${tenantTypeFirstUpperCase}First`][0].trees;
      if(tree){
          setTree(JSON.parse(tree).tree);
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });
  const [ queryFilterJobs
  ] = useLazyQuery(QUERY_JOBS_MACHINE_V2, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setJobsList(data[`${tenant}_jobs_jobgolf`])
      //setJobsList(data[`${tenant}_jobs_jobgolf`])
    },
    notifyOnNetworkStatusChange: true, // did the work
  });
  const [ queryGetCanedge
  ] = useLazyQuery(QUERY_CANEDGE, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => { 
      const canListData=data[`${tenant}`]["canList"]
      const canList=[];
      for(let i=0;i<canListData.length;i++){
        canList.push({id:canListData[i].id,can_number:canListData[i].canNumber})
      }
      setCanEdgeList(canList);
    },
    notifyOnNetworkStatusChange: true, // did the work
  });

   const [queryGetContentType] = useLazyQuery(QUERY_CONTENT_TYPE, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      let contentType=data[`django_content_type`][0]
      if(contentType){
        setContentType(contentType);
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });

   const [ queryGetAttachments
    ] = useLazyQuery(GOLF_GET_ATTACHMENT, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const attachments=data[`${tenant}_attachments_attachment`]
      if(attachments){
        setElencoFile(attachments);
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });

  const onChangeTextInput=(event)=>{
    if(event.target.type==="number"){
      setFormData({...formData,[event.target.name]:parseFloat(event.target.value)})
    }else if(event.target.type==="date"){
      setFormData({...formData,[event.target.name]:moment(event.target.value).format("YYYY-MM-DD")})
    }else{
      setFormData({...formData,[event.target.name]:event.target.value})
    }
  }


  const onChangeCheckBox=(event)=>{
    setFormData({...formData,[event.target.name]:event.target.checked})
  }

  function upsertMachine(){
   
    if ( formData?.chassis_number && formData.hourly_cost!==undefined && formData.name && formData.machine_type){
      let obj=extractIdFormData({...formData,aree:JSON.stringify({areeIds:selectedNodes}),jobs:JSON.stringify({jobsIds:selectedJobs.map((job)=>job.id)}),can:JSON.stringify({canId:canSel || {}})},true)
      delete obj.variables.machinetypeMachinetype
      delete obj.variables.machinesCanmachines
      delete obj.variables.machinesMachinejobs
      delete obj.variables._Typename
      delete obj.variables.machinesMachineareas
      delete obj.variables.cansel;
      delete obj.variables.job_id;
      console.log("OBJ",obj);
      upsertMutationMachine(obj)
      .then((value)=>{
        if(props.macchina){
            alert("Macchinario aggiornato correttamente");
            if(contentType) {
              for (let i = 0; i < elencoFile.length; i++) {
                addAttachment({
                  variables: {
                    name: elencoFile[i].name,
                    contentType: contentType.id,
                    objectId: props.macchina.id,
                    attachment: JSON.stringify(elencoFile[i])
                  }
                })
                    .then((value) => console.log(value))
                    .catch((error) => console.log(error))
              }
            }
        }else{
          alert("Macchinario aggiunto correttamente");
         /* if(contentType) {
              for (let i = 0; i < elencoFile.length; i++) {
                addAttachment({
                  variables: {
                    name: elencoFile[i].name,
                    contentType: contentType.id,
                    objectId: value["data"][tenant]["upsertMachine"+tenantTypeFirstUpperCase],
                    attachment: JSON.stringify(elencoFile[i])
                  }
                })
                    .then((value) => console.log(value))
                    .catch((error) => console.log(error))
              }
            }*/
        }
        onEscLayer();
      })
          .catch((value)=>{
        console.error(value);
        alert("Errore durante l'operazione");
      })
    }else{
      alert("Compila i campi obbligatori")
    }
  }

  React.useEffect(()=>{
    queryGetMachineType();
    queryGetCanedge();
    if(props.macchina){
      let areaNodes=props.macchina?.machines_machineareas?.map((machineArea)=>machineArea.area_areagolf.id);
      setSelectedNodes(areaNodes);
      setFormData({...props.macchina,machine_type:props.macchina.machineType_machinetype})
      setCanSel(props.macchina.canSel);
      queryGetContentType({variables:{model:"machinegolf"}});
    }
  },[tree])

  React.useEffect(()=>{
     if(contentType && props.macchina?.id){
         queryGetAttachments({variables:{contentTypeId:contentType?.id,objectId:props.macchina.id}})
      }
  },[contentType])

  React.useEffect(()=>{
    let leafNodes=filterLeadIds(selectedNodes);
    queryFilterJobs({variables:{areeId:leafNodes}})
  },[selectedNodes])

  React.useEffect(()=>{
    if(props.macchina && selectedJobs.length===0) {
      setSelectedJobs(props.macchina?.machines_machinejobs?.map((machineJob) => machineJob.jobs_jobgolf))
    }
  },[jobsList])

   function onEscLayer(){
    if(props.onEscLayer){
        props.onEscLayer();
    }
    setFormData({});
  }

  function downloadFile(allegatoSel){
        window.open("/media/"+allegatoSel.attachment)
  }

  function filterLeadIds(areaNodes){
     let leafNode=[];
     getLeafIds(tree[0],leafNode);
     let leafIds=leafNode.filter((id)=>areaNodes.includes(id));
     return leafIds
  }

  function getLeafIds(node,leafIds=[]){
    if(Array.isArray(node?.children) && node?.children.length>0){
      for (const element of node.children) {
        getLeafIds(element,leafIds)
      }
    }else if(Array.isArray(node?.children) && node?.children.length===0){
      leafIds.push(node?.id)
    }
  }

  return (
    <Box round pad="medium" direction="column" background="white" align="center" overflow="scroll">
      <Heading level="2" margin="none" size="small">
        <Box direction="row" gap="large">
          {!props.macchina?"Inserisci il macchinario":"Aggiorna il macchinario"}
         {/* <Button label="Scarica modello" href={"./modelliCsv/Modello_Macchinari.zip"} style={{width:'200px',fontSize:'20px',background:'#397aa3',color:'white',fontWeight:'bold',textTransform: 'uppercase',textAlign:"center"}}></Button> */}
        </Box>
      </Heading>
      <Box fill gap="medium" pad={{ vertical: "small" }} >
        <Form>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <FormField required label="Nome*">
              <TextInput
                name="name"
                placeholder="es. Tosaerba 2mm"
                value={ formData?.name}
                onChange={e => onChangeTextInput(e)}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Data del Tagliando">
              <TextInput
                  type="date"
                format="dd/mm/yyyy"
                name="car_service"
                value={ formData?.car_service}
                onChange={(e) => onChangeTextInput(e)}
                calendarProps={{ size: "small", locale: "it-IT", daysOfWeek: true, firstDayOfWeek: 1 }}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Telaio*">
              <TextInput
                name="chassis_number"
                placeholder="es. 134528736"
                value={ formData?.chassis_number}
                onChange={e => onChangeTextInput(e)}
              />
            </FormField>
          </Box>
        </Box>
        <Box direction="row" align="center">
          {
            /**
             *<Box pad="xsmall">
                <FormField required label="Ultimo Utilizzo (SOLO per prima creazione)">
                  <DateInput
                    format="dd/mm/yyyy"
                    value={newUltimoUtilizzo}
                    onChange={({ value }) => {setNewUltimoUtilizzo(value.split('T')[0])}}
                    calendarProps={{ size: "small", locale: "it-IT", daysOfWeek: true, firstDayOfWeek: 1 }}
                  />
                </FormField>
              </Box>
             */
          }     
          <Box pad="xsmall" direction="row" gap="small">
            <FormField required label="Can ID">
              {
                canSel?
                <TextInput
                  placeholder=""
                  value={ canSel?.can_number}
                />
                :
                <Select
                  name="can_id"
                  options={canEdgeList}
                  labelKey="can_number"
                  value={canSel}
                  onChange={e =>setCanSel(e.value)}
                />
              } 
              { canSel &&
                <Anchor onClick={() =>setCanSel(null)} label="Rimuovi canedge" />
              }
            </FormField>
            <Box direction="column">
              <FormField required label="Modello*">
                <Select
                  name="machine_type"
                  options={machineTypeList}
                  labelKey="type_name"
                  value={ formData?.machine_type}
                  onChange={e =>setFormData({...formData,machine_type:e.value})}
                />
              </FormField>
            </Box>
          </Box>
        </Box>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <FormField required label="Dimensione Di Taglio (cm)">
              <TextInput
                type="number"
                name="cut_size"
                placeholder="es. 150"
                value={ formData?.cut_size}
                onChange={e => onChangeTextInput(e)}
              />
            </FormField>
          </Box>
        </Box>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <FormField required label="Alimentazione">
              <TextInput
                name="fuel"
                placeholder="es. Benzina"
                value={ formData?.fuel}
                onChange={e => onChangeTextInput(e)}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Anno (acquisto)">
              <TextInput
                name="year"
                placeholder="es. 2015"
                value={formData?.year}
                onChange={e => setFormData({...formData,year:parseInt(e.target.value)})}
              />
            </FormField>
          </Box>
        </Box>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <FormField required label="Note">
              <TextInput
                name="notes"
                placeholder="es. Note Aggiuntive"
                value={formData?.notes}
                onChange={e => onChangeTextInput(e)}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Costo Orario €/h (interno) *">
              <TextInput
                type="number"
                name="hourly_cost"
                placeholder="es. 5,5"
                value={formData?.hourly_cost}
                onChange={e => onChangeTextInput(e)}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Valore Macchina €">
              <TextInput
                type="number"
                name="machine_value"
                placeholder="es. 15.000,00"
                value={formData?.machine_value}
                onChange={e => onChangeTextInput(e)}
              />
            </FormField>
          </Box>
        </Box>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <FormField required label="Tariffa Oraria Noleggio (€/h)">
              <TextInput
                type="number"
                name="hourly_rental_cost"
                placeholder="es. 7,5"
                value={formData?.hourly_rental_cost}
                onChange={e => onChangeTextInput(e)}
              />
            </FormField>
          </Box>
            <Box pad="xsmall">
              <FormField required label="Lavori Eseguibili">
                <Select
                  multiple={true}
                  closeOnChange={false}
                  options={jobsList}
                  value={selectedJobs}
                  labelKey="name"
                  onChange={(event) => {setSelectedJobs(event.value)}}
                />
              </FormField>
            </Box>
        </Box>
          <Box pad="xsmall">
            <FormField required label="Codice gestionale esterno">
              <TextInput
                name="external_erp_code"
                value={formData && formData.external_erp_code}
                onChange={e => onChangeTextInput(e)}
              />
            </FormField>
          </Box>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <CheckBox
              name="accessories"
              checked={formData?.accessories}
              label="Selezionare se il macchinario può montare accessori"
              onChange={(e) => onChangeCheckBox(e)}
            />
          </Box>
        </Box>
        <Box style={{overflowX:"hidden"}} overflow="auto" >
          <TreeViewAree clickedNodes={clickedNodes} setClickedNodes={setClickedNodes} checkAssignedAree={true} selectedNodes={selectedNodes} setSelectedNodes={setSelectedNodes} addElement={false} loadTree={queryGetTree} tree={tree} singleChildForNode={false} multiSelect={true}/>
        </Box>
          {
            props.macchina &&
                <FileInput
              multiple
              name="file"
              onChange={event => {
                const fileList = event.target.files;
                const file=fileList[0];
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = function () {
                    const result=reader.result;
                   let fileObj={
                        name: file.name,
                        type:file.type,
                        content:result,
                        size:file.size,
                        modified: file.lastModifiedDate,
                    }
                    let elencoFileApp=[...elencoFile]
                    elencoFileApp.push(fileObj);
                  setElencoFile(elencoFileApp);
                }
              }
              }
            />
          }
          {
                      elencoFile.length>0?

                        <List dense={false}>
                        {elencoFile.map((allegato)=>
                          <ListItem
                            secondaryAction={
                              <Box gap="small" direction="row">
                                <IconButton edge="end" aria-label="delete" onClick={()=>downloadFile(allegato)}>
                                  <DownloadIcon />
                                </IconButton>
                            </Box>
                            }
                          >
                            <ListItemAvatar>
                              <Avatar>
                                <FolderIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={<Typography variant="h6" style={ allegato.name.length>=50? { fontSize:"10px"}:{ fontSize:"15px"}}>{allegato.name}</Typography>}
                            />
                          </ListItem>
                        )}
                      </List>

                    :
                    null
                  }
        </Form>
        <Box direction="row" gap="medium" alignSelf="end">
          <Button label={!props.macchina?"Aggiungi":"Aggiorna"} onClick={upsertMachine} style={{width:'138px',height:'34px',fontSize:'15px',background:'#397aa3',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}/>
        </Box>
      </Box>
    </Box>
  )
}