import { gql } from '@apollo/client';
import { tenant,tenantType,tenantTypeFirstUpperCase } from "../tenant"

const GEO_REF_TENANT=gql`
query AreasGolf($areeId:[bigint!]) {
    ${tenant}_area_area${tenantType}(where: {coordinates: {_is_null: false},area_area${tenantType}:{id:{_in:$areeId}}}) {
      id
      coordinates
      home_coordinates
      name
      type_id
      color
    }
  }
`
const TRACK_AREA=gql`
mutation TrackArea($id:bigint!,$homeCoordinates:geometry,$coordinates:geometry,$color:String) {
  update_${tenant}_area_area${tenantType}_by_pk(pk_columns: {id: $id}, _set: {home_coordinates: $homeCoordinates, coordinates:$coordinates,color:$color }) {
    id
  }
}
` 

const GET_HOME=gql` 
query GetHome($id:Int!){
  ${tenant}{
    area${tenantTypeFirstUpperCase}(id:$id){
      id
      homeCoordinates
      recursiveHomeCoords
      name
      type {
        id
      }
      coordinates
      color
    }
  }
}`

export {GEO_REF_TENANT,TRACK_AREA,GET_HOME}
