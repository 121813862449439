import React from "react";
// import { createApolloClient } from "./components/apolloClient/apolloClient";
import HomePage from "./Home";



function App(props){
  return(  
    <HomePage />
  )
}

export default App;