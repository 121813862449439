import React, { useState, useEffect } from "react";
import { Box, Grid, Grommet, Text, Table, TableHeader, TableBody, TableRow, Select, TableCell, Button, Layer, ResponsiveContext, DropButton, Tab,Tabs,DateInput } from 'grommet';
import { FormClose, Configure, Clock as ClockIcon } from "grommet-icons";
import AddEse from "../registryComponent/maintenance/AddEse.js";
import {
    Divider,
    CircularProgress,
} from "@material-ui/core";
import AddRil from "../registryComponent/maintenance/AddRil.js";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useLazyQuery } from '@apollo/client';
import {QUERY_MACHINE, GET_MACHINE_MAINTENANCE} from "../registryComponent/maintenance/maintenanceGQL.jsx";
import { tenant,tenantType,tenantTypeFirstUpperCase  } from "../tenant.js"
import DashboardCanEdge from "../4_0_components/dashboard/Dashboard";
import ExecutedMaintenanceList from "./ExecutedMaintenanceList.jsx";
import ReliefHoursList from "./ReliefHourList.jsx";

const theme = {
  global: {
    colors: {
      brand: '#397aa3',
      'accent-1': 'white',
      'focus': '#397aa3',
      "my-text-color": "",
      background: '#ecedf3',
    },
    font: {
      family: '',
      size: '18px',
      height: '20px',
    },
    menu:{
      icons:{
        color:'white',
        },
    },
  },
};


const App = (props) => {
  const [interventi,setInterventi] = useState([]);

  const [macchinari, setMacchinari] = useState([]);
  const [dropdownMenu,setDropDownMenu]=React.useState("");
  const [mostraLayer,setMostraLayer]=React.useState(false);
  const [mostraLayerRilievo,setMostraLayerRilievo]=React.useState(false);
  const [selectedTab,setSelectedTab]=React.useState(0);
  const [macchinaSelOre,setMacchinaSelOre]=React.useState();
  const [nessunDato,setNessunDato]=React.useState(false);
  const [showLayerCaricamento,setShowLayerCaricamento]=React.useState(false);
  const [aree,setAree]=React.useState([]);
  const [machineHour,setMachineHour]=React.useState(0);

  const [ queryGetMachine
  ] = useLazyQuery(QUERY_MACHINE, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setDropDownMenu("");
      setNessunDato("");
      const machines=data[`${tenant}_machines_machinearea`].map((machine)=>machine[`machines_machine${tenantType}`]);
      const maintenance=data[`${tenant}_machines_machinearea`].map((machine)=>machine[`machines_machine${tenantType}`]["machines_maintenancegolves"]);
      if(machines){
        setMacchinari(machines);
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });


  const [ getMachineMaintenance
  ] = useLazyQuery(GET_MACHINE_MAINTENANCE, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setMachineHour(data[tenant]["machine"+tenantTypeFirstUpperCase].hours)
      const maintenaceStatus=data[tenant]["machine"+tenantTypeFirstUpperCase].maintenancesStatus
      setNessunDato(maintenaceStatus.length===0);
      setInterventi(maintenaceStatus)
    },
    notifyOnNetworkStatusChange: true, // did the work
  });

    React.useEffect(()=>{
      queryGetMachine({variables:{area:props.aree.length>0? props.aree : JSON.parse(sessionStorage.getItem("areeId"))}});
      },[props.aree])

    //Funzione per  i tab
    const onActive = (nextIndex) =>{
      setSelectedTab(nextIndex);
      if(nextIndex!==0){
        setDropDownMenu("");
        setNessunDato("");
      }
     }


  React.useEffect(()=>{
      if(dropdownMenu){
          getMachineMaintenance({variables:{id:dropdownMenu.id}})
      }
  },[dropdownMenu])


  return (
    <Grommet theme={theme} themeMode="dark" className="font-link">
      <ResponsiveContext.Consumer>
        {size => (
        <Box flex={false} fill>
            <Tabs activeIndex={selectedTab} onActive={onActive}>
                <Tab title="Manutenzione">
                  <Box direction="row">
                  <Box direction='row' flex overflow={{ horizontal: 'hidden' }}>
                  <Box flex height="92.5vh">
                    <Box flex="false" pad="medium" direction="row" gap="medium" align="center">
                      <Text color="#397aa3" margin="small" style={{fontWeight:'bold'}}>FILTRO MACCHINARIO: </Text>
                      <Select
                          placeholder="Seleziona macchinario"
                          options={[... new Set(macchinari.sort(function(a, b) { return a.name.localeCompare(b.name)}))]}
                          value={dropdownMenu}
                          labelKey={(option)=>option && `${option.name} ${option.chassis_number}`}
                          onChange={event =>{
                              setDropDownMenu(event.value)
                            }
                          }
                        />
                        <Divider orientation="vertical"/>
                        <Button style={{width:'350px',fontSize:'10px',background:"#ecedf3",color:'white',fontWeight:'bold',textTransform: 'uppercase',borderStyle: 'solid',borderColor:'#397aa3',borderWidth:"3px"}} onClick={()=>setMostraLayer(true)}>
                          <Box pad="small" direction="row" align="center" gap="medium">
                              <Text size="small" color="#397aa3">INSERISCI INTERVENTO DI MANUTENZIONE</Text>
                          </Box>
                        </Button>
                        <Button style={{width:'250px',fontSize:'10px',background:"#ecedf3",color:'white',fontWeight:'bold',textTransform: 'uppercase',borderStyle: 'solid',borderColor:'#397aa3',borderWidth:"3px"}} onClick={()=>{setMostraLayerRilievo(true);setMacchinaSelOre("")}}>
                          <Box pad="small" direction="row" align="center" gap="medium">
                              <Text size="small" color="#397aa3">INSERISCI RILIEVO ORE</Text>
                          </Box>
                        </Button>
{
                        mostraLayer &&
                          <Layer full={window.screen.width<1100} onEsc={()=>setMostraLayer(false)} onClickOutside={()=>setMostraLayer(false)}>
                          <Box width="80vw" height="100%" round="medium" pad="15px" background="src/components#ecedf3">
                            <Button  alignSelf="end" icon={<FormClose color="grey" size="35px" onClick={()=>setMostraLayer(false)}/>}></Button>
                            <Box round="medium" margin="small" alignSelf='center' background="white">
                              <AddEse reloadMaintenance={()=>getMachineMaintenance({variables:{id:dropdownMenu.id}})} onEscLayer={setMostraLayer} machine={macchinaSelOre} queryGetMachine={()=> {queryGetMachine({variables:{area:aree.map((el)=>parseInt(el.area.id))}});getMachineMaintenance({variables:{id:dropdownMenu.id}})}}/>
                            </Box>
                          </Box>
                      </Layer>

                      }
                       {
                        mostraLayerRilievo && (
                          <Layer full={window.screen.width<1100?true:false} onEsc={()=>setMostraLayerRilievo(false)} onClickOutside={()=>setMostraLayerRilievo(false)}>
                          <Box height="100%" round="medium" pad="15px" background="src/components#ecedf3">
                            <Button  alignSelf="end" icon={<FormClose color="grey" size="35px" onClick={()=>setMostraLayerRilievo(false)}/>}></Button>
                            <Box round="medium" margin="small" alignSelf='center' background="white">
                              <AddRil ruolo={props.ruolo}  getMachineMaintenance={()=>getMachineMaintenance({variables:{id:dropdownMenu.id}})} parcoMacchine={true} Telaio={(macchinaSelOre!==undefined && macchinaSelOre.Telaio!==undefined)?macchinaSelOre.Telaio:""} Modello={(macchinaSelOre!==undefined && macchinaSelOre.NomeMacchina!==undefined)?macchinaSelOre.NomeMacchina:""}  onEscLayer={setMostraLayerRilievo} />
                            </Box>
                          </Box>
                      </Layer>
                        )

                      }
                      </Box>
                    <Box pad="small" align="center">
                      {
                        nessunDato?
                          <Box margin="3%" alignSelf="center">
                            <Text weight="bold">NESSUN INTERVENTO PRESENTE A SISTEMA PER QUESTA MACCHINA</Text>
                          </Box>
                        :
                      !nessunDato && dropdownMenu!=="" &&
                      <Grid
                        rows={['auto', 'auto']}
                        columns={[ 'auto','auto', 'auto', 'auto']}
                        gap="medium"
                        pad="small">
                      {

                            interventi.map((intervento,index) => {
                              const percentage1=intervento.interval.interval * 0.6;
                              const percentage2=intervento.interval.interval * 0.8;
                              return(
                              <Box height="300px" direction="column" align="center">
                                <CircularProgressbar
                                  value={intervento?.elapsedHours}
                                  maxValue={intervento?.interval?.interval}
                                  minValue={0}
                                  text={machineHour.toFixed(2)+" / "+intervento.interval.interval+" ore"}
                                  strokeWidth="9"
                                  styles=
                                  {intervento?.elapsedHours<=percentage1?
                                    buildStyles({
                                      textSize: '8px',
                                      textColor:'green',
                                      pathColor: `green`,
                                    })
                                  :intervento?.elapsedHours>percentage1 && intervento?.elapsedHours<=percentage2?
                                    buildStyles({
                                      textSize: '8px',
                                      textColor:'orange',
                                      pathColor: `orange`,
                                    })
                                  :
                                  buildStyles({
                                    textSize: '8px',
                                    textColor:'red',
                                    pathColor: `red`,
                                  })
                                  }
                                    />
                                <br/><br/>
                                <Text>{intervento.interval.typeIntervention.code}</Text>
                              </Box>
                             )}


                              )}
                    {/*/*TK-24 Modulo per la gestione della manutenzione - END*/}
                      </Grid>
                         }
                    </Box>
                  </Box>
                  </Box>
              </Box>
              </Tab>
                 <Tab title="Registro Manutenzioni Eseguite">
                   <ExecutedMaintenanceList aree={props.aree}/>
                 </Tab>
                 <Tab title="Registro Rilievi Ore">
                   <ReliefHoursList aree={props.aree}/>
                 </Tab>
              <Tab title="Parco Macchine">
              <Box width="100%" height="94vh" align="" pad="medium" overflow="auto">
                <Table>
                  <TableHeader style={{fontWeight:'bold', color:'#397aa3'}}>
                    <TableRow>
                      <TableCell scope="col" border="bottom">
                        Nome Macchina
                      </TableCell>
                      <TableCell scope="col" border="bottom">
                        Telaio
                      </TableCell>
                      <TableCell scope="col" border="bottom">
                        Ore segnate
                      </TableCell>
                      <TableCell scope="col" border="bottom"/>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {macchinari.map(macchina => {
                        let maxMaintenance=macchina?.machines_maintenancegolves.slice(-1) && macchina.machines_maintenancegolves.slice(-1)[0] && macchina.machines_maintenancegolves.slice(-1)[0].machine_hour
                        let maxRelief=macchina?.machines_reliefhours.slice(-1) && macchina.machines_reliefhours.slice(-1)[0] && macchina.machines_reliefhours.slice(-1)[0].machine_hour;
                        return(
                       <TableRow>
                        <TableCell scope="col" border="bottom">
                          {macchina.name}
                        </TableCell>
                        <TableCell scope="col" border="bottom">
                          {macchina.chassis_number}
                        </TableCell>
                        <TableCell scope="col" border="bottom">
                          {maxMaintenance>maxRelief?maxMaintenance:maxRelief || 0}
                        </TableCell>
                        <TableCell scope="col" border="bottom">
                          <Box direction="row" gap="medium">
                            <Button style={{width:'200px',fontSize:'10px',background:'#397aa3',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={() =>{setMacchinaSelOre(macchina);setMostraLayer(true)}}>
                              <Box pad="small" direction="row" align="center" gap="small">
                                  <Configure color="white" />
                                  <Text size="small" color="white">Manutenzione</Text>
                              </Box>
                            </Button>
                            <Button style={{width:'200px',fontSize:'10px',background:'#397aa3',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={()=>{setMacchinaSelOre(macchina);setMostraLayerRilievo(true)}}>
                              <Box pad="small" direction="row" align="center" gap="small">
                                  <ClockIcon color="white" />
                                  <Text size="small" color="white">Rilievo ore</Text>
                              </Box>
                          </Button>
                        </Box>
                        </TableCell>
                      </TableRow>
                        )})}
                  </TableBody>
                </Table>
              </Box>
                    {
                        mostraLayer &&
                          <Layer full={window.screen.width<1100} onEsc={()=>setMostraLayer(false)} onClickOutside={()=>setMostraLayer(false)}>
                          <Box width="80vw" height="100%" round="medium" pad="15px" background="src/components#ecedf3">
                            <Button  alignSelf="end" icon={<FormClose color="grey" size="35px" onClick={()=>setMostraLayer(false)}/>}></Button>
                            <Box round="medium" margin="small" alignSelf='center' background="white">
                              <AddEse reloadMaintenance={()=>getMachineMaintenance({variables:{id:dropdownMenu.id}})} onEscLayer={setMostraLayer} machine={macchinaSelOre} queryGetMachine={()=> {queryGetMachine({variables:{area:aree.map((el)=>parseInt(el.area.id))}});getMachineMaintenance({variables:{id:dropdownMenu.id}})}}/>
                            </Box>
                          </Box>
                      </Layer>

                      }
              {
                mostraLayerRilievo && (
                  <Layer full={window.screen.width<1100} onEsc={()=>setMostraLayerRilievo(false)} onClickOutside={()=>setMostraLayerRilievo(false)}>
                  <Box height="100%" round="medium" pad="15px" background="src/components#ecedf3">
                    <Button  alignSelf="end" icon={<FormClose color="grey" size="35px" onClick={()=>setMostraLayerRilievo(false)}/>}></Button>
                    <Box round="medium" margin="small" alignSelf='center' background="white">
                      <AddRil ruolo={props.ruolo} getMachineMaintenance={()=>getMachineMaintenance({variables:{id:dropdownMenu.id}})} parcoMacchine={true} machine={macchinaSelOre}  onEscLayer={setMostraLayerRilievo} />
                    </Box>
                  </Box>
              </Layer>
                )
              }
              </Tab>
                    <DashboardCanEdge aree={props.aree}/>
                   {showLayerCaricamento && (
                       <Layer onEsc={()=>setShowLayerCaricamento(false)}>
                        <Box align="center" gap="medium" pad="small">
                          <CircularProgress style={{'color': 'green'}} variant="determinate" value={90} />
                          <Text>Caricamento...</Text>
                        </Box>
                      </Layer>
                    )
                    }
            </Tabs>
        </Box>
        )}
      </ResponsiveContext.Consumer>
    </Grommet>
  );
}

export default App;
