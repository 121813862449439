import React from "react";
import {useLazyQuery, useMutation, useSubscription} from "@apollo/client";
import {Box, Grommet, Text, Layer} from "grommet";
import {DataGrid} from "@mui/x-data-grid";
import {tenant, tenantType} from "../tenant.js";
import {Button as ButtonMui, Divider} from "@mui/material";

function MovementsActivity(props){


    const columnsMovements = [
         { field: 'name', headerName: 'Nome Prodotto', width: 300,
           renderCell: (cellValues) => {
            return(
                 <Box width="150px">
                    <Text size={"small"}>{cellValues.row[`products_product`]["name"]}</Text>
                  </Box>
            )
            }
         },
        { field: 'code', headerName: 'Codice Prodotto', width: 300,
           renderCell: (cellValues) => {
            return(
                 <Box width="150px">
                    <Text size={"small"}>{cellValues.row[`products_product`]["code"]}</Text>
                  </Box>
            )
            }
         },
    { field: 'quantity', headerName: 'Quantità', width: 150 },
  ];

  function onEscLayer(){
    if(props.onEscLayer){
        props.onEscLayer();
    }
  }

    return (
        <Layer onEsc={onEscLayer} onClickOutside={onEscLayer}>
            <Box width={"90%"} overflow="hidden" aligns="center" pad="small" margin="10px">
              <Text color="#397aa3" size="large" weight="bold" alignSelf="start">MOVIMENTI ATTIVITÀ</Text>
              <Divider/>
            </Box>
            <Box pad={"small"} overflow="auto" height="83vh" width="100%" alignSelf='center' style={{marginTop:'40px'}}>
                <div style={{ height: '75vh', width: '70vw' }}>
                  <DataGrid
                    rows={props.movements || []}
                    columns={columnsMovements}
                  />
                </div>
            </Box>
        </Layer>
    )
}

export default MovementsActivity;

