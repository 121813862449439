import React from "react";
import {Layer,Box,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    Button,
    TableRow} from "grommet"
import moment from "moment";

function LayerIntersect({intersect,onEscLayer}){

    React.useEffect(()=>{
        console.log("INTERSECT",intersect);
    },[intersect])

    function convertTime(seconds){

        const duration = moment.duration(seconds, 'seconds');
        const hours = Math.floor(duration.asHours());
        const minutes = duration.minutes();

        return `${hours} ore ${minutes} minuti`;
    }

    return(
        <Layer onClickOutside={onEscLayer}>
            <Box  overflow={"auto"} height="50%"round="medium" pad="30px" background="#ecedf3">
                <Table>
                    <TableHeader>
                        <TableRow>
                        <TableCell scope="col" border="bottom">
                            Area
                        </TableCell>
                        <TableCell scope="col" border="bottom">
                            Percentuale completamento
                        </TableCell>
                            {/*
                        <TableCell scope="col" border="bottom">
                            Tempo trascorso nell'area
                        </TableCell>*/}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {
                            intersect.map((el, index)=>{
                                return(
                                    <TableRow key={index}>
                                        <TableCell scope="row">
                                            <strong>{el.area_name}</strong>
                                        </TableCell>
                                        <TableCell>
                                            {(el.percentage * 100).toFixed(2)} %
                                        </TableCell>
                                        {/*<TableCell>
                                            {convertTime(el.time_spent)}
                                        </TableCell>*/}
                                    </TableRow>
                                )
                            })
                        }
                    
                        </TableBody>
                    </Table>
                    <Button margin='medium' alignSelf="center" label="Chiudi" onClick={onEscLayer} style={{ borderColor:"#c51d34",padding:'7px',fontSize:'15px',/*marginLeft: 'auto',*/background:'#c51d34',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}></Button>
            </Box>
        </Layer>
    )
}

export default LayerIntersect;