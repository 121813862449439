import React, { useState, useEffect } from "react";
import { Map, GoogleApiWrapper, Polyline, Polygon } from 'google-maps-react';
import { Box } from "grommet";
import { useLazyQuery } from '@apollo/client';
import {tenant, tenantType, tenantTypeFirstUpperCase} from "../tenant";
import { GET_HOME } from "./geoRefGQL";

const App = (props) => {

	  const [coordinate, setCoords] = useState([]);
	  const [polygons, setPolygons] = useState([]);
	  const [home,setHome]=React.useState();
	  const [ queryGetHome
	  ] = useLazyQuery(GET_HOME, {
		fetchPolicy: "no-cache",
		onCompleted: (data) => {
		  const coordinates=data[`${tenant}`][`area${tenantTypeFirstUpperCase}`]?.coordinates?.coordinates.map((polygon)=>polygon)
		  if(coordinates){
			const coordsApp=[];
				  for(let i=0;i<coordinates.length;i++){
					  for(let z=0;z<coordinates[i].length;z++){
						  const polygonPath=coordinates[i][z];
						  const path=[];
						  for(let j=0;j<polygonPath.length;j++){
							if(polygonPath[j][0]>30){
                                path.push({lat:polygonPath[j][0],lng:polygonPath[j][1]})
                            }else{
                                path.push({lat:polygonPath[j][1],lng:polygonPath[j][0]})
                            }
						  }
						  coordsApp.push({path:path,color:data[`${tenant}`][`area${tenantTypeFirstUpperCase}`].color || "#000000"});
					  }
				  }
			if(coordsApp){
				console.log("COORDAPP",coordsApp);
			  setPolygons(polygons.concat(coordsApp));
			}
		  }
		},
		notifyOnNetworkStatusChange: true, // did the work
	  });
	  /*const home = {lat: 45.1133724, 
	      			lng: 10.6885045};*/
		function getCoords() {
			const coordsProps=props.coordinates;
			const coords=[];
			for (let i=0;i<coordsProps.length;i++){
				for(let j=0;j<coordsProps[i].length;j++){
					const point=coordsProps[i][j]
					if(point[0]>30){
						coords.push({lat:point[0],lng:point[1]});
					}else{
						coords.push({lat:point[1],lng:point[0]});
					}

				}
			}
			setCoords(coords);
			if(props.home){		
				setHome({lat:props.home.coordinates[0],lng:props.home.coordinates[1]})
			}else{
				setHome(coords[0])
			}
		}

		async function getPolygon() {
			if(props.idArea){
				if(!parseInt(props.idArea)){
					for (const areaId of props.idArea) {
						await queryGetHome({variables:{id:areaId}})
					}
				}else{
					queryGetHome({variables:{id:props.idArea}})
				}
			}
		}

		useEffect(() => {
		 getCoords();
		 getPolygon();
		}, [props.idArea]);

		const mapStyles=[
    {
        "featureType": "all",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "color": "#f9f5ed"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
            {
                "color": "#f5f5f5"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#c9c9c9"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#aee0f4"
            }
        ]
    }
]

	  return(
	  	<Box flex style={{position: 'absolute', height: '100%', width: '100%', top: '0', left: '0'}}>
		    {
				home &&
				<Map google={window.google}
		        style={{width: '100%', height: '100%', position: 'relative'}}
                styles={mapStyles}
		        className={'map'}
		        initialCenter={home}
		        zoom={18}
				>
		        <Polyline
		          path={coordinate}
		          strokeColor="#0000FF"
		          strokeOpacity={0.8}
		          strokeWeight={2}
		          fillColor="#0000FF"
		          fillOpacity={0.35} />
		        {
					polygons.map((coordinata,index)=>(
						
						<Polygon
							key={index}
							editable={false}
							paths={coordinata.path}
							zIndex={index}
							strokeColor={coordinata?.color}
							strokeOpacity={0.8}
							strokeWeight={2}
							fillColor={coordinata?.color}
							fillOpacity={0.35}
							>
						</Polygon>
											
						)
					)
				}
		    </Map>
			}
		   
		
	    </Box>
	  )
}
 
export default GoogleApiWrapper({
  apiKey:  process.env.REACT_APP_GOOGLE_MAP_KEY
})(App)