import React, { useState, useEffect } from "react";
import { Box, Grommet, Notification as NotificationAp,TextArea,Text,CheckBox,Layer,Button,DateInput,TextInput,FileInput,Tabs,Tab, CardHeader, Card, CardBody } from 'grommet';
import  { Redirect } from 'react-router-dom';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import moment from "moment";
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import { Box as BoxMui } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Chat } from 'grommet-icons';
import { Divider } from '@mui/material';
import StopIcon from '@mui/icons-material/Stop';
import PauseIcon from '@mui/icons-material/Pause';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import { Accordion,AccordionSummary,AccordionDetails,Typography  } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import FolderIcon from '@mui/icons-material/Folder';
import DownloadIcon from '@mui/icons-material/Download';
import { CircularProgress } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {useLazyQuery, useMutation} from "@apollo/client";
import {
    DELETE_TO_DO,
    GET_USER,
    QUERY_ARCHIVED_TO_DO,
    QUERY_ASSIGNED_TO_DO,
    QUERY_TO_DO,
    GET_TO_DO_ASSIGNED_USER,
    DELETE_ATTACHMENT,
    GET_TAGS,
    QUERY_GET_TO_DO_TAGS,
    DELETE_TODO_TAG
} from "./ToDoGQL.jsx";
import { QUERY_GET_CLIENTS } from "../activity/AutomaticheGQL.js";
import {tenant, tenantType} from "../tenant.js";
import {extractIdFormData, GOLF_ADD_ATTACHMENT, GOLF_GET_ATTACHMENT, QUERY_CONTENT_TYPE} from "../../utilis/utils.js";
import {AttachFile} from "@material-ui/icons";
import { ar, da, ta } from "date-fns/locale";
import LayerUserResponse from "./LayerUserResponse.jsx";
import Switch from '@mui/material/Switch';
import { set } from "date-fns";
import { tag } from "@turf/turf";
import Chip from "@mui/material/Chip";
import TuneIcon from '@mui/icons-material/Tune';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Badge from '@mui/material/Badge';
import ToDoList from "./ToDoList.jsx";

const theme = {
  global: {
    colors: {
      brand: '#397aa3',
      'accent-1': 'white',
      'focus': '#397aa3',
      "my-text-color": "",
      background: '#ecedf3',
    },
    font: {
      family: '',
      size: '18px',
      height: '20px',
    },
    menu:{
      icons:{
        color:'white',
        },
    },
  },
};



const ToDoListComp = (props) => {


  const [showLayer,setShowLayer]=React.useState(false);
  let [elencoToDo,setElencoToDo]=React.useState([]);
  const [showFab,setShowFab]=React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [elencoAllegati,setElencoAllegati]=React.useState([]);
  const [elencoFile,setElencoFile]=React.useState([]);
  const [newFilesList,setNewFilesList]=React.useState([]);
  const [mostraLayerCaricatore,setMostraLayerCaricatore]=React.useState(false);
  const [width, setWidth]   = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [index, setIndex] = React.useState(0);
  const [checkNotifyNewNote,setCheckedNotifyNewNote]=React.useState(false);
  const [checkNotifyOnSave,setCheckedNotifyOnSave]=React.useState(false);
  const [showLayerUserResponse, setShow] = React.useState(false);
  const [clientList,setClientList]=React.useState([]);
  const [clientListFiltered,setClientListFiltered]=React.useState([]);
  const [selectedClientListFiltered,setSelectedClientListFiltered]=React.useState([]);
  const [selectedClient,setSelectedClient]=React.useState([]);
  const [orderByExpiration, setOrderByExpiration] = React.useState(false);
  const [showAutocompleteCustomer, setShowAutocompleteCustomer] = React.useState(false);
  const [allowToFilter, setAllowToFilter] = React.useState(false);
  const [textSearch, setTextSearch] = useState("");
  let [originalElencoToDo,setOriginalElencoToDo]=React.useState([]);
  const [tagsList,setTagsList]=React.useState([]);
  const [todoTagsList,setToDoTagsList]=React.useState([]);
  const [selectedTags,setSelectedTags]=React.useState([]);
  const [selectedFilterTags,setSelectedFilterTags]=React.useState([]);
  const [showFilter, setShowFilter] = React.useState(false);

  const [formDataUserResponse, setFormDataUserResponse]=React.useState("");
  const [formData,setFormData]=React.useState({});
  const [usersList,setUsersList]=React.useState([]);
  const [assignedUsersList,setAssignedUsersList]=React.useState([]);
  const [selectedUser,setSelectedUser]=React.useState([]);
  const [deleteToDo]=useMutation(DELETE_TO_DO);
  const [contentType,setContentType]=React.useState();
  const [deleteAttachment] = useMutation(DELETE_ATTACHMENT)

  const [deleteToDoTag] = useMutation(DELETE_TODO_TAG)

  const [ queryGetClients
  ] = useLazyQuery(QUERY_GET_CLIENTS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if(data){
        const dataApp=data[`${tenant}_machines_machine${tenantType}`];
        if(dataApp){
          console.log("CLIENTI",dataApp)
          const clientNames = [];
          for (let i = 0; i < dataApp.length; i++) {
            clientNames.push(dataApp[i].name.toUpperCase());
          }
          setClientListFiltered(clientNames);
          setClientList(dataApp)
        }
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });

  const [ queryGetTags
  ] = useLazyQuery(GET_TAGS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if(data){
        const dataApp=data[`${tenant}_tags_tagname`];
        if(dataApp){
          setTagsList(dataApp)
          console.log("TAGS LIST",dataApp)
        }
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });

    const [ queryGetToDoTags
    ] = useLazyQuery(QUERY_GET_TO_DO_TAGS, {
        fetchPolicy: "no-cache",
        onCompleted: (data) => {
            if(data){
                const dataApp=data[`${tenant}_tags_tags`];
                if(dataApp){
                    console.log("TO DO TAGS",dataApp)
                    setToDoTagsList(dataApp)
                }
            }
        },
        notifyOnNetworkStatusChange: true, // did the work
    });

  const [ queryAllToDO
  ] = useLazyQuery(QUERY_TO_DO, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const toDoList=data[`${tenant}_users_todo`];
      if(toDoList){
        for (let i=0;i<toDoList.length;i++) {
          let str = "";
          let len = toDoList[i].users_userstodos.length;
  
          for (let j = 0; j < len; j++){
            const user = toDoList[i].users_userstodos[j][`users_user${tenantType}`];
            if (user) {
                str += `${user.name} ${user.surname} ${user.registration_number}`;
                // Aggiungi una virgola se non è l'ultimo utente
                if (j < len - 1) {
                    str += ", ";
                }
            }
          }         
          toDoList[i].assignedUsersStr = str;
        }
        

        setElencoToDo(toDoList);
        setOriginalElencoToDo(toDoList);
        console.log("TODOLIST ALL",toDoList);
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });

  const [ queryAssignedToDO
  ] = useLazyQuery(QUERY_ASSIGNED_TO_DO, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const toDoList=data[`${tenant}_users_todo`];
      if(toDoList){
        for (let i=0;i<toDoList.length;i++) {
          let str = "";
          let len = toDoList[i].users_userstodos.length;
  
          for (let j = 0; j < len; j++){
            const user = toDoList[i].users_userstodos[j][`users_user${tenantType}`];
            if (user) {
                str += `${user.name} ${user.surname} ${user.registration_number}`;
                // Aggiungi una virgola se non è l'ultimo utente
                if (j < len - 1) {
                    str += ", ";
                }
            }
          }         
          toDoList[i].assignedUsersStr = str;
        }
        
        setElencoToDo(toDoList);
        setOriginalElencoToDo(toDoList);
        console.log("TODOLIST ASSIGNED",toDoList);
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });

  const [ queryArchivedToDo
  ] = useLazyQuery(QUERY_ARCHIVED_TO_DO, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const toDoList=data[`${tenant}_users_todo`];
      if(toDoList){
        for (let i=0;i<toDoList.length;i++) {
          let str = "";
          let len = toDoList[i].users_userstodos.length;
  
          for (let j = 0; j < len; j++){
            const user = toDoList[i].users_userstodos[j][`users_user${tenantType}`];
            if (user) {
                str += `${user.name} ${user.surname} ${user.registration_number}`;
                // Aggiungi una virgola se non è l'ultimo utente
                if (j < len - 1) {
                    str += ", ";
                }
            }
          }         
          toDoList[i].assignedUsersStr = str;
        }

        setElencoToDo(toDoList);
        setOriginalElencoToDo(toDoList);
        console.log("TODOLIST ARCH",toDoList);
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });

  const [ queryUser
  ] = useLazyQuery(GET_USER, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      let users=data[`${tenant}_users_userarea`].map((el)=>el[`users_user${tenantType}`]).sort((a,b)=>a.name.localeCompare(b.name));
      if(users){
        setUsersList(users);

        setFormData({...formData,user_creator:users.find((el)=>el.id==sessionStorage.getItem("userId")).id})
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });

  const [queryGetAssignedUser] = useLazyQuery(GET_TO_DO_ASSIGNED_USER, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const dataApp= data[`${tenant}_users_userstodo`];
      if(dataApp){
        console.log("data",dataApp);
        let visbile=[]
        let assigned=[]
        dataApp.forEach((el)=>{
          if(el?.assigned){
            assigned.push(el?.users_usergolf)
          }else{
            visbile.push(el?.users_usergolf)
          }
        })
        setAssignedUsersList(assigned);
        setSelectedUser(visbile);
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });

   const [queryGetContentType] = useLazyQuery(QUERY_CONTENT_TYPE, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      let contentType=data[`django_content_type`][0]
      if(contentType){
        setContentType(contentType);
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });

   const [ queryGetAttachments
    ] = useLazyQuery(GOLF_GET_ATTACHMENT, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const attachments=data[`${tenant}_attachments_attachment`]
      if(attachments){
        setElencoFile(attachments);
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });


  React.useEffect(()=>{
      queryAllToDO({variables:{userId:sessionStorage.getItem("userId")}});
      queryGetContentType({variables:{model:"todo"}})
      queryGetClients();
      queryGetTags();
      console.log("responseNotRead TODO",props?.responseNotRead)
  },[])

    React.useEffect(()=>{
        queryGetToDoTags({variables:{contentTypeId:contentType?.id}});
    },[contentType])


    React.useEffect(()=>{
        filter();
        console.log("selectedFilterTags",selectedFilterTags);
    },[selectedClientListFiltered,textSearch,orderByExpiration,showAutocompleteCustomer,selectedFilterTags])


    React.useEffect(()=>{
         queryUser({variables:{areeId:props.aree || JSON.parse(sessionStorage.getItem("areeId"))}});
    }, [props.aree])

    /**
     * Funzione che permette di sapere le dimensioni aggiornate dello schermo
     */
  const updateDimensions = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
  }

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
}, []);

  const handleChange = (panel) => (event, isExpanded) => {
      console.log(panel);
    setExpanded(isExpanded ? panel : false);
  };


  if (!sessionStorage.getItem("accessToken")) {
    return <Redirect to='/'  />
  }


  function eliminaAllegato(allegatoSel){
    /*var confirm=window.confirm("Sicuro di voler eliminare l'allegato?");
    if(confirm){
      let elencoAllegatiApp=[...elencoAllegati];
      elencoAllegatiApp=elencoAllegatiApp.filter((allegato)=>allegato.name != allegatoSel.name)
      firebase.firestore().collection('ToDo').doc(modificaToDo).update({
        Allegati: elencoAllegatiApp.map(allegato=>allegato.name)
      }).then((value)=>console.log("aggiornamento"));
      allegatoSel.delete().then(() => {
        setElencoAllegati(elencoAllegatiApp)
        alert("Allegato eliminato con successo");
      }).catch((error) => {

      });
    }*/
  }

  function downloadFile(allegatoSel){
        window.open("/media/"+allegatoSel.attachment)
  }

  function filter(){
    if (orderByExpiration || showAutocompleteCustomer || textSearch!=="" || selectedFilterTags.length>0) {
      let filteredElenco = [...originalElencoToDo]; // Copia dell'elenco originale
      
      // Filtro per tag
      if(selectedFilterTags.length>0){

        const filteredTags = todoTagsList.filter(item => {
            return selectedFilterTags.some(filterTag => 
                item.tags_tagname.id === filterTag.id && 
                item.tags_tagname.name === filterTag.name
            );
        });

        console.log("filteredTags",filteredTags);

        filteredElenco = filteredElenco.filter(item => {
          return filteredTags.some(filterTag => 
              item.id === filterTag.object_id 
          );
        });

        console.log("TODO FILTRTATI PER TAG",filteredTags);
      }
      

      // Filtro per testo
      if (textSearch !== "") {
        filteredElenco = filteredElenco.filter(item => 
          item?.title.toLowerCase().includes(textSearch.toLowerCase()) ||
          item?.text.toLowerCase().includes(textSearch.toLowerCase())
        );
      }
      // Filtro per cliente
      if (showAutocompleteCustomer) {
        if (selectedClientListFiltered.length === 0) {
          filteredElenco = filteredElenco.filter(item => item?.machines_machinegolf !== null);
        } else {
          filteredElenco = filteredElenco.filter(item => {
            if (item?.machines_machinegolf) {
              return selectedClientListFiltered.includes(item?.machines_machinegolf?.name.toUpperCase());
            }
            return false;
          });
        }
      }
      // Ordina per scadenza
      if (orderByExpiration) {
        filteredElenco.sort((a, b) => new Date(a?.expiration) - new Date(b?.expiration));
      }

      setElencoToDo(filteredElenco);
    }else{
      setElencoToDo(originalElencoToDo);
    }

  }

  function filterToDo(index){

    if(index===0){ //TUTTI
       queryAllToDO({variables:{userId:sessionStorage.getItem("userId")}});
    }else if(index===1){ //ASSEGNATI
      queryAssignedToDO({variables:{userId:sessionStorage.getItem("userId")}});
    }else if(index===2){ //ARCHIVIATI
      queryArchivedToDo({variables:{userId:sessionStorage.getItem("userId")}});
    }

  }

  function handleUpsertToDo(checked=false,toDo=null){ //argoments only on checked event
      let formDataApp= toDo || {...formData};
      formDataApp["notification_on_expire"]=checkNotifyNewNote
      formDataApp["notification_on_save"]=checkNotifyOnSave
     // formDataApp["created_at"]=new Date().toDateString();
      formDataApp["user_creator"]=sessionStorage.getItem("userId")
      formDataApp["have_attachment"]=false;
      
      delete formDataApp[`users_user${tenantType}`];
      delete formDataApp[`usersUser${tenantType}ByUserModifyId`]
      delete formDataApp[`__typename`]
      delete formDataApp[`machines_machine${tenantType}`]
      
      if(formDataApp?.id){
        formDataApp["user_modify"]=sessionStorage.getItem("userId")
        formDataApp["checked"]=typeof checked === 'object'?checked?.target?.checked:checked;
      }else{
        formDataApp["checked"]=false;
      }
     
      let obj={}
      obj["data"]=formDataApp;
      obj["assigned"]=assignedUsersList.map((el)=>el?.id);
      obj["visible"]=selectedUser.map((el)=>el?.id);
      obj["tags"]=selectedTags.map((el)=>el?.id);
      obj["content_type"]= contentType?.id || null
      obj["data"]["have_attachment"]=false;
      obj["data"]["customer"]= selectedClient?.id || null
      let attachments=[]
      if(contentType){
          for(let i=0;i<newFilesList.length;i++){
              attachments.push({name:newFilesList[i]?.name, contentType:contentType?.id,attachment:JSON.stringify(newFilesList[i])});
              obj["data"]["have_attachment"]=true;
              /*addAttachments({variables:{name:elencoFile[i].name, contentType:contentType.id,objectId:id,attachment:JSON.stringify(elencoFile[i])}})
                  .then((value)=>console.log(value))
                  .catch((error)=>console.log(error))*/
          }
      }
      obj["attachments"]=attachments
      console.log("OBJ",obj)

      const url = window.location.hostname!=="localhost"?window.location.href.replace(/\/home$/, '') + "/api/create_to_do/" : process.env.REACT_APP_BACKEND_HOST+ "/api/create_to_do/"
      const accessToken = sessionStorage.getItem("accessToken");

      fetch(url, {
        method: 'POST',
        cors:"no-cors",
        headers: {
          "Authorization": `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      })
      .then(response => response.json())
      .then(result => {
        if(result){
          console.log(result);
          queryGetToDoTags({variables:{contentTypeId:contentType?.id}});
          filterToDo(index);
          if(!toDo){
            alert("Nota aggiunta correttamente");
          }
          setFormData({});
          setSelectedUser([]);
          setSelectedClient([]);
          setSelectedTags([]);
          setAssignedUsersList([]);
         // queryGetAttachments({variables:{contentTypeId:contentType?.id,objectId:toDo.id}});
          setShowLayer(false);
        }
      })
      .catch(error => {
        alert("Errore durante l'operazione");
        console.error('Si è verificato un errore:', error);
      });
  }

  const onActive = (nextIndex) => {setTextSearch("");setOrderByExpiration(false);setShowAutocompleteCustomer(false);setSelectedFilterTags([]);setSelectedClientListFiltered([]);filterToDo(nextIndex);setIndex(nextIndex);};


  return (
    <Grommet theme={theme} themeMode="dark" className="font-link">
      <Box align="center" margin="small" height="94vh" overflow="auto">
      <Box width="100%" overflow="hidden" aligns="center" pad="small" margin="10px">
      <center>
  <Box direction="row" align="center" gap="small" style={{cursor: 'pointer'}}>

    <TuneIcon
      fontSize="large"
      color={showFilter ? "#1976d2" : "black"}
      onClick={(e) => setShowFilter(!showFilter)}
      style={{ margin: "10px", cursor: 'pointer' }}
    />
    {!showFilter ? (
      <KeyboardArrowUpIcon fontSize="large" onClick={(e) => setShowFilter(!showFilter)}/>
    ) : (
      <KeyboardArrowDownIcon fontSize="large" onClick={(e) => setShowFilter(!showFilter)}/>
    )}
  </Box>
</center>

{showFilter && (
  <Box
    width="100%"
    overflow="hidden"
    height={"540px"}
    style={{
      animation: 'fadeIn 0.5s ease-in-out',
      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
      padding: "20px",
      borderRadius: "8px",
      backgroundColor: "#f9f9f9",
    }}
  >
    <Box direction="row" style={{ justifyContent: "space-between", marginBottom: "20px" }}>
      <Box direction="row" gap="small" alignContent="center">
        <Switch
          checked={orderByExpiration}
          onChange={(event) => setOrderByExpiration(event.target.checked)}
          name={"Ordina per scadenza più vicina"}
          style={{ transition: 'transform 0.2s ease' }}
        />
        <Text
          color="color"
          size="medium"
          style={{ marginTop: "5px", fontWeight: 500 }}
        >
          Ordina per scadenza più vicina
        </Text>
      </Box>
    </Box>

    <Box direction="row" style={{ justifyContent: "space-between", marginBottom: "20px" }}>
      <Box direction="row" gap="small" alignContent="center">
        <Switch
          checked={showAutocompleteCustomer}
          onChange={(event) => setShowAutocompleteCustomer(event.target.checked)}
          name={"Filtra per cliente"}
          style={{ transition: 'transform 0.2s ease' }}
        />
        <Text
          color="color"
          size="medium"
          style={{ marginTop: "5px", fontWeight: 500 }}
        >
          Filtra per cliente
        </Text>
        {showAutocompleteCustomer && (
          <Autocomplete
            disableCloseOnSelect
            multiple
            disablePortal
            id="combo-box-demo"
            options={clientListFiltered}
            value={selectedClientListFiltered}
            style={{
              minWidth: "200px",
              transition: 'all 0.3s ease',
            }}
            onChange={(event, newValue, index) => {
              setSelectedClientListFiltered(newValue);
            }}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField {...params} size="medium" variant="standard" />
            )}
          />
        )}
      </Box>
    </Box>

    <Box width="90%" style={{ marginLeft: "14px" }}>
      <TextField
        id="standard-basic"
        label="Ricerca per testo"
        variant="outlined"
        value={textSearch}
        onChange={(e) => setTextSearch(e.target.value)}
        style={{
          width: "100%",
          borderRadius: "5px",
          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
        }}
      />
    </Box>

    <Box width="90%" style={{ marginTop: "30px", marginLeft: "14px" }}>
      <Autocomplete
        disableCloseOnSelect
        multiple
        disablePortal
        id="combo-box-demo"
        options={tagsList}
        value={selectedFilterTags}
        style={{
          minWidth: "200px",
          transition: 'all 0.3s ease',
          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: "5px",
        }}
        onChange={(event, newValue, index) => {
          setSelectedFilterTags(newValue);
        }}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField {...params} style={{marginLeft: "7px", marginBottom: "10px", width: "99%"}} size="medium" label="Tag" variant="standard" />
        )}
      />
    </Box>
  </Box>
)}

<style jsx>{`
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .filter-box-enter {
    animation: fadeIn 0.5s ease-in-out;
  }
`}</style>

          <Divider/>
      </Box>
        <Box width="100%" overflow="auto" gap="small"  height="100vh">
          <Tabs activeIndex={index} onActive={onActive}>
            <Tab title="TUTTI">
                <ToDoList
                    index={0}
                    elencoToDo={elencoToDo}
                    contentType={contentType}
                    queryGetAttachments={queryGetAttachments}
                    handleChange={handleChange}
                    expanded={expanded}
                    handleUpsertToDo={handleUpsertToDo}
                    filterToDo={filterToDo}
                    width={width}
                    setFormData={setFormData}
                    queryGetAssignedUser={queryGetAssignedUser}
                    setSelectedClient={setSelectedClient}
                    setShowLayer={setShowLayer}
                    deleteToDo={deleteToDo}
                    elencoFile={elencoFile}
                    deleteAttachment={deleteAttachment}
                    downloadFile={downloadFile}
                    responseNotRead={props?.responseNotRead}
                    setFormDataUserResponse={setFormDataUserResponse}
                    setShow={setShow}
                    todoTagsList={todoTagsList}
                    setToDoTagsList={setToDoTagsList}
                />
            </Tab>
              <Tab title="ASSEGNATI">
                  <ToDoList
                      index={1}
                      elencoToDo={elencoToDo}
                      contentType={contentType}
                      queryGetAttachments={queryGetAttachments}
                      handleChange={handleChange}
                      expanded={expanded}
                      handleUpsertToDo={handleUpsertToDo}
                      filterToDo={filterToDo}
                      width={width}
                      setFormData={setFormData}
                      queryGetAssignedUser={queryGetAssignedUser}
                      setSelectedClient={setSelectedClient}
                      setShowLayer={setShowLayer}
                      deleteToDo={deleteToDo}
                      elencoFile={elencoFile}
                      deleteAttachment={deleteAttachment}
                      downloadFile={downloadFile}
                      responseNotRead={props?.responseNotRead}
                      setFormDataUserResponse={setFormDataUserResponse}
                      setShow={setShow}
                      todoTagsList={todoTagsList}
                      setToDoTagsList={setToDoTagsList}
                  />
              </Tab>
              <Tab title="ARCHIVIATI">
                  <ToDoList
                      index={2}
                      elencoToDo={elencoToDo}
                      contentType={contentType}
                      queryGetAttachments={queryGetAttachments}
                      handleChange={handleChange}
                      expanded={expanded}
                      handleUpsertToDo={handleUpsertToDo}
                      filterToDo={filterToDo}
                      width={width}
                      setFormData={setFormData}
                      queryGetAssignedUser={queryGetAssignedUser}
                      setSelectedClient={setSelectedClient}
                      setShowLayer={setShowLayer}
                      deleteToDo={deleteToDo}
                      elencoFile={elencoFile}
                      deleteAttachment={deleteAttachment}
                      downloadFile={downloadFile}
                      responseNotRead={props?.responseNotRead}
                      setFormDataUserResponse={setFormDataUserResponse}
                      setShow={setShow}
                      todoTagsList={todoTagsList}
                      setToDoTagsList={setToDoTagsList}
                  />
              </Tab>
            </Tabs>
          </Box>
          {
              showLayerUserResponse && <LayerUserResponse formDataUserResponse={formDataUserResponse} responseNotRead={props?.responseNotRead} setShow={setShow} setFormDataUserResponse={setFormDataUserResponse} />
          }
        {showLayer && (
         <Layer
         onEsc={() => {setShowLayer(false);setFormData({});setSelectedUser([]);setSelectedClient([]);setAssignedUsersList([]);setSelectedTags([]);}}
         onClickOutside={() => {setShowLayer(false);setFormData({});setSelectedUser([]);setSelectedClient([]);setAssignedUsersList([]);setSelectedTags([]);}}
       >
        <Box round width="600px" pad="medium" direction="column" background="white" overflow="auto" height="94vh" gap="medium" margin="10px">
                <Box flex={false}>
                    <Text style={{marginRight:'10px',marginTop:'-3px'}}>Seleziona la data di fine: *</Text>
                            <DateInput
                              format="dd/mm/yyyy"
                              value={formData?.expiration || moment(new Date()).format()}
                              onChange={({ value }) => {setFormData({...formData,expiration:moment(value).format("YYYY-MM-DD")})}}
                              calendarProps={{ size: "small", locale: "it-IT", daysOfWeek: true, firstDayOfWeek: 1 }}
                            />
                </Box>
                <Text style={{marginRight:'10px',marginTop:'-3px'}}>Visibile da:</Text>
                <Autocomplete
                    disableCloseOnSelect
                    multiple
                    disablePortal
                    id="combo-box-demo"
                    options={usersList}
                    value={selectedUser}
                    onChange={(event,newValue,index) => {
                      setSelectedUser(newValue);

                    }}
                    getOptionLabel={(option)=>option.name + " "+option.surname+" "+option.registration_number}
                    renderInput={(params) => <TextField {...params} size="medium" variant="standard"/>}
                  />
                
                <Text style={{marginRight:'10px',marginTop:'-3px'}}>Assegnata a:</Text>
                <Autocomplete
                    disableCloseOnSelect
                    multiple
                    disablePortal
                    id="combo-box-demo2"
                    options={usersList}
                    value={assignedUsersList}
                    onChange={(event,newValue,index) => {
                      setAssignedUsersList(newValue);

                    }}
                    getOptionLabel={(option)=>option.name + " "+option.surname+" "+option.registration_number}
                    renderInput={(params) => <TextField {...params} size="medium" variant="standard"/>}
                  />

                <Text style={{marginRight:'10px',marginTop:'-3px'}}>cliente:</Text>
                <Autocomplete
                      disablePortal
                      id="combo-box-demo3"
                      options={clientList}
                      value={selectedClient}
                      onChange={(event,newValue,index) => {
                        setSelectedClient(newValue);
                      }}
                      getOptionLabel={(option)=> option.name ? option.name : 'Nessuno'} 
                      renderInput={(params) => <TextField {...params} size="medium" variant="standard"/>}
                    />

                  { tagsList.length>0 &&
                  <>  
                    <Text style={{marginRight:'10px',marginTop:'-3px'}}>Tag:</Text>
                    <Autocomplete
                      multiple
                      disablePortal
                      id="combo-box-demo3"
                      options={tagsList}
                      value={selectedTags}
                      onChange={(event,newValue,index) => {
                        setSelectedTags(newValue);
                      }}
                      getOptionLabel={(option)=> option.name ? option.name : 'Nessuno'} 
                      renderInput={(params) => <TextField {...params} size="medium" variant="standard"/>}
                    />
                  </>
                  }

                <TextInput
                placeholder="Inserisci titolo nota"
                value={formData?.title || ""}
                onChange={event =>{ setFormData({...formData,title:event.target.value})}}
                />


                <Box flex={false}>
                  <TextArea
                    value={formData?.text || ""}
                    style={{height:180}}
                    resize={false}
                    placeholder="Inserisci nota"
                    onChange={e =>{setFormData({...formData,text:e.target.value})}}
                  />
                </Box>

            {
              elencoAllegati.length>0?

              <List dense={false}>
              {elencoAllegati.map((allegato)=>
                <ListItem
                  secondaryAction={
                    <Box gap="small" direction="row">
                        <IconButton edge="start" aria-label="delete" onClick={()=>deleteAttachment({variables:{id:allegato?.id}})}>
                            <DownloadIcon />
                        </IconButton>
                      <IconButton edge="end" aria-label="download" onClick={()=>downloadFile(allegato)}>
                        <DownloadIcon />
                      </IconButton>
                   </Box>
                  }
                >
                  <ListItemAvatar>
                    <Avatar>
                      <FolderIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                     primary={<Typography variant="h6" style={ allegato.name.length>=50? { fontSize:"10px"}:{ fontSize:"15px"}}>{allegato.name}</Typography>}
                  />
                </ListItem>
              )}
            </List>

              :
              null
            }
                  <FileInput
                                    multiple
                                    name="file"
                                    onChange={event => {
                                      const fileList = event.target.files;
                                      const file=fileList[0];
                                      var reader = new FileReader();
                                      reader.readAsDataURL(file);
                                      reader.onloadend = function () {
                                          const result=reader.result;
                                         let fileObj={
                                              name: file.name,
                                              type:file.type,
                                              content:result,
                                              size:file.size,
                                              modified: file.lastModifiedDate,
                                          }
                                          let elencoFileApp=[]
                                          elencoFileApp.push(fileObj);
                                          setNewFilesList(elencoFileApp);
                                      }
                                    }
                                    }
                                  />
                   <FormControlLabel
                                    label="Ricevi notifica alla scadenza"
                                    name={"notificationOnExpire"}
                                    control={
                                    <Checkbox
                                        checked={checkNotifyNewNote}
                                         onChange={(e)=>{setCheckedNotifyNewNote(e.target.checked)}}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        label=""
                                      />
                                    }
                                    />
                    <FormControlLabel
                                    label="Ricevi notifica alla creazione"
                                    name={"notificationOnSave"}
                                    control={
                                    <Checkbox
                                        checked={checkNotifyOnSave}
                                         onChange={(e)=>{setCheckedNotifyOnSave(e.target.checked)}}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        label=""
                                      />
                                    }
                                    />
          <Box direction="row" alignSelf="center">
            <Button label={formData?.id?"Aggiorna":"Crea"} onClick={handleUpsertToDo} style={{width:'138px',height:'34px',fontSize:'15px',background:'#397aa3',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}/>
            <Button type="reset" label="Chiudi" onClick={()=>{setFormData({});setShowLayer(false);setSelectedUser([]);setSelectedClient([]);setAssignedUsersList([]);setSelectedTags([]);}} style={{marginLeft:'20px',height:'34px',width:'138px',fontSize:'15px',background:'#c51d34',color:'white',fontWeight:'bold',textTransform: 'uppercase', borderColor:'#c51d34'}} />
          </Box>
        </Box>
        {
          mostraLayerCaricatore?
          <Layer onEsc={()=>setMostraLayerCaricatore(false)} onClickOutside={()=>setMostraLayerCaricatore(false)}>
            <Box align="center" gap="medium" pad="small">
              <CircularProgress style={{'color': 'green'}} variant="determinate" value={90} />
              <Text>Caricamento...</Text>
            </Box>
          </Layer>
          :
          null
        }

       </Layer>
        )
        }
        {
          !showFab?
          <Fab
            onClick={()=>  {setElencoFile([]);setElencoAllegati([]);setShowLayer(true)}}
            style={{  margin: 0,
            top: 'auto',
            right: 30,
            bottom: 30,
            left: 'auto',
            position: 'fixed',}} color="primary" aria-label="add">
            <AddIcon />
          </Fab>
        :
          <Fab
            onClick={()=> {setElencoFile([]);setElencoAllegati([]);setShowLayer(true)}}
            style={{  margin: 0,
            top: 'auto',
            right: 17,
            bottom: 80,
            left: 'auto',
            position: 'absolute',}} color="successs" aria-label="add">
            <AddIcon />
          </Fab>
        }
      </Box>
    </Grommet>
  );
}

export default ToDoListComp;