import React, { useState, useEffect } from "react";
import { Box, Grid, Grommet, Text, Card, CardHeader, CardBody, CardFooter, Button, Heading, Layer, ResponsiveContext } from 'grommet';
import { LinkPrevious } from "grommet-icons";
import Map from "../geoRef/Map";
import  { useHistory,useLocation} from 'react-router-dom';
import moment from "moment";
import { tenant, tenantType } from "../tenant";
import { DELETE_ACTIVITY,QUERY_ACTIVITY_INTERSECT,QUERY_GET_GPS_TRACK } from "./activityGQL";
import { useLazyQuery,useMutation } from '@apollo/client';
import LayerIntersect from "./LayerIntersect";
import LoadingLayer from "../loadingLayer/LoadingLayer.jsx";
import LayerMovementsActivity from "./LayerMovementsActivity.jsx";
import {CircularProgress} from "@mui/material";

const theme = {
  global: {
    colors: {
      brand: '#397aa3',
      'accent-1': 'white',
      'focus': '#397aa3',
      "my-text-color": "",
      background: '',
    },
    font: {
      family: '',
      size: '18px',
      height: '20px',
    },
  },
};

const App = (props) => {
  const location=useLocation()
  const history=useHistory();
  const [showLayer,setShowLayer]=useState(false);

  const [width, setWidth]   = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [landScapeError,setLandScapeError]=React.useState(false);
  const [activityData,setActivityData]=React.useState();
  const [intersectionActivity,setIntersectionActivity]=React.useState();
  const [showLayerIntersect,setShowLayerIntersect]=React.useState(false);
  const [deleteActivityMutation]=useMutation(DELETE_ACTIVITY)
  const [gpsTrack,setGpsTrack]=useState(null);
  const [activityPartecipants,setActivityPartecipants] = useState([]);
  const [showLayerActivityMovement,setShowLayerActivityMovement] = useState(false);
  const [activityMovements,setAcitivityMovements] = useState([]);

  function distinctByAreaName(inputList) {
    const distinctAreas = {};

    inputList.forEach((item) => {
      const areaName = item.area_name.toLowerCase(); // Converti in lowercase per rendere il confronto case-insensitive
      if (!distinctAreas[areaName]) {
        distinctAreas[areaName] = item;
      }
    });

    return Object.values(distinctAreas);
  }

  const [ queryGetIntersect,{loading:loadingIntersect}
  ] = useLazyQuery(QUERY_ACTIVITY_INTERSECT, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const dataApp=data[`${tenant}`]["activity"]
      if(dataApp){
        const intersection=JSON.parse(dataApp.areaIntersectByActivityTrack);
        if(intersection && intersection.length>0){
          let intersectionActivityApp=distinctByAreaName(intersection);
          intersectionActivityApp=intersectionActivityApp.sort((el)=>el?.area_name)
          setIntersectionActivity(intersectionActivityApp);
        }else{
          setIntersectionActivity(null);
        }
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  },


  );

  const [ queryGetTrack,{loading}
  ] = useLazyQuery(QUERY_GET_GPS_TRACK, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const dataApp=data[`${tenant}_activity_activity${tenantType}_by_pk`];
      if(dataApp){
        setGpsTrack(dataApp.gpsTrack_gpstrack);
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });

    /**
     * Funzione che permette di sapere le dimensioni aggiornate dello schermo
     */
  const updateDimensions = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
  }

  useEffect(() => {
      window.addEventListener("resize", updateDimensions);
      return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  /**
   * Controllo in base a width e height l'orientamento dello schermo.
   * Per essere respondive blocco la web app in LandScape.
   */
  React.useEffect(()=>{
    if(width<1100){
      if(width<height){
        setLandScapeError(true);
      }else{
        setLandScapeError(false);
      }    
    }
  },[width,height])

 
  useEffect(() => {
    if(location && location?.state?.activityData){
      setActivityData(location.state.activityData)
      queryGetTrack({variables:{id:location.state.activityData.id}});
      queryGetIntersect({variables:{id:location.state.activityData.id}});
      if(Array.isArray(location.state.activityData["activity_activitypartecipants"]) && location.state.activityData["activity_activitypartecipants"].length>0){
        setActivityPartecipants(location.state.activityData["activity_activitypartecipants"].filter((partecipant)=>partecipant[`users_user${tenantType}`]?.id !== location.state.activityData?.UserId));
      }
      if(Array.isArray(location.state.activityData["products_movements"]) && location.state.activityData["products_movements"].length>0){
        console.log(location.state.activityData["products_movements"]);
        setAcitivityMovements(location.state.activityData["products_movements"]);
      }
    }
  }, []);


  /**
   * Funzione che trasforma la durata dell'attività nel formato hh:mm
   */
  function test(durata){
    if(durata>59){
      var oraT=durata/60;
      var ora=parseInt(oraT);
      var minuti=(oraT-ora)*60;
      var durataF=ora+'h '+Math.round(minuti)+'m';
    } else{
      var durataF=Math.round(durata)+'m'
    }
    return durataF;
  }

  /**
   * Implementa il layer che mostra le note dell'admin e dell'operatore inerente all'attività analizzata
   */
  const LayerChat=(props)=>{
    return(
    <Layer onEsc={()=>setShowLayer(false)} onClickOutside={()=>setShowLayer(false)}>
       <Box width="300px" align="center" style={{borderStyle:"solid",borderColor:"grey",borderWidth:"0.3px"}} margin="small" direction="column" gap="small" pad="small" round="medium">
        {activityData.notes && activityData.notes!==""?
          <Box flex round="medium" background="grey" alignSelf="start" pad="small" textColor="white" >
            <Text weight="bold"  color="white"> ADMIN</Text>
            <Text size="small" color="white"> {activityData.notes} </Text>
          </Box>
          :
          null
          }
          {activityData.operator_notes && activityData.operator_notes!==""?
          <Box flex round="medium"  background="green" alignSelf="end" pad="small">
            <Text weight="bold"  color="white"> OPERATORE </Text>
            <Text size="small" color="white"> {activityData.operator_notes}</Text>
          </Box>
          :
          null
        }
      </Box>
      <Button margin='medium' alignSelf="center" label="Chiudi" onClick={() => setShowLayer(false)} style={{ borderColor:"#c51d34",padding:'10px',fontSize:'28px',/*marginLeft: 'auto',*/background:'#c51d34',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}></Button>
    </Layer>
    );
  } 

  function deleteActivity(){
    let res=window.confirm("Sicuro di voler eliminare l'attività?");
    if(res){
      deleteActivityMutation({variables:{id:activityData.id}})
      .then((value)=>{
        alert("Attività eliminata correttamente")
        history.push({
          pathname:"/home",
          page:"home"
        });
      })
      .catch((value)=>{
        alert("Errore durante l'operazione");
        console.error(value);
      })
    }
  }

  return (
    <Grommet theme={theme} themeMode="dark" className="font-link">
      <ResponsiveContext.Consumer>
        {size => (
        <Box fill>
           <Box height="97vh" direction={width>=1100?"row":"column"}>
      
              {
                activityData &&
              <Box direction='row' flex overflow={{ horizontal: 'hidden' }}>
                  <Box flex pad="small" >
                  <Button style={{width:'150px',fontSize:'15px',background:"#397aa3",color:'white',fontWeight:'bold',textTransform: 'uppercase',borderStyle: 'solid',borderColor:'#397aa3',borderWidth:"3px", borderRadius:"10px"}}  onClick={() =>window.location.href = window.location.origin + "/home"}>
                    <Box pad="small" direction="row" align="center" gap="medium">
                        <LinkPrevious color="white"/>
                        <Text size="small" color="white">Indietro</Text>
                    </Box>
                  </Button>
                    <Box direction='row' flex overflow={{ horizontal: 'hidden' }}>
                        <Card margin="small" background="light-1" width="xlarge" height="large" style={{marginLeft:'auto',marginRight:'auto'}}>
                          <CardHeader justify="center">
                          <Box direction="row">
                            <Button margin='medium' alignSelf="center" label="Cancella" onClick={deleteActivity} style={{ borderColor:"#c51d34",padding:'7px',fontSize:'15px',/*marginLeft: 'auto',*/background:'#c51d34',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}></Button>
                            <Heading level="3" textAlign="center">{activityData[`jobs_job${tenantType}`].name} - {activityData[`area_area${tenantType}`].name}</Heading>
                          </Box>
                          </CardHeader>
                          <CardBody>
                          <Grid
                           gap="small"
                           columns={['auto','auto']}
                           areas={[
                             [ 'colonna1', 'colonna2'],
                           ]}
                         >
                          <Box align="center" gridArea='colonna2' >
                            <Text style={{fontWeight:'bold'}}>Data Lavoro: {moment(activityData.start).format("DD-MM-YYYY")}</Text>
                            <Text style={{fontWeight:'bold'}}>Ora Inizio: {moment(activityData.start).format("HH:mm")}</Text>
                            <Text style={{fontWeight:'bold'}}>Ora Fine: {moment(activityData.end).format("HH:mm")}</Text>
                            {(activityData.notes && activityData.notes!=="" )||( activityData.operator_notes && activityData.operator_notes!=="")?
                            <Button margin="small" label="Mostra note" onClick={() => setShowLayer(true)} style={{width:'170px',height:'34px',fontSize:'15px',background:'#397aa3',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}></Button>
                            :
                            null  
                           }

                            { loadingIntersect && <CircularProgress />}
                            {
                              intersectionActivity &&  <Button margin="small" label="Mostra dati per area" onClick={() => setShowLayerIntersect(true)} style={{width:'250px',height:'34px',fontSize:'15px',background:'#397aa3',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}></Button>
                            }
                            {
                            activityPartecipants.length>0 &&
                                <Box direction={"column"} gap={"small"} style={{marginTop:"50px"}}>
                                  <Text weight="bold">PARTECIPANTI:</Text>
                                  {
                                    activityPartecipants.map((partecipant,index)=>{
                                      return(
                                          <Text>{index+1}) {partecipant[`users_user${tenantType}`].name} {partecipant[`users_user${tenantType}`].surname}</Text>
                                       )
                                    })
                                  }
                              </Box>
                          }
                            {
                              activityMovements.length>0 &&
                                  <Button margin="small" label="Mostra movimenti di magazzino" onClick={() =>setShowLayerActivityMovement(true)} style={{width:'300px',height:'34px',fontSize:'12px',background:'#397aa3',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}></Button>
                            }
                          </Box>
                          {showLayer &&
                            <LayerChat attivita={activityData}/>
                          }
                          {
                            showLayerActivityMovement &&
                              <LayerMovementsActivity movements={activityMovements} onEscLayer={()=>setShowLayerActivityMovement(false)}/>
                          }


                          }
                           
                          <Box style={{paddingLeft:'50px',lineHeight: '40px' }} gridArea='colonna1'> 
                            Dipendente:<Text style={{fontWeight:'bold',textTransform:'uppercase'}}>{`${activityData[`users_user${tenantType}`].name} ${activityData[`users_user${tenantType}`].surname} ${activityData[`users_user${tenantType}`].registration_number}`}</Text>
                          
                            <Text> <br></br> Macchinario: <br></br> <Text style={{fontWeight:'bold', textTransform:'uppercase'}}> {activityData[`machines_machine${tenantType}`].name} </Text> </Text>
                          
                            Durata: <Text style={{fontWeight:'bold'}}>{activityData.approved == 'SI' || activityData.approved == 'si' || activityData.approved == 'manuale' || activityData.approved == 'MANUALE' ? test(Number(parseFloat(((moment(Date.parse(activityData.end)).set('second',0) - moment(Date.parse(activityData.start)).set('second',0))/1000/60), 10).toFixed(2))) : 'Attività non terminata'}</Text>
                            <br></br>
                            <br></br>
                            <br></br>
                          </Box>
                        </Grid> 
                          </CardBody>
                          <CardFooter height="medium" pad={{horizontal: "small"}} background="light-2" style={{position: 'relative'}}> 
                            {gpsTrack && (activityData.approved == 'SI' ||activityData.approved == 'si' )? <Map idArea={new Set(intersectionActivity?.map(el => el?.area_id)) || activityData && activityData[`area_area${tenantType}`].id} coordinates={gpsTrack.track.coordinates} home={activityData && activityData[`area_area${tenantType}`].home_coordinates}/> : null}
                          </CardFooter>
                        </Card>
                          
                    </Box>
                </Box>
              </Box>
              }
            </Box>   
              {
                showLayerIntersect && <LayerIntersect intersect={intersectionActivity} onEscLayer={()=>setShowLayerIntersect(false)}/>
              }
              {
                loading && <LoadingLayer/>
              }
        </Box>
        )}
      </ResponsiveContext.Consumer>
    </Grommet>
  );
}

export default App;