import React from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab'; // Assicurati di importare i componenti correttamente
import { Tab, TextField, Checkbox, FormControlLabel, Autocomplete } from '@mui/material';
import { TextArea, DateInput } from 'grommet';
import moment from 'moment';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';

const ProfileTab = (props) => {

    const [originalProfile, setOriginalProfile] = React.useState({});


    React.useEffect(() => {
        console.log('props?.modifiedKeys', props?.modifiedKeys);
        if (props?.modifiedKeys.length > 0) {
            props?.setShowSave(true);
        }else{
            props?.setShowSave(false);
        }
    }, [props?.modifiedKeys]);

    React.useEffect(() => {
        if(props?.profile){
            setOriginalProfile(props?.profile);
        }
    }, [props?.profile]);

    function handleUpdate(value, key, input){
        if(input === 'date'){
            value = moment(value).format('MM/DD/YYYY');  
        }
        // Ottieni il profilo corrente
        const currentProfile = props?.profile;
        
        // Aggiorna il profilo con il nuovo valore formattato
        const updatedProfile = {
            ...currentProfile,
            profile: {
            ...currentProfile['profile'],
            [key]: {
                ...currentProfile['profile'][key],
                value: value
            }
            }
        };

        if(input === 'choices')
            updatedProfile.status = value;

        findModifiedKeys(updatedProfile);
        // Imposta il nuovo profilo usando la funzione passata nei props
        props?.setProfile(updatedProfile);
    }

    const findModifiedKeys = (newProfile) => {
        let modifiedKeysNow = [];
    
        if (originalProfile) {
          Object.keys(newProfile['profile']).forEach((key) => {
            if (JSON.stringify(newProfile.profile[key]) !== JSON.stringify(originalProfile.profile[key])) {
                modifiedKeysNow.push(key);
            }
          });
        }
        props?.setModifiedKeys(modifiedKeysNow);
    };

    return (
        <TabContext value={props?.valueTab}>
            <TabList
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable tabs"
                onChange={props?.handleChange}
            >
            {props?.profile && Object.keys(props?.profile).length !== 0 && 
            Object.entries(props?.profile['profile']).map(([key], index) => {
                const isModified = props?.modifiedKeys.includes(key);
                        return (
                        <Tab 
                            key={index} 
                            label={
                            isModified ? (
                                <>
                                <Badge color="primary" badgeContent="!" overlap="circular" sx={{
                                    position: 'absolute',
                                    top: 10,
                                    right: 10,
                                    
                                  }}>
                                
                                </Badge>
                                {key}
                                </>
                            ) : (
                                key
                            )
                            } 
                            value={index} 
                        />
                        );
                    })
                }
            </TabList>

            {props?.profile && Object.keys(props?.profile).length !== 0 && 
                Object.entries(props?.profile['profile']).map(([key, value], index)=>{
                    return(
                        <TabPanel key={index} value={index}>
                        {/* Controlla il valore di input */}
                        {value.input === 'choices' && (
                            <Autocomplete
                                value={value.value}
                                options={value.accepted_value} // Array di opzioni
                                renderInput={(params) => <TextField {...params} label="Seleziona un'opzione" />}
                                onChange={(event, newValue) => handleUpdate(newValue, key, 'choices')}
                            />
                        )}
                        {value.input === 'text' && (
                            <TextArea
                                label="Inserisci testo"
                                variant="outlined"
                                value={value.value}
                                style={{height: "400px"}}
                                onChange={(event) => handleUpdate(event.target.value, key, 'text')}
                            />
                        )}
                        {value.input === 'check' && (
                            <FormControlLabel
                                control={<Checkbox />}    // DA TESTARE !!!!
                                label="Accetta"
                                value={value.value}
                                onChange={() => handleUpdate(!value.value, key, 'check')}
                            />
                        )}
                        {value.input === 'date' && value?.value && 
                                <DateInput
                                key={index}
                                format="dd/mm/yyyy"
                                value={value?.value}
                                calendarProps={{ size: "small", locale: "it-IT", daysOfWeek: true, firstDayOfWeek: 1 }}
                                onChange={({ value }) => {handleUpdate(value, key, 'date')}}

                                />
            
                        }
                    </TabPanel>
                    )
                })
        
            }
        </TabContext>
    );
};

export default ProfileTab;
