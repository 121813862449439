import { gql } from '@apollo/client';
import { tenant, tenantType, tenantTypeFirstUpperCase } from '../../tenant';

const QUERY_USER=gql`
query user($area: [bigint!]) {
    ${tenant}_users_userarea(where: {area_id: {_in: $area}},distinct_on: user_id) {
      id
      users_user${tenantType} {
        id
        name
        surname
        registration_number
      }
    }
}  
`

const QUERY_MACHINE=gql`
query machine($area: [bigint!]) {
  ${tenant}_machines_machinearea(where: {area_id: {_in: $area}, _and: {machines_machinegolf: {is_machine: {_eq: true}}}}, distinct_on: machine_id) {
    machines_machine${tenantType} {
      id
      name
      chassis_number
      machines_reliefhours(order_by: {machine_hour: asc}) {
        id
        machine_hour
      }
      machines_maintenancegolves(order_by: {machine_hour: asc}){
        id
        machine_hour
        machines_machine${tenantType} {
          id
          name
        }
        machineType_interventiontype {
          code
        }
        datetime
      }
    }
  }
}
`

const ADD_RELIEF_HOUR=gql`
mutation AddRieliefHour($datetime:DateTime!,$machine:ID!,$machineHour:Int!,$user:ID!){
  ${tenant}{
    upsertReliefHour(input:{datetime:$datetime,machine:$machine,machineHour:$machineHour,user:$user}){
      datetime
      machine
      machineHour
      errors{
        messages
      }
    }
  }
}
`

const UPDATE_RELIEF_HOUR=gql`
mutation UpdateRieliefHour($id:bigint!,$datetime:timestamptz!,$machine:bigint!,$machineHour:Int,$user:bigint!){
  update_${tenant}_machines_reliefhour_by_pk(pk_columns: {id: $id},_set:{datetime:$datetime,machine_id:$machine,machine_hour:$machineHour,user_id:$user}){
  	id
    machine_id
    machine_hour
    datetime
  }
}
`

const INTERVENTION_TYPE=gql`
query InterventionType{
 	machineType_interventiontype(order_by: {code: asc}){
    id
    code
  }
}
`

const ADD_MAINTENANCE=gql`
mutation addMaintenance($typeIntervention:ID!,$machine:ID!,$machineHour:Int!,$datetime:DateTime!,$note:String){
  ${tenant}{
    upsertMaintenance${tenantTypeFirstUpperCase}(input:{typeIntervention:$typeIntervention,machine:$machine,machineHour:$machineHour,datetime:$datetime,note:$note}){
      machineHour
      errors{
        messages
      }
    }
  }
}
`

const GET_MACHINE_MAINTENANCE=gql`
query MaintenanceMachine($id: Int!) {
  ${tenant}{
     machine${tenantTypeFirstUpperCase}(id:$id) {
         hours
        maintenancesStatus {     
          interval{
            typeIntervention{
              code
            }
            interval
          }
        elapsedHours
      }
   }
	}
}
`


const UPSERT_MAINTENANCE_SCHEDULE=gql`
mutation UpsertMaintenanceSchedule($id:ID,$machineId: ID!, $typeInterventionId: ID!, $interval: Int!,$sequence:Int!,$iterations:Int!) {
  ${tenant}{
  	upsertMachineMaintenanceSchedule(input:{id:$id,machine:$machineId,typeIntervention:$typeInterventionId,interval:$interval,sequence:$sequence,iterations:$iterations}){
      machineMaintenanceSchedule{
        id
      }
    }
  }
}
`

const DELETE_MAINTENANCE_SCHEDULE=gql`
mutation DeleteMaintenanceSchedule($id:bigint!){
  delete_${tenant}_machines_machinemaintenanceschedule_by_pk(id:$id){
    id
  }
}
`

const QUERY_GET_INTERVENTION_TYPE=gql`
query GetInterventionType{
  machineType_interventiontype{
    id
    note
    code
  }
}
`

const QUERY_MAINTENANCE_BY_MACHINE_BY_INTERVENTION_TYPE=gql`
query GetMaintenanceFromMachineAndInterventionType($idMachine:bigint!,$idInterventionType:bigint!){
 ${tenant}_machines_machine${tenantType}_by_pk(id:$idMachine){
    machines_machinemaintenanceschedules(where:{type_intervention_id:{_eq:$idInterventionType}}){
      id
      interval
      sequence
      iterations
    }
  }
}`

const DELETE_MAINTENANCE=gql`
mutation deleteMaintenace($id:bigint!){
  delete_${tenant}_machines_maintenance${tenantType}_by_pk(id:$id){
    id
  }
}
`

const QUERY_RELIEF_HOURS=gql`
query ReliefHoursList($areeId:[bigint!]) {
  ${tenant}_machines_reliefhour(where: {machines_machine${tenantType}: {machines_machineareas: {area_id: {_in: $areeId}}}}) {
    id
    machines_machine${tenantType} {
      name
    }
    users_user${tenantType} {
      name
      surname
      registration_number
    }
    datetime
    machine_hour
  }
}

`

const DELETE_RELIEF_HOUR=gql`
mutation DeleteReliefHour($id:bigint!){
  delete_${tenant}_machines_reliefhour_by_pk(id:$id){
    id
  }
}
`


export {QUERY_USER,QUERY_MACHINE,ADD_RELIEF_HOUR,UPDATE_RELIEF_HOUR,INTERVENTION_TYPE,ADD_MAINTENANCE,GET_MACHINE_MAINTENANCE,UPSERT_MAINTENANCE_SCHEDULE,DELETE_MAINTENANCE_SCHEDULE,QUERY_GET_INTERVENTION_TYPE,QUERY_MAINTENANCE_BY_MACHINE_BY_INTERVENTION_TYPE,DELETE_MAINTENANCE,QUERY_RELIEF_HOURS,DELETE_RELIEF_HOUR};