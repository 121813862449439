import {useMutation, useSubscription} from "@apollo/client";
import {QUERY_CANEDGE} from "../registryComponentsGQL.js";
import {Box} from "grommet";
import {DataGrid} from "@mui/x-data-grid";
import {tenant} from "../../tenant.js";
import React from "react";
import {Button as ButtonMui} from "@mui/material";
import {DELETE_CANEDGE} from "./canedgeGQL.js";

function Can(props){

    const columnsCan=[
    { field: 'can_number', headerName: 'Can', width: 170 },
    { field: 'Modifica', headerName: 'Modifica', width: 170,
     renderCell:(cellValues)=>{
      return(
              props?.modifyRegistry &&
                 <ButtonMui
                  variant="contained"
                  color="primary"
                  onClick={(event) => {
                    props.setCanSel(cellValues.row)
                    props.setModifyCan(true);
                  }}
                >
                 Modifica
                </ButtonMui>
      )
      }
    },
    {
      field: 'Cancella',
      headerName: 'Cancella',
      width: 120,
      renderCell: (cellValues) => {
        return (
              props?.modifyRegistry &&
          <ButtonMui
            variant="contained"
            color="error"
            onClick={(event) => {
              let res=window.confirm("Sei sicuro di voler eliminare il canedge selezionato?");
              if(res){
                deleteCan({variables:{ id:cellValues.row.id}})
              }
            }}
          >
            Cancella
          </ButtonMui>
        );},
     }
  ]
    const { data, loading } = useSubscription(
      QUERY_CANEDGE
    );
    const [deleteCan]=useMutation(DELETE_CANEDGE);

    return (
      <Box overflow="auto"  height="100%" width="100%" alignSelf='center' style={{marginTop:'40px'}}>
        <div style={{ height: '75vh', width: '100%' }}>
          <DataGrid
            rows={data && data[`${tenant}_machines_can`] || []}
            columns={columnsCan}
          />
        </div>
      </Box>
    )
}

export default Can;