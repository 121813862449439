import React from "react";
import {Box, Heading, Form, FormField, TextInput, Button, CheckBox} from "grommet";
import TreeViewAree from "../../treeView/TreeView";
import { useLazyQuery,useMutation } from '@apollo/client';
import { ADD_JOB } from "./jobsGQL";
import { GET_TREE } from "../aree/areeGQL";
import { tenant, tenantTypeFirstUpperCase } from "../../tenant";
import { extractIdFormData } from "../../../utilis/utils";
import {sortTree} from "../../../utilis/utils";

export default function AddLav(props) {
  
  const [formData,setFormData]=React.useState({assignedAutomaticallyToAllMachine:true});
  const [mutationUpsertJob]=useMutation(ADD_JOB);
  const [selectedNodes,setSelectedNodes]=React.useState(formData?.jobs_jobsareas?.map((jobArea)=>jobArea.area_id )||[]);
  const [tree,setTree]=React.useState({});
  const [ queryGetTree
  ] = useLazyQuery(GET_TREE, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => { 
        const tree=data[`${tenant}`]["whoami"].profile.treeArea   //[`areas${tenantTypeFirstUpperCase}First`][0].trees;
        if(tree){
          setTree(JSON.parse(tree).tree);
        }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });

  React.useEffect(()=>{
    if(props.lavoro){
      console.log("LAVORO",props.lavoro);
      console.log("JOB AREA",props.lavoro?.jobs_jobsareas?.map((jobArea)=>jobArea.area_id ));
      setSelectedNodes(props.lavoro?.jobs_jobsareas?.map((jobArea)=>jobArea.area_id ) || [])
      setFormData(props.lavoro)
    }
  },[])
  

  const onChangeTextInput=(event)=>{
    setFormData({...formData,[event.target.name]:event.target.value})
  }

  function onUpsertDip(){

    let formDataApp=extractIdFormData({...formData,aree:JSON.stringify({areeIds:selectedNodes})},true);
    formDataApp["variables"]["assignedAutomaticallyToAllMachine"]=formDataApp["variables"]["assignedautomaticallytoallmachine"];
    delete formDataApp?.variables["assignedautomaticallytoallmachine"];
    delete formDataApp?.variables["jobsJobsareas"];
    delete formDataApp?.variables["_Typename"];
    console.log(formDataApp);
    mutationUpsertJob(formDataApp)
    .then((value)=>{
      //setFormData(value.data[`${tenant}`][`upsertJob${tenantTypeFirstUpperCase}`])
      alert("Lavoro aggiunto correttamente");
      onEscLayer();
    }).catch((value)=>{
      console.error(value);
      alert("Errore durante l'operazione");
    })
  }

  function onEscLayer(){
    if(props.onEscLayer){
        props.onEscLayer();
    }
    //setFormData({});
  }


  return (
    <Box round pad="medium" direction="column" background="white" align="center" overflow="auto">
      <Heading level="2" margin="none" size="small">
        <Box direction="row" gap="large">
          {!props.lavoro?"Inserisci Lavoro":"Aggiorna lavoro"}
          {/*<Button label="Scarica modello" href={"./modelliCsv/Modello_Lavoro.csv"} style={{width:'200px',fontSize:'20px',background:'#397aa3',color:'white',fontWeight:'bold',textTransform: 'uppercase',textAlign:"center"}}></Button> */}
        </Box>
      </Heading>
      <Box fill gap="medium" pad={{ vertical: "small" }}>
        <Form>
          <Box pad="xsmall">
            <FormField required label="Nome lavoro">
              <TextInput
                name="name"
                value={ formData?.name }
                onChange={e => onChangeTextInput(e)}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Codice Gestionale Esterno">
              <TextInput
                name="external_erp_code"
                value={formData && formData.external_erp_code}
                onChange={e => onChangeTextInput(e)}
              />
            </FormField>
          </Box>
           <CheckBox
              name="assignedAutomaticallyToAllMachine"
              checked={formData?.assignedAutomaticallyToAllMachine || false}
              label="Assegna a tutte le macchine"
              onChange={(e) => setFormData({...formData,assignedAutomaticallyToAllMachine:e.target.checked})}
            />
          <Box style={{overflowX:"hidden"}} >
            <TreeViewAree checkAssignedAree={true} selectedNodes={selectedNodes} setSelectedNodes={setSelectedNodes} addElement={false} loadTree={queryGetTree} tree={tree} singleChildForNode={false} multiSelect={true}/>
          </Box>
        </Form>
        <Box direction="row" gap="medium" alignSelf="end" >
          <Button margin="small" label= {!props.lavoro?"Aggiungi":"Aggiorna"} onClick={onUpsertDip} style={{width:'138px',height:'34px',fontSize:'15px',background:'#397aa3',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}/>
        </Box>
      </Box>
    </Box>
  )
}