import {tenantType} from "../tenant.js";

function importUser(jsonData){
    // Ottenere il bearer token dal localStorage
const accessToken = sessionStorage.getItem("accessToken");

// Verificare se l'accessToken è presente nel localStorage
if (!accessToken) {
  console.error("AccessToken non trovato nel localStorage.");
} else {
  // URL a cui inviare la richiesta POST
  const url = window.location.hostname!=="localhost"? window.location.origin + `/import/${tenantType}/users`:`http://royalpark.localhost:8000/import/${tenantType}/users`

  // Impostare le opzioni per la richiesta POST
  const requestOptions = {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(jsonData)
  };

  // Effettuare la richiesta POST utilizzando fetch
  fetch(url, requestOptions)
    .then(response => {
      if (!response.ok) {
        throw new Error("Errore nella richiesta POST");
      }
      return response.json();
    })
    .then(data => {
      console.log("Risposta dal server:", data);
      alert(data.message);
    })
    .catch(error => {
      console.error("Si è verificato un errore:", error);
        alert("Errore durante l'import",error.message);
    });
    }
}


function importJobs(jsonData){
    // Ottenere il bearer token dal localStorage
const accessToken = sessionStorage.getItem("accessToken");

// Verificare se l'accessToken è presente nel localStorage
if (!accessToken) {
  console.error("AccessToken non trovato nel localStorage.");
} else {
  // URL a cui inviare la richiesta POST
  const url = window.location.hostname!=="localhost"? window.location.origin + `/import/${tenantType}/jobs`:`http://royalpark.localhost:8000/import/${tenantType}/jobs`

  // Impostare le opzioni per la richiesta POST
  const requestOptions = {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(jsonData)
  };

  // Effettuare la richiesta POST utilizzando fetch
  fetch(url, requestOptions)
    .then(response => {
      if (!response.ok) {
        throw new Error("Errore nella richiesta POST");
      }
      return response.json();
    })
    .then(data => {
      console.log("Risposta dal server:", data);
      alert(data.message);
    })
    .catch(error => {
      console.error("Si è verificato un errore:", error);
        alert("Errore durante l'import",error.message);
    });
    }
}


function importMachines(jsonData){
    // Ottenere il bearer token dal localStorage
const accessToken = sessionStorage.getItem("accessToken");

// Verificare se l'accessToken è presente nel localStorage
if (!accessToken) {
  console.error("AccessToken non trovato nel localStorage.");
} else {
  // URL a cui inviare la richiesta POST
  const url = window.location.hostname!=="localhost"? window.location.origin + `/import/${tenantType}/machines`:`http://royalpark.localhost:8000/import/${tenantType}/machines`

  // Impostare le opzioni per la richiesta POST
  const requestOptions = {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(jsonData)
  };

  // Effettuare la richiesta POST utilizzando fetch
  fetch(url, requestOptions)
    .then(response => {
      if (!response.ok) {
        throw new Error("Errore nella richiesta POST");
      }
      return response.json();
    })
    .then(data => {
      console.log("Risposta dal server:", data);
      alert(data.message);
    })
    .catch(error => {
      console.error("Si è verificato un errore:", error);
        alert("Errore durante l'import",error.message);
    });
    }
}

export {importUser,importJobs,importMachines}