import {Layer, Box, Text, Table, TableHeader, TableRow, TableCell, TableBody, Button} from "grommet";
import { CircularProgress } from "@mui/material";
import React from "react";

function LoadingLayer(props){
    return(
        <Layer>
            <Box height="60%"round="medium" pad="30px" background="#ecedf3">
                <Text>DATA: {props.data}</Text>
                <Table>
                    <TableHeader>
                        <TableRow>
                        <TableCell scope="col" border="bottom">
                            Operatore
                        </TableCell>
                            <TableCell scope="col" border="bottom">
                            Ore Lavorate
                        </TableCell>
                            {/*
                        <TableCell scope="col" border="bottom">
                            Tempo trascorso nell'area
                        </TableCell>*/}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {
                            props.usersDailyHours.map((el, index)=>{
                                return(
                                    <TableRow key={index}>
                                        <TableCell scope="row">
                                            <strong>{el.text}</strong>
                                        </TableCell>
                                        <TableCell>
                                            {el.hours}
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }

                        </TableBody>
                    </Table>
                    <Button margin='medium' alignSelf="center" label="Chiudi" onClick={props.onEscLayer} style={{ borderColor:"#c51d34",padding:'7px',fontSize:'15px',/*marginLeft: 'auto',*/background:'#c51d34',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}></Button>
            </Box>
        </Layer>
    )
}

export default LoadingLayer;