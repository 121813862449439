import React, { useState, useEffect } from "react";
import { Box, Grommet, Notification as NotificationAp,TextArea,Text,CheckBox,Layer,Button,DateInput,TextInput,FileInput,Tabs,Tab, CardHeader, Card, CardBody } from 'grommet';
import  { Redirect } from 'react-router-dom';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import moment from "moment";
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import { Box as BoxMui } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Chat } from 'grommet-icons';
import { Divider } from '@mui/material';
import StopIcon from '@mui/icons-material/Stop';
import PauseIcon from '@mui/icons-material/Pause';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import { Accordion,AccordionSummary,AccordionDetails,Typography  } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import FolderIcon from '@mui/icons-material/Folder';
import DownloadIcon from '@mui/icons-material/Download';
import { CircularProgress } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {useLazyQuery, useMutation} from "@apollo/client";
import {
    DELETE_TO_DO,
    GET_USER,
    QUERY_ARCHIVED_TO_DO,
    QUERY_ASSIGNED_TO_DO,
    QUERY_TO_DO,
    GET_TO_DO_ASSIGNED_USER,
    DELETE_ATTACHMENT,
    GET_TAGS,
    QUERY_GET_TO_DO_TAGS,
    DELETE_TODO_TAG
} from "./ToDoGQL.jsx";
import { QUERY_GET_CLIENTS } from "../activity/AutomaticheGQL.js";
import {tenant, tenantType} from "../tenant.js";
import {extractIdFormData, GOLF_ADD_ATTACHMENT, GOLF_GET_ATTACHMENT, QUERY_CONTENT_TYPE} from "../../utilis/utils.js";
import {AttachFile} from "@material-ui/icons";
import BadgeMessage from "./BadgeMessage.js";
import TagsChip from "./TagsChip.jsx";

function ToDoList({ todoTagsList,responseNotRead,setFormDataUserResponse, setShow ,elencoToDo, contentType, queryGetAttachments, handleChange, expanded, handleUpsertToDo, filterToDo, setFormData, queryGetAssignedUser, setSelectedClient, setShowLayer, deleteToDo, elencoFile, deleteAttachment, downloadFile , index,setToDoTagsList}) {


    return (
        <Box overflow="auto" gap="small" margin="small">
            {
            elencoToDo.length > 0 ?
            elencoToDo.map((toDo, i) => (
            <Accordion
                key={toDo.id}
                expanded={expanded === 'panel' + i}
                onChange={handleChange('panel' + i)}
                onClick={() => queryGetAttachments({ variables: { contentTypeId: contentType?.id, objectId: toDo.id } })}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${i}-content`}
                    id={`panel${i}-header`}
                >
                    <Box direction="row" gap="small" width="100%">
                        <CheckBox
                            checked={toDo?.checked || false}
                            onChange={(event) => {
                                queryGetAttachments({ variables: { contentTypeId: contentType?.id, objectId: toDo.id } });
                                handleUpsertToDo(event.target.checked, { ...toDo });
                                filterToDo(index);
                            }}
                        />
                        <Box direction="column" gap="small" width="100%">
                            <Button>
                                <BadgeMessage
                                    toDo={toDo}
                                    responseNotRead={responseNotRead}
                                    setFormDataUserResponse={setFormDataUserResponse}
                                    setShow={setShow}
                                />
                            </Button>
                            <Box direction="column">
                                <TagsChip toDo={toDo} todoTagsList={todoTagsList} setToDoTagsList={setToDoTagsList} contentType={contentType} />
                                <Typography style={toDo.checked ? { textDecoration: "line-through" } : null}>Mittente: {`${toDo[`users_user${tenantType}`]?.name} ${toDo[`users_user${tenantType}`]?.surname} ${toDo[`users_user${tenantType}`]?.registration_number}`}</Typography>
                                <Typography style={toDo.checked ? { textDecoration: "line-through" } : null}>Assegnata a: {toDo.assignedUsersStr}</Typography>
                                {toDo[`machines_machine${tenantType}`] !== null && <Typography style={toDo.checked ? { textDecoration: "line-through" } : null}>Cliente: {toDo[`machines_machine${tenantType}`]?.name}</Typography>}
                                {toDo[`usersUser${tenantType}ByUserModifyId`] !== null && <Typography style={toDo.checked ? { textDecoration: "line-through" } : null}>Ultima modifica: {`${toDo[`usersUser${tenantType}ByUserModifyId`]?.name} ${toDo[`usersUser${tenantType}ByUserModifyId`]?.surname}`}</Typography>}
                                <Typography color={toDo.expiration <= moment().format('YYYY-MM-DD') ? "red" : "black"}>Scadenza: {toDo.expiration}</Typography>
                            </Box>
                            <Box direction="row" gap="small">
                                {toDo.have_attachment ? <AttachFile /> : null}
                                <Typography style={{fontSize: 26, fontWeight: "bold", fontVariantCaps: "all-petite-caps", textTransform: "uppercase"}}sx={toDo.checked ? { textDecoration: "line-through", wordWrap: "break-word" } : { wordWrap: "break-word" }}>{toDo.title}</Typography>
                            </Box>
                        </Box>
                        <Box align="end" direction="row">
                            <IconButton onClick={() => {
                                setFormData({ ...toDo });
                                queryGetAssignedUser({ variables: { toDoId: toDo.id } });
                                setSelectedClient(toDo[`machines_machine${tenantType}`]);
                                setShowLayer(true);
                            }}>
                                <EditIcon color="primary" />
                            </IconButton>
                            <IconButton onClick={() => {
                                if (window.confirm("Sicuro di voler eliminare la nota?")) {
                                    deleteToDo({ variables: { id: toDo.id } }).then(() => filterToDo(index));
                                }
                            }}>
                                <DeleteIcon color="primary" />
                            </IconButton>
                        </Box>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Box direction="column" gap="small">
                        <TextField
                            InputProps={{ disableUnderline: true }}
                            multiline
                            variant="standard"
                            value={toDo.text}
                        />
                        {elencoFile.length > 0 && (
                            <List dense={false}>
                                {elencoFile.map((allegato) => (
                                    <ListItem
                                        key={allegato.id}
                                        secondaryAction={
                                            <Box gap="small" direction="row">
                                                <IconButton onClick={() => deleteAttachment({ variables: { id: allegato.id } })}>
                                                    <DeleteIcon />
                                                </IconButton>
                                                <IconButton onClick={() => downloadFile(allegato)}>
                                                    <DownloadIcon />
                                                </IconButton>
                                            </Box>
                                        }
                                    >
                                        <ListItemAvatar>
                                            <Avatar>
                                                <FolderIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={<Typography variant="h6">{allegato.name}</Typography>} />
                                    </ListItem>
                                ))}
                            </List>
                        )}
                    </Box>
                </AccordionDetails>
            </Accordion>
            )) :
            <Box align="center" alignSelf="center">
                <Text color="#397aa3" size="large" weight="bold">Non ci sono note al momento</Text>
            </Box>
            }
        </Box>
    );
}

export default ToDoList;
