import { gql } from '@apollo/client';
import {tenant,tenantType,tenantTypeFirstUpperCase} from "../tenant.js";

const ADD_TO_DO=gql`
mutation insertToDo($userCreator:bigint!,$checked:Boolean,$expiration:date,$text:String!,$title:String!,$haveAttachment:Boolean,$notificationOnExpire:Boolean,$notificationOnSave:Boolean,$createdAt:timestamptz,$customerId: bigint!){
  insert_${tenant}_users_todo_one(object:{user_creator_id:$userCreator,checked:$checked,expiration:$expiration,text:$text,title:$title,have_attachment:$haveAttachment,notification_on_expire:$notificationOnExpire,notification_on_save:$notificationOnSave,created_at:$createdAt, customer_id: $customerId}){
    id
    title
    text
    expiration
    notification_on_expire
    notification_on_save
  }
}
`

const UPDATE_TO_DO=gql`
mutation updateToDo($id:bigint!,$userCreator:bigint!,$checked:Boolean,$expiration:date,$text:String!,$title:String!,$haveAttachment:Boolean,$notificationOnExpire:Boolean,$customerId:bigint!,$customerId: bigint!){
  update_${tenant}_users_todo_by_pk(pk_columns:{id:$id}, _set:{user_creator_id:$userCreator,checked:$checked,expiration:$expiration,text:$text,title:$title,have_attachment:$haveAttachment,notification_on_expire:$notificationOnExpire, customer_id: $customerId}){
    id
    title
    text
    expiration
    notification_on_expire
  }
}
`

const DELETE_TO_DO=gql`
mutation deleteToDo($id:ID){
  ${tenant}{
    deleteToDo(id:$id){
      ok
      message
    }
  }
}
`

const QUERY_GET_TO_DO_TAGS=gql`
query GetToDoTags($contentTypeId:Int!){
  ${tenant}_tags_tags(where:{content_type_id:{_eq:$contentTypeId}}){
    id
    object_id
    tags_tagname{
      id
      name
    }
  }
}
`

const QUERY_TO_DO=gql`
query MyQuery($userId: bigint!) {
  ${tenant}_users_todo(
    where: {
      _or: [
        { users_userstodos: { users_user${tenantType}: { id: { _eq: $userId } } } },
        { user_creator_id: { _eq: $userId } }
      ]
      checked: { _eq: false }
    }
  ) {
    id
    title
    text
    expiration
    checked
    have_attachment
    users_user${tenantType} {
      id
      name
      surname
      registration_number
    }
    usersUser${tenantType}ByUserModifyId {
      id
      name
      surname
      registration_number
    }
    users_userstodos(where: {assigned: {_eq: true}}, order_by: {users_todo: {created_at: asc}}) {
      assigned
      users_user${tenantType} {
        name
        surname
        registration_number
      }
    }
    machines_machine${tenantType} {
      id
      name
    }
  }
}`

const QUERY_ASSIGNED_TO_DO=gql`
query getAssignedToDo($userId: bigint!) {
  ${tenant}_users_todo(where: {users_userstodos: {users_user${tenantType}: {id: {_eq: $userId}}}, checked: {_eq: false}}) {
    id
    title
    text
    expiration
    checked
    have_attachment
    users_user${tenantType} {
      id
      name
      surname
      registration_number
    }
    usersUser${tenantType}ByUserModifyId {
      id
      name
      surname
      registration_number
    }
    users_userstodos(where: {assigned: {_eq: true}}, order_by: {users_todo: {created_at: asc}}) {
      assigned
      users_user${tenantType} {
        id
        name
        surname
        registration_number
      }
    }
    machines_machine${tenantType} {
      id
      name
    }
  }
}`

const QUERY_ARCHIVED_TO_DO=gql`
query MyQuery($userId: bigint!) {
  ${tenant}_users_todo(
    where: {
      _or: [
        { users_userstodos: { users_user${tenantType}: { id: { _eq: $userId } } } },
        { user_creator_id: { _eq: $userId } }
      ]
      checked: { _eq: true }
    }
  ) {
    id
    title
    text
    expiration
    checked
    have_attachment
    users_user${tenantType} {
      id
      name
      surname
      registration_number
    }
    usersUser${tenantType}ByUserModifyId {
      id
      name
      surname
      registration_number
    }
    users_userstodos(where: {assigned: {_eq: true}}, order_by: {users_todo: {created_at: asc}}) {
      assigned
      users_user${tenantType} {
        name
        surname
        registration_number
      }
    }
    machines_machine${tenantType} {
      id
      name
    }
  }
}

`;

const GET_USER=gql`
query GetUsersToDo($areeId:[bigint!]){
  ${tenant}_users_userarea(where:{area_id:{_in:$areeId}},  distinct_on: user_id){
    users_user${tenantType}{
      id
      name
      surname
      registration_number
    }
  }
}
`

const CHECK_TO_DO=gql`
mutation checkToDo($input:ToDoMutationInput!){
  ${tenant}{
    upsertToDo(input:$input){
      toDo{
        id
        checked
      }
    }
  }
}
`

const ADD_USER_TO_DO_RELATION=gql`
mutation AddUsersToDoRelation($userId:bigint!,$toDoId:bigint!,$assigned:Boolean){
  insert_${tenant}_users_userstodo_one(object:{user_id:$userId,to_do_id:$toDoId,assigned:$assigned}){
    id
  }
}
`

const DELETE_USER_TO_DO_RELATION=gql`
mutation DeleteUsersToDoRelation($toDoId:bigint!){
 delete_${tenant}_users_userstodo(where:{to_do_id:{_eq:$toDoId}}){
    returning{
      id
    }
  }
}
`

const GET_TO_DO_ASSIGNED_USER=gql`
query getToDoAssignedUser($toDoId:bigint!){
  ${tenant}_users_userstodo(where:{to_do_id:{_eq:$toDoId}}){
    id
    assigned
    users_user${tenantType}{
      id
      name
      surname
      registration_number
    }
  }
}
`

const DELETE_ATTACHMENT=gql`
mutation deleteAttachment($id:ID!){
  ${tenant}{
    deleteAttachment(id:$id){
      ok
      message
    }
  }
}`

const ADD_USER_RESPONSE=gql`
mutation upsertResponse($input:UserResponseMutationInput!){
  ${tenant}{
    upsertUserResponse(input:$input){
      userResponse{
        id
      }
      errors{
        messages
      }
    }
  }
}`

const GET_USER_RESPONSE=gql`
query getUserResponseFromId($toDoId: bigint!) {
  ${tenant}_users_userresponse(where:{todo_id:{_eq:$toDoId}}) {
    todo_id
    text
    created_at
    system_message
    users_user${tenantType} {
      name
      surname
      id
    }
  }
}`

const GET_TAGS=gql`
query getTagName {
  ${tenant}_tags_tagname(order_by: {name: asc}) {
    id
    name
  }
}
`
const DELETE_TODO_TAG=gql`
mutation MyMutation($tags_id: bigint) {
  delete_${tenant}_tags_tags(where: {id: {_eq: $tags_id}}) {
    returning {
      tags_tagname {
        name
      }
      object_id
    }
  }
}
`

const SET_READ_RESPONSE=gql`
mutation MyMutation($user_id: bigint!, $ids_response: [bigint!], $todo_id: bigint!) {
  update_${tenant}_users_responseread(where: {user_in_chat_id: {_eq: $user_id}, response_id: {_in: $ids_response}, todo_id: {_eq: $todo_id}}, _set: {read: true}) {
    returning {
      response_id
      read
    }
  }
}
`

export {ADD_TO_DO,UPDATE_TO_DO,DELETE_TO_DO,QUERY_TO_DO,GET_USER,QUERY_ARCHIVED_TO_DO,QUERY_ASSIGNED_TO_DO,CHECK_TO_DO,ADD_USER_TO_DO_RELATION,DELETE_USER_TO_DO_RELATION,GET_TO_DO_ASSIGNED_USER,DELETE_ATTACHMENT,ADD_USER_RESPONSE,GET_USER_RESPONSE,GET_TAGS,QUERY_GET_TO_DO_TAGS,DELETE_TODO_TAG,SET_READ_RESPONSE}