import { gql } from '@apollo/client';
import { tenant, tenantTypeFirstUpperCase,tenantType } from '../../tenant';

const ADD_JOB=gql`
mutation AddJob($id:ID,$name:String!,$externalErpCode:String,$aree:String!,$assignedAutomaticallyToAllMachine:Boolean){
    ${tenant}{
      upsertJob${tenantTypeFirstUpperCase}(input:{id:$id,name:$name,externalErpCode:$externalErpCode,aree:$aree,assignedAutomaticallyToAllMachine:$assignedAutomaticallyToAllMachine}){
        job${tenantTypeFirstUpperCase}{
          name
          assignedAutomaticallyToAllMachine
        }
      }
    }
  }
`

const DELETE_JOB=gql`
mutation DeleteJob($id:ID!){
	${tenant}{
    deleteJobGolf(id:$id){
      ok
      message
    }
  }
}
`

const GET_JOB=gql`
query GetJob($id:bigint!){
    ${tenant}_jobs_job${tenantType}_by_pk(id:$id){
      id
      name
      external_gestional_code
      jobs_jobsareas{
        id
        job_id
        area_id
      }
    }
}
`

export {ADD_JOB,DELETE_JOB,GET_JOB}