import {useMutation, useSubscription} from "@apollo/client";
import {Box, Text} from "grommet";
import {DataGrid} from "@mui/x-data-grid";
import {tenant, tenantType} from "../../tenant.js";
import {DELETE_CUSTOMER, QUERY_CUSTOMER} from "./customerGQL.js";
import {Button as ButtonMui} from "@mui/material";
import QRCode_Gen from "qrcode.react";

function TableCustomer(props){

    const columnsMac = [
    { field: 'name', headerName: 'Nome cliente', width: 300 },
    { field: 'chassis_number', headerName: 'id cliente', width: 150 },
    {
      field: 'Modifica',
      headerName: 'Modifica',
      width: 120,
      renderCell: (cellValues) => {
        return (
              props?.modifyRegistry &&
          <ButtonMui
            variant="contained"
            color="primary"
            onClick={(event) => {
                props.setMacSel({...cellValues.row,canSel:""})
                props.setModifyMacchinari(true);
            }}
          >
            Modifica
          </ButtonMui>
        );},
    },
    {
      field: 'Cancella',
      headerName: 'Cancella',
      width: 150,
      renderCell: (cellValues) => {
        return (
              props?.modifyRegistry &&
          <ButtonMui
            variant="contained"
            color="error"
            onClick={(event) => {
              let res=window.confirm("Sei sicuro di voler eliminare il macchinario?");
              if(res){
                deleteMachine({variables:{ id:cellValues.row.id}})
              }
            }}
          >
            Cancella
          </ButtonMui>
        );},
    },

  ];

    const [deleteMachine]=useMutation(DELETE_CUSTOMER);

    const { data, loading } = useSubscription(
      QUERY_CUSTOMER,{variables:{areeId:props.aree.length>0? props.aree : JSON.parse(sessionStorage.getItem("areeId"))}}
    );

    return (
      <Box overflow="auto"  height="100%" width="100%" alignSelf='center' style={{marginTop:'40px'}}>
        <div style={{ height: '75vh', width: '100%' }}>
          <DataGrid
            rows={data && data[`${tenant}_machines_machine${tenantType}`] || []}
            columns={columnsMac}
          />
        </div>
      </Box>
    )
}

export default TableCustomer;

