import { gql } from '@apollo/client';
import { tenant,tenantType,tenantTypeFirstUpperCase } from '../tenant';
import {GraphQLBase} from "@apollo/client/react/hoc/hoc-utils.js";

const QUERY_GET_USERS=gql`
query GetResource($areeId:[bigint!]){
    ${tenant}_users_user${tenantType}(where:{users_userareas:{area_id:{_in:$areeId}}}, order_by: {name: asc}){
      id
      name
      surname
      fiscal_code
      cost_ext
      cost_int
      date_of_birth
      registration_number
      modify_registry
      show_in_calendar
      users_userareas{
        id
        area_id
      }
    }
}
`

const QUERY_GET_MACHINE_FROM_AREA=gql`
query GetMachineFromArea($areaId: bigint) {
  ${tenant}_machines_machinearea(
    where: { area_id: { _eq: $areaId } },
    order_by: [{ machine_id: asc }, { machine_id: asc }, { machines_machine${tenantType}: { name: asc } }],
    distinct_on: [machine_id]
  ) {
    machine_id
    machines_machine${tenantType} {
      id
      name
      chassis_number
    }
  }
}
`

const QUERY_GET_CUSTOMER_FROM_AREA=gql`
query GetCustomerFromArea($areaId: bigint) {
  ${tenant}_machines_machinearea(
    where: {machines_machine${tenantType}: {is_machine: {_eq: false}}}, 
    order_by: [{ machine_id: asc }, { machine_id: asc }, { machines_machine${tenantType}: { name: asc } }],
    distinct_on: [machine_id]
  ) {
    id
    machines_machine${tenantType} {
      id
      name
      chassis_number
    }
  }
}



`

const QUERY_GET_MACHINE_FROM_JOB=gql`
query GetMachineFromJob($jobId:bigint,$areaId:bigint!) {
  ${tenant}_machines_machinejobs(
    where:{job_id:{_eq:$jobId},machines_machine${tenantType}:{machines_machineareas:{area_id:{_eq:$areaId}}}},
    order_by: [{ machine_id: asc }, { machine_id: asc }, { machines_machine${tenantType}: { name: asc } }],
    distinct_on: [machine_id]
  ){
    id
    machines_machine${tenantType}{
      id
      name
      chassis_number
    }
  }
}
`

const QUERY_GET_CUSTOMER_FROM_JOB=gql`
query GetCustomerFromJob($jobId: bigint, $areaId: bigint!) {
  ${tenant}_machines_machinejobs(
    where: {job_id: {_eq: $jobId}, machines_machine${tenantType}: {machines_machineareas: {area_id: {_eq: $areaId}}, is_machine: {_eq: false}}}, 
     order_by: [{ machine_id: asc }, { machine_id: asc }, { machines_machine${tenantType}: { name: asc } }],
    distinct_on: [machine_id]
  ) {
    id
    machines_machine${tenantType} {
      id
      name
      chassis_number
    }
  }
}
`


const QUERY_GET_JOBS_FROM_MACHINE=gql`
query GetJobs($machineId: bigint, $areaId: bigint!) {
  ${tenant}_machines_machinejobs(where: {machine_id: {_eq: $machineId}, jobs_job${tenantType}: {jobs_jobsareas: {area_id: {_eq: $areaId}}}}, order_by: {jobs_job${tenantType}: {name: asc}}) {
    id
    jobs_job${tenantType} {
      id
      name
    }
  }
}
`

const QUERY_GET_JOBS_FROM_AREA=gql`
query GetJobsFromArea($areaId:bigint!){
	${tenant}_jobs_jobsarea(where:{area_id:{_eq:$areaId}} order_by: {jobs_job${tenantType}: {name: asc}}){
    id
    jobs_job${tenantType}{
      id
      name
    }
  }
}
`

const QUERY_GET_ACTIVITY=gql`
subscription GetActivity($start: timestamptz, $end: timestamptz,$areeId:[bigint!]) {
  ${tenant}_activity_activity${tenantType}(where: {start: {_gte: $start}, end: {_lte: $end},area_id:{_in:$areeId} }) {
    id
    area_id
    approved
    created_by_user
    notes
    operator_notes
    area_area${tenantType} {
      id
      name
      home_coordinates
    }
    start
    end
    notes
    gpsTrack_gpstrack {
      track
    }
    ordinary
    work_duration
    machines_machine${tenantType} {
      id
      name
      chassis_number
    }
    data
    ordinary
    jobs_job${tenantType} {
      id
      name
    }
    users_user${tenantType} {
      id
      name
      surname
      registration_number
    }
  }
}
`

const QUERY_GET_AREA=gql`
query GetArea($id: bigint) {
  ${tenant}_users_userarea(where: {user_id: {_eq: $id}, area_area${tenantType}: {father_id: {_is_null: false}}}) {
    id
    area_area${tenantType} {
      id
      name
      father_id
    }
  }
}
`

const ADD_ACTIVITY=gql`
mutation AddActivity($notes:String,$start:timestamptz!,$end:timestamptz!,$approved:String,$createdByUser:Boolean,$ordinary:String,$work_duration:float8,$completion_percentage:Int,$job:bigint!,$area:bigint!,$machine:bigint!,$user:bigint!,$data:date){
	insert_${tenant}_activity_activity${tenantType}_one(object:{notes:$notes,start:$start,end:$end,approved:$approved,created_by_user:$createdByUser,ordinary:$ordinary,work_duration:$work_duration,completion_percentage:$completion_percentage,job_id:$job,area_id:$area,machine_id:$machine,user_id:$user,data:$data}){
    id
    notes
    start
    end
    approved
    created_by_user
    ordinary
    work_duration
    completion_percentage
   	jobs_job${tenantType}{
      id
      name
    }
    area_area${tenantType}{
      id
      name
    }
    data
    users_user${tenantType}{
      id
      name
      surname
      registration_number
    }
    machines_machine${tenantType}{
      id
      name
      chassis_number
    }
    
  }
}
`

const UPDATE_ACTIVITY=gql`
mutation UpdateActivity($id: bigint!, $notes: String, $start: timestamptz!, $end: timestamptz!, $approved: String, $created_by_user: Boolean, $ordinary: String, $work_duration: float8, $completion_percentage: Int, $job: bigint, $area: bigint, $machine: bigint,$user:bigint!,$data:date) {
  update_${tenant}_activity_activity${tenantType}_by_pk(pk_columns: {id: $id}, _set: {notes: $notes, start: $start, end: $end, approved: $approved, created_by_user: $created_by_user, ordinary: $ordinary, work_duration: $work_duration, completion_percentage: $completion_percentage, job_id: $job, area_id: $area, machine_id: $machine,user_id:$user,data:$data}) {
    id
    notes
    start
    end
    approved
    created_by_user
    ordinary
    work_duration
    completion_percentage
    jobs_job${tenantType} {
      id
      name
    }
    area_area${tenantType} {
      id
      name
    }
    data
    users_user${tenantType} {
      id
      name
      surname
      registration_number
    }
    machines_machine${tenantType} {
      id
      name
      chassis_number
    }
    activity_activitypartecipants{
      id
      users_user${tenantType}{
        id
        name
        surname
      }
    }
  }
}
`

const DELETE_ACTIVITY=gql`
mutation deleteActivity($id:ID!){
  ${tenant}{
    deleteActivity${tenantType}(id:$id){
      ok
      message
    }
  }
}
`

const QUERY_GET_SINGLE_ACTIVITY=gql`
query GetActivity($id:bigint!){
  ${tenant}_activity_activity${tenantType}_by_pk(id:$id){
    id
    area_id
    approved
    created_by_user
    notes
    start 
    end
    area_area${tenantType}{
      id
      name
    }
    start
    end
    notes
    gpsTrack_gpstrack{
      track
    }
    ordinary
    work_duration
    machines_machine${tenantType}{
      id
      name
      chassis_number
    }
    data
    ordinary
    jobs_job${tenantType}{
      id
      name
    }
    users_user${tenantType}{
      id
      name
      surname
      registration_number
    }
    activity_activitypartecipants{
      id
      users_user${tenantType}{
        id
        name
        surname
      }
    }
  }
}
`

const GET_USER_TREE=gql`
query TreeUser($id:Int!){
  ${tenant}{
    user${tenantTypeFirstUpperCase}(id:$id){
      treeArea
    }
  }
}
`

const QUERY_ACTIVITY_INTERSECT=gql`
query ActivityIntersect($id:Int!){
	${tenant}{
    activity(id:$id){
      areaIntersectByActivityTrack
    }
  }
}
`

const QUERY_GET_GPS_TRACK=gql`
query activityTrack($id:bigint!){
${tenant}_activity_activity${tenantType}_by_pk(id:$id){
    gpsTrack_gpstrack{
      track
    }
  }
}
`

const USERS_DAILY_HOURS=gql`
query UserGetDailyHour($date:DateTime,$area:[ID!]){
  ${tenant}{
    userGolfDailyHour(date:$date,area:$area)
  }
}
`

const MUTATION_PARTECIPANT=gql`
mutation activityPartecipant($activityLeader:ID!,$user:ID!){
  ${tenant}{
    upsertActivitypartecipant(input:{activityLeader:$activityLeader,user:$user}){
      activityPartecipant{
        id
      }
    }
  }
}
`

const GET_TENANT_DATA=gql`
query getTenantOption($name:String){
  core_tenant(where:{name:{_eq:$name}}){
    export_csv_external_code
  }
}
`
export {QUERY_GET_USERS,QUERY_GET_ACTIVITY,QUERY_GET_AREA,ADD_ACTIVITY,UPDATE_ACTIVITY,DELETE_ACTIVITY,QUERY_GET_SINGLE_ACTIVITY,GET_USER_TREE,QUERY_GET_MACHINE_FROM_JOB,QUERY_GET_MACHINE_FROM_AREA,QUERY_GET_JOBS_FROM_MACHINE,QUERY_GET_JOBS_FROM_AREA,QUERY_ACTIVITY_INTERSECT,QUERY_GET_GPS_TRACK,USERS_DAILY_HOURS,MUTATION_PARTECIPANT,QUERY_GET_CUSTOMER_FROM_AREA,QUERY_GET_CUSTOMER_FROM_JOB};