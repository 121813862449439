import React from 'react';
import { Box, Chip } from '@mui/material';
import {useLazyQuery, useMutation} from "@apollo/client";
import {DELETE_TODO_TAG, QUERY_GET_TO_DO_TAGS} from "./ToDoGQL.jsx";
import {tenant} from "../tenant.js"; // Assuming you're using Material-UI components
// Define your TagsChip component
const TagsChip = ({ toDo, todoTagsList, setToDoTagsList, contentType, ci }) => {

    const [deleteToDoTag] = useMutation(DELETE_TODO_TAG)
    const [ queryGetToDoTags
    ] = useLazyQuery(QUERY_GET_TO_DO_TAGS, {
        fetchPolicy: "no-cache",
        onCompleted: (data) => {
            if(data){
                const dataApp=data[`${tenant}_tags_tags`];
                if(dataApp){
                    console.log("TO DO TAGS",dataApp)
                    setToDoTagsList(dataApp)
                }
            }
        },
        notifyOnNetworkStatusChange: true, // did the work
    });

    function handleDeleteTag(tags_id) {
        deleteToDoTag({variables: {tags_id: tags_id}})
        queryGetToDoTags({variables:{contentTypeId:contentType?.id}});
    }

    // Filter the tags associated with the current toDo item
    const tags = Array.isArray(todoTagsList) && todoTagsList?.length > 0
        ? todoTagsList.filter((el) => el?.object_id === toDo?.id)
        : [];

    // Render the tags if any are found
    if (tags.length > 0) {
        return (
            <Box direction="row" gap={"small"} margin={"small"}>
                {
                    tags.map((el) => (
                        <Chip
                            key={el?.id} // unique key for each tag
                            label={el?.tags_tagname?.name || "Unnamed Tag"}
                            onDelete={() => handleDeleteTag(el?.id)} // Ensure handleDelete is passed down as prop
                        />
                    ))
                }
            </Box>
        );
    }
    // Return an empty fragment if no tags are found
    return <></>;
};

// Add default props to ensure the component behaves well even if some props are not passed
TagsChip.defaultProps = {
    toDo: {},
    todoTagsList: [],
    handleDelete: () => {},
};

export default TagsChip;
