import {Card, CardBody, CardHeader, Layer} from "grommet";
import {Box as BoxMui} from "@mui/material";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send.js";
import React, {useState,useRef} from "react";
import {extractIdFormData} from "../../utilis/utils.js";
import {useLazyQuery, useMutation} from "@apollo/client";
import {ADD_USER_RESPONSE, GET_USER_RESPONSE, SET_READ_RESPONSE} from "./ToDoGQL.jsx";
import {tenant, tenantType} from "../tenant.js";
import moment from "moment";
import { set } from "date-fns";

const LayerUserResponse=(props)=>{

    const formatedId="testo"+props?.formDataUserResponse?.todo;
    const [addUserResponse]=useMutation(ADD_USER_RESPONSE);
    const [message,setMessage]=useState("");
    const [userResponse,setUserResponse]=React.useState([]);
    const scrollChat = React.useRef(null);

    const [setReadResponse]=useMutation(SET_READ_RESPONSE);

    const [queryGetUserResponse] = useLazyQuery(GET_USER_RESPONSE, {
        fetchPolicy: "no-cache",
        onCompleted: (data) => {
            const dataApp= data[`${tenant}_users_userresponse`];
            console.log("DATA",dataApp);
            if(dataApp){
                dataApp.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
                dataApp.forEach(item => {
                    const date = new Date(item.created_at);
                    //date.setHours(date.getHours() + 2);
                    const formattedDate = moment(date).format('DD/MM/YYYY HH:mm');  //`${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
                    item.created_at = formattedDate;
                    const fieldName = `users_user${tenantType}`;
                    item.user= item[fieldName];  // Sposta il contenuto del campo specifico sotto 'user'
                    delete item[fieldName]; // Elimina il vecchio campo
                });
                console.log("data elaborated",dataApp);
                setUserResponse(dataApp);
            }
        },
        notifyOnNetworkStatusChange: true, // did the work
    });

    const scrollChatToBottom = () => {
        const myDiv = scrollChat?.current;
        if(myDiv){
            setTimeout(() => {
                myDiv.scrollTop = myDiv.scrollHeight;
            }, 100); // Attendi un attimo prima di scrollare
        }
    };

    React.useEffect(()=>{
        queryGetUserResponse({variables:{toDoId:props?.formDataUserResponse?.todo}})
        scrollChatToBottom();
    },[props?.formDataUserResponse])

    React.useEffect(()=>{
        let response_not_read_of_todo=props?.responseNotRead.filter((el) => el?.todo_id === props?.formDataUserResponse?.todo)
        let ids_response=[];
        for (let i = 0; i < response_not_read_of_todo.length; i++) {
            ids_response.push(response_not_read_of_todo[i].response_id)
        }

        setReadResponse({
            variables: {
              user_id: parseInt(sessionStorage.getItem("userId"), 10), 
              ids_response: ids_response,
              todo_id: props?.formDataUserResponse?.todo
            }
        });
          
    },[props?.responseNotRead])

    React.useEffect(() => {
        scrollChatToBottom();
    }, [userResponse]);

    function handleUpsertUserResponse(){
        let formDataApp={input:{}}
        formDataApp.input={...props?.formDataUserResponse};
        formDataApp.input["userCreator"]=sessionStorage.getItem("userId")
        formDataApp.input["text"]=message;
        let obj=extractIdFormData(formDataApp,true);
        console.log("OBJ",obj);
        addUserResponse(obj)
            .then((value)=>{
                queryGetUserResponse({variables:{toDoId:props?.formDataUserResponse?.todo}})
                setMessage("");
                scrollChatToBottom();
            })

    }


    return(
        <Layer
            onEsc={() => {props?.setShow(false);props?.setFormDataUserResponse({});}}
            onClick={(e) => e.stopPropagation()}
            onClickOutside={(event) => {
                // Se il clic avviene su un elemento che non è il TextField, chiudi il layer
                if (!event.target.closest(".no-close")) {
                    props?.setShow(false);
                    props?.setFormDataUserResponse({});
                }}}
            style={{ width: "1000px", height: "650px", padding: "40px" }}
        >
            <BoxMui
                ref={scrollChat}
                direction="column"
                height={"500px"}
                overflow={"auto"}
                style={{ paddingLeft:"20px" }}
            >
                {userResponse.length > 0 ?
                   userResponse.map((response, i) => (
                        <Card
                            key={i}
                            style={{
                                width: response.system_message ? "600px" : "400px",
                                height: "fit content",
                                padding: "20px",
                                marginBottom: "20px",
                                marginLeft:  response.system_message ? "160px" : response.user.id == sessionStorage.getItem("userId") ? "560px" : "0px",
                                background: response.system_message ? "#397aa3" :response.user.id == sessionStorage.getItem("userId") ? "rgba(30, 234, 50, 0.5)" : "inherit",
                                fontSize: response.system_message ? "12px" : "14px",
                                color: response.system_message ? "white" : "black"
                            }}
                        >
                            {!response.system_message && (
                                <CardHeader>
                                    <b>{response.user.name} {response.user.surname} ({response.user.id})</b> <i>{response.created_at}</i>
                                </CardHeader>
                            )}

                            <CardBody>
                                {response.system_message ? (
                                    <center>
                                    <span>
                                        <b>{response.user.name} {response.user.surname} ({response.user.id})</b> {response.text} in data <i>{response.created_at}</i>
                                    </span>
                                    </center>
                                ) : (
                                    <p>{response.text}</p>
                                )}
                            </CardBody>

                        </Card>
                    ))
                    : null
                }
            </BoxMui>
            <BoxMui onChange={event =>{ event.stopPropagation();}} width={"1000px"} height={"150px"} style={{paddingTop:"100px"}}>
                <TextField value={message} style={{width:"900px"}} placeholder="Messaggio" variant="standard" onClick={(e) => e.stopPropagation()} onChange={(event) =>{ event.stopPropagation(); setMessage(event.target.value);}} />
                <SendIcon  style={{marginLeft:"40px"}} onClick={(e) => {handleUpsertUserResponse()}}></SendIcon>
            </BoxMui>
        </Layer>
    )
}

export default LayerUserResponse;