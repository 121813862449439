import React from "react";
import { Button} from 'grommet';
import { Chat } from 'grommet-icons';
import Badge from '@mui/material/Badge';


const BadgeMessage = ({ toDo, responseNotRead, setFormDataUserResponse, setShow }) => {
    console.log("BADGE MESSAGE TO DO",toDo)
    console.log("BADGE MESSAGE RESPONSE NOT READ",responseNotRead)
    let numberOfMessages = Array.isArray(responseNotRead) && responseNotRead?.length > 0 && responseNotRead.filter((el) => el?.todo_id === toDo?.id) || null
    console.log("BADGE MESSAGE NUMBER OF MESSAGES",numberOfMessages)
    if (numberOfMessages) {
        let lenght = numberOfMessages.length
        return (
            <Button>
                <Badge badgeContent={lenght} color="primary">
                    <Chat onClick={(e)=> {setFormDataUserResponse({todo:toDo.id}); setShow(true)}}></Chat>
                </Badge>
            </Button>
        )
    }else{
        return (
            <Button>
                <Chat onClick={(e)=> {setFormDataUserResponse({todo:toDo.id}); setShow(true)}}></Chat>
            </Button>
        )
    }
};

export default BadgeMessage;