import {Box, Button, DateInput, DropButton, Select, Tab, Text} from "grommet";
import {Box as BoxMaterial, Grid as GridMaterial} from "@material-ui/core";
import {Container} from "@mui/material";
import {OreVeicolo} from "./components/oreVeicolo.js";
import {LivelloCarburante} from "./components/livelloCarburante.js";
import {OreLame} from "./components/oreLame.js";
import {ConsumoMedio} from "./components/consumoMedio.js";
import {GraficoGiriMotore} from "./components/graficoGiriMotore.js";
import {GraficoTemperaturaOlio} from "./components/graficoTemperaturaOlio.js";
import {GraficoTensione} from "./components/graficoTensione.js";
import {GraficoPressioneFiltro} from "./components/graficoPressFiltro.js";
import {Calendar, Download} from "grommet-icons";
import moment from "moment/moment.js";
import React from "react";
import {useLazyQuery} from "@apollo/client";
import {tenant, tenantType} from "../../tenant.js";
import {GET_DASHBOARD_DATA, QUERY_MAC} from "../DashboardGQL.js";
import LoadingLayer from "../../loadingLayer/LoadingLayer.jsx";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import autoTable from "jspdf-autotable";

const DashboardCanEdge = React.memo(( props ) => {

    const [macList,setMacList]=React.useState([]);
    const [macSel,setMacSel]=React.useState();
    const [dateFilter,setDateFilter]=React.useState({start:moment(new Date()).format(),end:moment(new Date()).format()});
    const [dashboardData,setDashboardData] = React.useState()
    const [showLayerLoading,setShowLayerLoading] = React.useState(false);
    const [ queryGetMacList ] = useLazyQuery(QUERY_MAC, {
        fetchPolicy: "no-cache",
        onCompleted: (data) => {
            console.log(data);
            const macListApp=data[`${tenant}_machines_machine${tenantType}`]
            if(macListApp){
                setMacList(macListApp);
            }
        },
        notifyOnNetworkStatusChange: true, // did the work
      });
    const [ queryGetDashboardData , {loading} ] = useLazyQuery(GET_DASHBOARD_DATA, {
        fetchPolicy: "no-cache",
        onCompleted: (data) => {
            console.log("DATA DASHBOARD",data);
            const dashboardData=data[tenant][`machineTimeSeries`]
            if(dashboardData){
                setDashboardData(dashboardData);
            }
        },
        notifyOnNetworkStatusChange: true, // did the work
      });

    function downloadDatiMacchina(){
      setShowLayerLoading(true);
      const doc = new jsPDF();
      doc.setFontSize(22);
      doc.addImage("./images/Logo1.png", 'JPEG',65, 70 , 80, 90);
      doc.text(30, 180, macSel?.name);
      doc.text(30, 200, "Report da: "+moment(dateFilter?.start).format("DD/MM/YYYY")+" a: "+moment(dateFilter?.end).format("DD/MM/YYYY"));
      doc.addPage();
      const oreVeicolo = document.getElementById('oreVeicolo');
      const livelloCarburante= document.getElementById('livelloCarburante');
      const oreLame= document.getElementById('oreLame');
      const consumoMedio= document.getElementById('consumoMedio');
      const graficoGiriMotore=document.getElementById('graficoGiriMotore');
      const graficoTemperaturaOlio=document.getElementById('graficoTemperaturaOlio');
      const graficoTensione=document.getElementById('graficoTensione');
      const graficoCentralineLame= document.getElementById('graficoCentralinaLame');
      const graficoPressioneFiltro=document.getElementById('graficoPressioneFiltro');
      html2canvas((oreVeicolo),{
        width: oreVeicolo.width,
        height: oreVeicolo.height,
        useCORS:true,
      }).then((canvas) => {
          var image = canvas.toDataURL('image/jpg', 1.0);
          doc.addImage(image, 'JPG', 2, 10,100,40);
          html2canvas((livelloCarburante),{
            width: livelloCarburante.width,
            height: livelloCarburante.height,
            useCORS:true,
          }).then((canvas) => {
              var image = canvas.toDataURL('image/jpg', 1.0);
              doc.addImage(image, 'JPG', 107, 10,100,40);
              html2canvas((oreLame),{
                width: oreLame.width,
                height: oreLame.height,
                useCORS:true,
              }).then((canvas) => {
                  var image = canvas.toDataURL('image/jpg', 1.0);
                  doc.addImage(image, 'JPG', 2, 50,100,40);
                  html2canvas((consumoMedio),{
                    width: consumoMedio.width,
                    height: consumoMedio.height,
                    useCORS:true,
                  }).then((canvas) => {
                      var image = canvas.toDataURL('image/jpg', 1.0);
                      doc.addImage(image, 'JPG', 107, 50,100,40);
                      html2canvas((graficoGiriMotore),{
                        width: graficoGiriMotore.width,
                        height: graficoGiriMotore.height,
                        useCORS:true,
                      }).then((canvas) => {
                          var image = canvas.toDataURL('image/jpg', 1.0);
                          doc.addImage(image, 'JPG', 5, 110,200,100);
                          doc.addPage();
                          html2canvas((graficoTemperaturaOlio),{
                            width: graficoTemperaturaOlio.width,
                            height: graficoTemperaturaOlio.height,
                            useCORS:true,
                          }).then((canvas) => {
                              var image = canvas.toDataURL('image/jpg', 1.0);
                              doc.addImage(image, 'JPG', 5, 15,200,100);
                              html2canvas((graficoTensione),{
                                width: graficoTensione.width,
                                height: graficoTensione.height,
                                useCORS:true,
                              }).then((canvas) => {
                                  var image = canvas.toDataURL('image/jpg', 1.0);
                                  doc.addImage(image, 'JPG', 5, 120,200,100);
                                  doc.addPage();
                                  html2canvas((graficoPressioneFiltro),{
                                    width: graficoPressioneFiltro.width,
                                    height: graficoPressioneFiltro.height,
                                    useCORS:true,
                                  }).then((canvas) => {
                                      var image = canvas.toDataURL('image/jpg', 1.0);
                                      doc.addImage(image, 'JPG', 5, 15,200,100);
                                      doc.addPage();
                                      const headGiriMotore=[{header:"Giri Motore",dataKey: "data"},{header:"TimeStamp",dataKey: "timeStamp"}];
                                      autoTable(doc, {
                                        columns: headGiriMotore,
                                        body: dashboardData?.datasetEngineSpeed,
                                      });
                                      doc.addPage();
                                      const headTempOlio=[{header:"Temperatura fluido idraulico",dataKey: "data"},{header:"TimeStamp",dataKey: "timeStamp"}];
                                      autoTable(doc, {
                                        columns: headTempOlio,
                                        body: dashboardData?.datasetOilTemp,
                                      });
                                      doc.addPage();
                                      const headTensioneBatteria=[{header:"Tensione batteria",dataKey: "data"},{header:"TimeStamp",dataKey: "timeStamp"}];
                                      autoTable(doc, {
                                        columns: headTensioneBatteria,
                                        body: dashboardData?.datasetBatteryPotential,
                                      });
                                      doc.addPage();
                                      const headTempLiquidoRaffr=[{header:"Temperatura liquido raffredamento",dataKey: "data"},{header:"TimeStamp",dataKey: "timeStamp"}];
                                      headTempLiquidoRaffr.push();
                                      autoTable(doc, {
                                        columns: headTempLiquidoRaffr,
                                        body: dashboardData?.datasetCoolTemp,
                                      });
                                      doc.addPage();
                                      const headPressioneFiltro=[{header:"Pressione differenziale filtro antiparticolato",dataKey: "data"},{header:"TimeStamp",dataKey: "timeStamp"}];
                                      autoTable(doc, {
                                        columns: headPressioneFiltro,
                                        body: dashboardData?.datasetParticulateFilterPressure,
                                      });
                                      setShowLayerLoading(false);
                                      doc.save();
                                      },1200)
                                    });
                                });
                            });
                        });
                    });
                });
            });
    }


    React.useEffect(()=>{
        queryGetMacList({variables:{areeId: props.aree || JSON.parse(sessionStorage.getItem("areeId"))}})
    },[props.aree])

    React.useEffect(()=>{
        queryGetDashboardData({variables:{canId:macSel?.machines_canmachines[macSel?.machines_canmachines.length-1]?.machines_can?.can_number,start:dateFilter?.start,end:dateFilter?.end}})
    },[macSel,dateFilter])

    return(
        <Tab title="Dati 4.0">
            <Box height="92vh" overflow="auto">
                <BoxMaterial
                      component="main"
                      sx={{
                        flexGrow: 1,
                        py: 8
                      }}
                    >
                  <Box direction="column" alignSelf="center" align="center" gap="large">
                    <Box direction="row" gap="small" width="700px" align="center" >
                      <Text color="#397aa3" margin="small" style={{fontWeight:'bold'}}>SELEZIONA MACCHINA </Text>
                      <Select
                      options={macList}
                      labelKey="name"
                      value={macSel}
                      onChange={(option) =>{setMacSel(option.value)}}
                      />
                    </Box>
                  <Container maxWidth={false}>
                    <GridMaterial
                      container
                      spacing={4}
                    >
                      <GridMaterial
                        item
                        lg={3}
                        sm={6}
                        xl={3}
                        xs={12}
                      >
                        <OreVeicolo oreVeicolo={dashboardData?.totalVehicleHours || null} id="oreVeicolo" />
                      </GridMaterial>
                      <GridMaterial
                        item
                        xl={3}
                        lg={3}
                        sm={6}
                        xs={12}
                      >
                        <LivelloCarburante livelloCarburante={dashboardData?.fuelLevel} id="livelloCarburante"/>
                      </GridMaterial>
                      <GridMaterial
                        item
                        xl={3}
                        lg={3}
                        sm={6}
                        xs={12}
                      >
                        <OreLame oreLame={dashboardData?.bladesHour || null} erroreOreLame={dashboardData?.totalVehicleHours<dashboardData?.bladesHour || null} id="oreLame"/>
                      </GridMaterial>
                      <GridMaterial
                        item
                        lg={3}
                        sm={6}
                        xl={3}
                        xs={12}
                      >
                        <ConsumoMedio consumoMedio={dashboardData?.averageConsumption || null} id="consumoMedio"/>
                      </GridMaterial>
                      <Box direction="row" width="100%" gap="small" pad="small">
                        <GridMaterial
                          item
                          lg={8}
                          md={12}
                          xl={9}
                          xs={12}
                        >
                          <GraficoGiriMotore datasetGiriMotore={dashboardData?.datasetEngineSpeed || null} id="graficoGiriMotore"/>
                        </GridMaterial>
                        <GridMaterial
                          item
                          lg={8}
                          md={12}
                          xl={9}
                          xs={12}
                        >
                          <GraficoTemperaturaOlio datasetTempOlio={dashboardData?.datasetOilTemp || null} dataSetCoolTemp={dashboardData?.datasetCoolTemp || null} id="graficoTemperaturaOlio"/>
                        </GridMaterial>
                      </Box>
                      <Box direction="row" width="100%" gap="small" pad="small">
                        <GridMaterial
                          item
                          lg={8}
                          md={12}
                          xl={9}
                          xs={12}
                        >
                          <GraficoTensione datasetTensione={dashboardData?.datasetBatteryPotential || null} id="graficoTensione"/>
                        </GridMaterial>
                        <GridMaterial
                          item
                          lg={8}
                          md={12}
                          xl={9}
                          xs={12}
                        >
                          <GraficoPressioneFiltro datasetPressioneFiltro={dashboardData?.datasetParticulateFilterPressure || null} id="graficoPressioneFiltro"/>
                        </GridMaterial>
                      </Box>
                    </GridMaterial>
                  </Container>
                  </Box>
                  <Box margin='small' gap="small" alignSelf="end" style={{position:'fixed',bottom:'3%',zIndex:'5',right:'2%'}}>
                      <Box alignSelf="end">
                        <Button
                          icon={<Download color="white"/>}
                          onClick={()=>downloadDatiMacchina()}
                          style={{fontSize:'28px',background:'#8FBCA7', padding:'10px',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}>
                        </Button>
                      </Box>
                      <DropButton
                              icon={<Calendar color='white'/>}
                              style={{fontSize:'28px',background:'#397aa3', padding:'10px',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}
                              dropAlign={{ top: 'bottom', right: 'left' }}
                              dropContent={
                                <>
                                <Box pad="medium" gap="medium">
                                  <Box direction="row" gap="small">
                                    <Text>Data inizio: </Text>
                                    <DateInput
                                      format="mm/dd/yyyy"
                                      value={dateFilter?.start}
                                      onChange={({ value }) => {setDateFilter({...dateFilter,start:moment(value).format()})}}
                                      dropProps={{align:{bottom:"top"}}}
                                      calendarProps={{ size: "small", locale: "it-IT", daysOfWeek: true, firstDayOfWeek: 1 }}
                                    />
                                    </Box>
                                    <Box direction="row" gap="small">
                                    <Text>Data Fine: </Text>
                                    <DateInput
                                      format="mm/dd/yyyy"
                                      value={dateFilter?.end}
                                      onChange={({ value }) =>{setDateFilter({...dateFilter,end:moment(value).format()})}}
                                      dropProps={{align:{bottom:"top"}}}
                                      calendarProps={{ size: "small", locale: "it-IT", daysOfWeek: true, firstDayOfWeek: 1 }}
                                    />
                                    </Box>
                                  </Box>
                                </>

                              }
                            />
                    </Box>
                </BoxMaterial>
                </Box>
            {
                loading && <LoadingLayer/>
            }
        </Tab>

    )
});

export default DashboardCanEdge