import {Box,Text,CheckBox,DropButton,Select,Anchor} from "grommet";
import {useMutation, useSubscription, gql, useLazyQuery} from '@apollo/client';
import React from "react";
import {QUERY_USERS} from "../registryComponent/registryComponentsGQL";
import {tenant, tenantType} from "../tenant";
import {QUERY_GET_USERS, UPDATE_ACTIVITY, USERS_DAILY_HOURS} from "./activityGQL";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import {useHistory} from "react-router-dom";
import CustomCalendar from "./calendar"
import moment from "moment";
import {Filter} from "grommet-icons";
import LoadingLayer from "../loadingLayer/LoadingLayer.jsx";
import {oreMinutiToString} from "../../utilis/utils.js";
import LayerDailyHours from "./LayerDailyHours.jsx";
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

function AttivitaDet1(props){

  let history=useHistory();
  const [resources,setResources]=React.useState([]);
  const [resourceViewMode,setResourceViewMode]=React.useState("default");
  const [dateFilter,setDateFilter]=React.useState({start:moment().startOf('day').format(),end:moment().add("days",3).format()})
  const [dropdownFiltriDip,setDropdownFiltriDip]=React.useState(null);
  const [dropdownFiltriMac,setDropDownFiltriMac]=React.useState(null);
  const [dropdownFiltroLav,setDropdownFiltroLav]=React.useState(null);
  const [dropdownFiltriStato,setDropDownFiltriStato]=React.useState(null);
  const [updateActivity]=useMutation(UPDATE_ACTIVITY)
  const [queryVariables,setQueryVariables]=React.useState({})
  let [filtroDip, setFiltroDip] = React.useState(false); //sessionStorage.getItem('dipendente') !== null ? (sessionStorage.getItem('dipendente') !== '' ? true : false) : false)
  let [filtroMac, setFiltroMac] = React.useState(sessionStorage.getItem('mac') !== null ? (sessionStorage.getItem('mac') !== '' ? true : false) : false);
  let [filtroLav, setFiltroLav] = React.useState(sessionStorage.getItem('lav') !== null ? (sessionStorage.getItem('lav') !== '' ? true : false) : false);
  let [filtroStato, setFiltroStato] = React.useState(sessionStorage.getItem('stato') !== null ? (sessionStorage.getItem('stato') !== '' ? true : false) : false);
  let [filtroArea,setFiltroArea] = React.useState(sessionStorage.getItem('area') !== null ? (sessionStorage.getItem('area') !== '' ? true : false) : false)
  const [usersDailyHours,setUsersDailyHours] = React.useState([]);
  const [showLayerUserDailyHour,setShowLayerUserDailyHour]=React.useState(false);
  const [modifyMode,setModifyMode] = React.useState(  sessionStorage.getItem("modifyMode")==="true");
  const [ queryGetUserDailyHours
  ]  = useLazyQuery(USERS_DAILY_HOURS, {
  fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const obj=data[`${tenant}`]["userGolfDailyHour"]
      if(obj){
        const hours=JSON.parse(obj)
        let usersDailyHourApp=[];
        for(let i=0;i<resources.length;i++){
          const resource=resources[i];
          if(hours[resource.registration_number]){
            usersDailyHourApp.push({text:resource.text,hours: oreMinutiToString(hours[resource.registration_number]/60)})
          }
        }
        setUsersDailyHours(usersDailyHourApp);
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });



  React.useEffect(()=>{
    if(sessionStorage.getItem("selectedDate") && sessionStorage.getItem("selectedDate")!=="undefined"){
      setDateFilter({start: moment(sessionStorage.getItem("selectedDate")).format(),end:moment().add("days",1).format()})
    }


    const queryVaribales=sessionStorage.getItem("queryVariables");
    if(queryVaribales){
      setQueryVariables(JSON.parse(queryVaribales));
    }
    const lav=sessionStorage.getItem("lav");
    if(lav){
      setDropdownFiltroLav(sessionStorage.getItem("dropdownFiltroLav"))
      setFiltroLav(lav==="true");
    }

    const dip=sessionStorage.getItem("dipendente");
    if(dip){
      setDropdownFiltriDip(sessionStorage.getItem("dropdownFiltriDip"))
      setFiltroDip(dip==="true");
    }

    const mac=sessionStorage.getItem("mac");
    if(mac){
      setDropDownFiltriMac(sessionStorage.getItem("dropdownFiltriMac"))
      setFiltroMac(mac==="true");
    }

    const stato=sessionStorage.getItem("stato");
    if(stato) {
      setDropDownFiltriStato(sessionStorage.getItem("dropdownFiltriStato"))
      setFiltroStato(stato === "true");
    }

    const selectedDate=sessionStorage.getItem("selectedDate");
    if(!selectedDate){
      sessionStorage.setItem("selectedDate",moment().startOf('day').format());
    }
  },[])

  React.useEffect(()=>{
    if(data && dropdownFiltriDip?.id){
      sessionStorage.setItem("dropdownFiltriDip",dropdownFiltriDip.id);
      setQueryVariables({...queryVariables,user_id:{"_eq":dropdownFiltriDip.id}})
    }

    if(dropdownFiltriDip===null){
       if(queryVariables["user_id"]){
          let queryVariablesApp={...queryVariables};
          delete queryVariablesApp.user_id;
          setQueryVariables((queryVariablesApp))
        }
    }

  },[dropdownFiltriDip])

  React.useEffect(()=>{
    if(!filtroDip){
      let queryVariablesApp={...queryVariables};
      if(queryVariablesApp.user_id){
        delete queryVariablesApp.user_id
        setQueryVariables(queryVariablesApp)
      }
    }
  },[filtroDip])

  React.useEffect(()=>{
    if(data && dropdownFiltriMac?.id){
      sessionStorage.setItem("dropdownFiltriMac",dropdownFiltriMac.id);
      setQueryVariables({...queryVariables,machine_id:{"_eq":dropdownFiltriMac.id}})
    }

    if(dropdownFiltriMac===null){
       if(queryVariables["machine_id"]){
          let queryVariablesApp={...queryVariables};
          delete queryVariablesApp.machine_id;
          setQueryVariables((queryVariablesApp))
        }
    }

  },[dropdownFiltriMac])

  React.useEffect(()=>{
    if(!filtroMac){
      let queryVariablesApp={...queryVariables};
      if(queryVariablesApp.machine_id){
        delete queryVariablesApp.machine_id
        setQueryVariables(queryVariablesApp)
      }
    }
  },[filtroMac])

  
  React.useEffect(()=>{
    if(data && dropdownFiltroLav?.id){
      sessionStorage.setItem("dropdownFiltroLav",dropdownFiltroLav.id);
      setQueryVariables({...queryVariables,job_id:{"_eq":dropdownFiltroLav.id}})
    }

     if(dropdownFiltroLav===null){
       if(queryVariables["job_id"]){
          let queryVariablesApp={...queryVariables};
          delete queryVariablesApp.job_id;
          setQueryVariables((queryVariablesApp))
        }
    }

  },[dropdownFiltroLav])

  React.useEffect(()=>{
    if(!filtroMac){
      let queryVariablesApp={...queryVariables};
      if(queryVariablesApp.job_id){
        delete queryVariablesApp.job_id
        setQueryVariables(queryVariablesApp)
      }
    }
  },[filtroLav])

  
  React.useEffect(()=>{
    if(data){
       sessionStorage.setItem("dropdownFiltriStato",dropdownFiltriStato);
      setQueryVariables({...queryVariables,approved:{"_eq":dropdownFiltriStato}})
    }

     if(filtroStato===null){
       if(queryVariables["approved"]){
          let queryVariablesApp={...queryVariables};
          delete queryVariablesApp.approved;
          setQueryVariables((queryVariablesApp))
        }
    }

  },[dropdownFiltriStato])

  React.useEffect(()=>{
    if(!filtroStato){
      let queryVariablesApp={...queryVariables};
      if(queryVariablesApp.approved){
        delete queryVariablesApp.approved
        setQueryVariables(queryVariablesApp)
      }
    }
  },[filtroStato])

  React.useEffect(()=>{
    if(queryVariables){
      sessionStorage.setItem("queryVariables",JSON.stringify(queryVariables));
    }
  },[queryVariables])

  useSubscription(QUERY_USERS,{
    variables:{areeId:props.aree.length>0? props.aree : JSON.parse(sessionStorage.getItem("areeId"))},
    onData:({data:data})=>{
      const res=data.data[`${tenant}_users_user${tenantType}`];
      if(res){
        const resourcesApp=[];
        for(let i=0;i<res.length;i++){
          const item=res[i];
          if(item?.show_in_calendar){
            resourcesApp.push(
              {
                id: item.id,
                text: `${item.name} ${item.surname} ${item.registration_number}`,
                subtext: item.registration_number,
                width:"100px",
                registration_number: item.registration_number
              }
            )
            }
          }

        setResources(resourcesApp);
      }
    },
    onError:({error:error})=>{
      console.log(error)
    }
  })

  React.useEffect(()=>{
    if(sessionStorage.getItem("Cantiere")){
      queryGetUserDailyHours({variables:{date:moment(sessionStorage.getItem("selectedDate")).format(),area: JSON.parse(sessionStorage.getItem("Cantiere"))}})
    }else{
      queryGetUserDailyHours({variables:{date:moment(sessionStorage.getItem("selectedDate")).format(),area: 0}})
    }
  },[resources,dateFilter])

  const QUERY_GET_ACTIVITY=gql`
  subscription GetActivity($start: timestamptz, $end: timestamptz,$areeId:[bigint!]) {
    ${tenant}_activity_activity${tenantType}(where: {start: {_gte: $start}, end: {_lte: $end},area_id:{_in:$areeId}, ${Object.keys(queryVariables).length === 0?"":JSON.stringify(queryVariables).replace("{", "").replaceAll('"',"").slice(0, -1)}}) {
      id
      area_id
      approved
      created_by_user
      notes
      operator_notes
      area_area${tenantType} {
        id
        name
      }
      start
      end
      notes
      ordinary
      work_duration
      machines_machine${tenantType} {
        id
        name
        chassis_number
      }
      data
      ordinary
      jobs_job${tenantType} {
        id
        name
      }
      users_user${tenantType} {
        id
        name
        surname
        registration_number
      }
      activity_activitypartecipants{
        id
        users_user${tenantType}{
          id
          name
          surname
        }
      }
      products_movements{
        id
        quantity
        products_product{
          name
          code
          description
        }
      }
    }
  }
  `

  let { data, loading } =  useSubscription(QUERY_GET_ACTIVITY,{variables:{...dateFilter,areeId:props.aree.length>0? props.aree : JSON.parse(sessionStorage.getItem("areeId"))}},{/** */
    onError:({error:error})=>{
      console.log(error)
    }
  })


  /**
   * Funzione che salva il dipendente selezionato nel filtro Dipendente
   */
  const handleSelectDipendente = (dipendente) => {
    if(dipendente) {
      sessionStorage.setItem('dipendente', dipendente);
      setFiltroDip(true);
    } else {
      sessionStorage.removeItem('dipendente');
      setFiltroDip(false);
    }
  }

  /**
   * Funzione che salva il macchinario selezionato nel filtro Macchinario
   */
  const handleSelectMacchinari = (mac) => {
    if(mac) {
      sessionStorage.setItem('mac', mac);
      setFiltroMac(true);
    } else {
      sessionStorage.removeItem('mac');
      setFiltroMac(false);
    }
  }

  /**
   * Funzione che salva il lavoro selezionato nel filtro Lavoro
   */
  const handleSelectLavori = (lav) => {
    if(lav) {
      sessionStorage.setItem('lav', lav);
      setFiltroLav(true);
    } else {
      sessionStorage.removeItem('lav');
      setFiltroLav(false);
    }
  }

  /**
   * Funzione che salva lo stato selezionato nel filtro Stato
   */
  const handleSelectStato = (stato) => {
    if(stato) {
      sessionStorage.setItem('stato', stato);
      setFiltroStato(true);
    } else {
      sessionStorage.removeItem('stato');
      setFiltroStato(false);
    }
  }

    /**
   * Funzione che salva lo stato selezionato nel filtro Stato
   */
     const handleSelectArea = (area) => {
      if(area) {
        sessionStorage.setItem('area', area);
        setFiltroArea(true);
      } else {
        sessionStorage.removeItem('area');
        setFiltroArea(false);
      }
    }

  return(
    <Box pad="medium" height="100%" background="#ecedf3">
      {filtroDip &&
          <Box margin='xxsmall'>
            <Text >Seleziona l'operatore: </Text>
            <Select
              options={data?[...new Set(data[`${tenant}_activity_activity${tenantType}`].sort(function(a, b) { return a[`users_user${tenantType}`].name.localeCompare(b[`users_user${tenantType}`].name)}).map((el)=>JSON.stringify(el[`users_user${tenantType}`])))].map(JSON.parse):[]}
              value={dropdownFiltriDip}
              onChange={(option ) =>setDropdownFiltriDip(option.value)}
              labelKey={(option)=>option && option.name && option.surname && option.registration_number && `${option.name} ${option.surname} ${option.registration_number}`}
            />
            { dropdownFiltriDip &&
              <Anchor onClick={() => {setDropdownFiltriDip(null);sessionStorage.removeItem("dipFilter");sessionStorage.removeItem("matricolaFilter")}} label="Rimuovi Filtro" />
            }
          </Box>
        }
        {filtroMac && 
          <Box margin='xxsmall'>
            <Text >Seleziona il macchinario: </Text>
            <Select     
              options={data?[...new Set(data[`${tenant}_activity_activity${tenantType}`].sort(function(a, b) { return a[`machines_machine${tenantType}`].name.localeCompare(b[`machines_machine${tenantType}`].name)}).map((el)=>JSON.stringify(el[`machines_machine${tenantType}`])))].map(JSON.parse):[]}
              value={dropdownFiltriMac}
              onChange={(option ) => setDropDownFiltriMac(option.value)}
              labelKey={(option)=> option && option.name && option.chassis_number && `${option.name} ${option.chassis_number}`}
            />
            { dropdownFiltriMac &&
              <Anchor onClick={() => {setDropDownFiltriMac(null)}} label="Rimuovi Filtro" />
            }
          </Box>
        }
         {filtroLav &&
          <Box margin='xxsmall'>
            <Text >Seleziona il lavoro: </Text>
            <Select
              options={data ? [...new Set(data[`${tenant}_activity_activity${tenantType}`].sort(function(a, b) { return a[`jobs_job${tenantType}`].name.localeCompare(b[`jobs_job${tenantType}`].name)}).map((el)=>JSON.stringify(el[`jobs_job${tenantType}`])))].map(JSON.parse):[]}
              value={dropdownFiltroLav}
              onChange={(option ) => setDropdownFiltroLav(option.value)}
              labelKey={(option)=>option && option.name && `${option.name}`}
            />
            { dropdownFiltroLav &&
              <Anchor onClick={() => {setDropdownFiltroLav(null)}} label="Rimuovi Filtro" />
            }
          </Box>
        }
         {filtroStato &&
          <Box margin='xxsmall'>
            <Text >Seleziona lo stato: </Text>
            <Select
              options={data ? [...new Set(data[`${tenant}_activity_activity${tenantType}`].map((el)=>el.approved))]:[]}
              value={dropdownFiltriStato}
              onChange={(option ) => setDropDownFiltriStato(option.value)}
              labelKey={(option)=>option && `${option}`}
            />
            { dropdownFiltriStato &&
              <Anchor onClick={() => {setDropDownFiltriStato(null)}} label="Rimuovi Filtro" />
            }
          </Box>
        }
      {
        loading && <LoadingLayer/>
      }
      <Box height="90vh" overflow="auto">
        <FormControlLabel
          control={
             <Switch
                checked={modifyMode}
                onChange={(e)=>{
                  window.location.reload()
                  sessionStorage.setItem("modifyMode",e.target.checked);
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
          }
          label="Abilita modifica attività"
        />
        {
         <CustomCalendar resources={resources} events={data} resourceViewMode={resourceViewMode} setDateFilter={setDateFilter} updateActivity={updateActivity} modifyMode={modifyMode}/>
        }
      </Box>
      <Fab aria-label="like" sx={{ position: 'absolute', bottom: 16,right: 150}} color="info" onClick={()=>setShowLayerUserDailyHour(true)}>
        <WatchLaterIcon />
      </Fab>
      {
        showLayerUserDailyHour && <LayerDailyHours onEscLayer={()=>setShowLayerUserDailyHour(false)} usersDailyHours={usersDailyHours} data={moment(sessionStorage.getItem("selectedDate")).format("DD-MM-YYYY")}/>
      }
      <Fab aria-label="like" sx={{ position: 'absolute', bottom: 16,right: 80}} color="white">
        <DropButton
          icon={<Filter color='green'/>}
          style={{
            fontSize: '28px',
            background: '#FFFFFF',
            color: 'white',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            borderRadius: '50%', // Rende il DropButton circolare
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
            width: 56, // Imposta la larghezza desiderata del pulsante
            height: 56,
          }}
          dropAlign={{ bottom: 'bottom', right: 'left' }}
          dropContent={
            <>
              <Box pad="small">
                <CheckBox
                  checked={filtroDip}
                  label="Filtro Dipendente"
                  onChange={(event) => handleSelectDipendente(event.target.checked)}
                />
              </Box>
              <Box pad="small">
                <CheckBox
                  checked={filtroMac}
                  label="Filtro Macchinario"
                  onChange={(event) => handleSelectMacchinari(event.target.checked)}
                />
              </Box>
              <Box pad="small">
                <CheckBox
                  checked={filtroLav}
                  label="Filtro Lavoro"
                  onChange={(event) => handleSelectLavori(event.target.checked)}
                />
              </Box>
              <Box pad="small">
                <CheckBox
                  checked={filtroStato}
                  label="Filtro Stato"
                  onChange={(event) => handleSelectStato(event.target.checked)}
                />
              </Box>
              <Box pad="small">
                <CheckBox
                  checked={filtroArea}
                  label="Filtro Area"
                  onChange={(event) => handleSelectArea(event.target.checked)}
                />
              </Box>
              </>
            }
          />
      </Fab>
      <Fab aria-label="like" sx={{ position: 'absolute', bottom: 16,right: 16}} color="success" onClick={()=>
        history.push({
            pathname: '/attivitaDettaglio',
            page: "AddAtt",
            state:{
              areeId:props.aree.length>0? props.aree : JSON.parse(sessionStorage.getItem("areeId"))
            }
          })}>
        <AddIcon />
      </Fab>
    </Box>
  )
}

export default AttivitaDet1;