import { gql } from '@apollo/client';
import { tenant, tenantTypeFirstUpperCase,tenantType } from '../../tenant';

const DEARCHIVED_AREA=gql`
mutation dearchivedArea($id:bigint!,$archive:Boolean){
    update_${tenant}_area_area${tenantType}_by_pk(pk_columns: {id: $id},_set: {archive:$archive}){
        id
        archive
  }
}
`;

const ARCHIVED_AREA_LIST=gql`
subscription areaGolfArchive{
    ${tenant}_area_area${tenantType}(where:{archive:{_eq:true}}){
        id
        name
    }
}
`

export {DEARCHIVED_AREA,ARCHIVED_AREA_LIST};