import { gql } from '@apollo/client';
import { tenant } from '../../tenant';

const ADD_CANEDGE=gql`
mutation AddCan($canNumber:String!){
    insert_${tenant}_machines_can_one(object:{can_number:$canNumber}){
      id
      can_number
    }
}
`

const UPDATE_CANEDGE=gql`
mutation UpdateCan($id:bigint!,$canNumber:String!){
  update_${tenant}_machines_can_by_pk(pk_columns:{id:$id},_set:{can_number:$canNumber}){
    id
    can_number
  }
}
`

const DELETE_CANEDGE=gql`
mutation DeleteCan($id:bigint!){
  delete_${tenant}_machines_can_by_pk(id:$id){
		id
    can_number
 }
}
`

export {ADD_CANEDGE,UPDATE_CANEDGE,DELETE_CANEDGE}