import React from "react";
import {Box, Button, Grommet, Select, Text} from "grommet"
import { Divider } from '@mui/material';
import {tenantType} from "../tenant.js";
import LoadingLayer from "../loadingLayer/LoadingLayer.jsx";

const theme = {
    global: {
        colors: {
            brand: '#397aa3',
            'accent-1': 'white',
            'focus': '#397aa3',
            "my-text-color": "",
            background: '#ecedf3',
        },
        font: {
            family: '',
            size: '18px',
            height: '20px',
        },
        menu:{
            icons:{
                color:'white',
            },
        },
    },
};

/**
 * Componente che mostra tutte le notifiche dell'utente
 */
const ReportAnnuale=(props)=>{

    const [formData,setFormData]=React.useState({});
    const [isLoading,setIsLoading]=React.useState(false);
    const [loadingText, setLoadingText] = React.useState("Sto generando il report...");
    const [timer, setTimer] = React.useState(25);
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = 2022; year <= currentYear; year++) {
        years.push(year);
    }

    React.useEffect(() => {
        let interval;
        if (isLoading && timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else if (timer === 0) {
            setLoadingText("Sto generando il report...");
            setTimer(25);
        }
        return () => clearInterval(interval);
    }, [isLoading, timer]);

    function requestReport(file){
        if(formData?.start && formData?.end){
            setIsLoading(true);
            const url = window.location.hostname;
            const parts = url.split('.'); // Dividi la stringa in base al punto
            const domain = parts[0]; // Estrai
            let formDataApp={...formData};
            formDataApp["tenant"] =  window.location.hostname!=="localhost"? domain : "arzaga"
            formDataApp["file"] = file
            console.log("FORMDATA",formDataApp);
            const accessToken = sessionStorage.getItem("accessToken");

            if (!accessToken) {
                console.error("AccessToken non trovato nel localStorage.");
            } else {

                const url = window.location.hostname!=="localhost"? window.location.origin + `/export/export-excel-activity-report`:`https://arzaga.timemate.it/export/export-excel-activity-report`

                // Impostare le opzioni per la richiesta POST
                const requestOptions = {
                    method: "POST",
                    headers: {
                        "Authorization": `Bearer ${accessToken}`,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(formDataApp)
                };

                // Effettuare la richiesta POST utilizzando fetch
                fetch(url, requestOptions)
                    .then(response => {
                        if (!response.ok) {
                            throw new Error("Errore nella richiesta POST");
                        }
                        setIsLoading(false);
                        return response.json();
                    })
                    .then(data => {
                        console.log("Risposta dal server:", data);
                        window.open(data?.url);
                        setIsLoading(false);
                    })
                    .catch(error => {
                        console.error("Si è verificato un errore:", error);
                        alert("Errore durante l'export",error?.error);
                        setIsLoading(false);
                    });
            }
        }else{
            alert("Seleziona anno inizio e anno di fine prima di generare");
        }
    }

    return(
        <Grommet theme={theme}>
            <Box height="98vh" width="100%" direction="row" >
                <Box width="100%" overflow="auto" aligns="center" pad="medium" gap={"medium"}>
                    <Select
                        placeholder="Seleziona primo anno"
                        options={years}
                        onChange={(e)=>setFormData({...formData,start:e?.target?.value})}
                    />
                    {/* Secondo select */}
                    <Select
                        placeholder="Seleziona secondo anno"
                        options={years}
                        onChange={(e)=>setFormData({...formData,end:e?.target?.value})}
                    />
                    <Box direction="row" justify="center" alignItems="center" pad="medium" gap={"medium"}>
                        <Button label={"Genera pdf"} onClick={(e)=>requestReport("pdf")} style={{width:'170px',height:'34px',fontSize:'15px',background:'#397aa3',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}/>
                        <Button label={"Genera excel"} onClick={(e)=>requestReport("excel")} style={{width:'170px',height:'34px',fontSize:'15px',background:'#397aa3',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}/>
                    </Box>
                </Box>
                {isLoading && (
                    <LoadingLayer
                        text={`${loadingText} (${timer}s)`}
                    />
                )}
            </Box>
        </Grommet>
    )
}

export default ReportAnnuale;