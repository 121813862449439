import {useMutation, useSubscription} from "@apollo/client";
import {QUERY_USERS} from "../registryComponentsGQL.js";
import {tenant, tenantType} from "../../tenant.js";
import {Box} from "grommet";
import {DataGrid} from "@mui/x-data-grid";
import {DELETE_USER} from "./dipGQL.js";
import {Button as ButtonMui} from "@mui/material";

function Personale(props){

    const [deleteUser]=useMutation(DELETE_USER);

    const { data, loading } = useSubscription(
      QUERY_USERS,{variables:{areeId:props.aree.length>0? props.aree : JSON.parse(sessionStorage.getItem("areeId"))}}
    );

     const columnsDip = [
    { field: 'name', headerName: 'Nome', width: 170 },
    { field: 'surname', headerName: 'Cognome', width: 170 },
    { field: 'fiscal_code', headerName: 'Codice Fiscale', width: 170 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'telephone_number', headerName: 'Telefono', width: 100 },
    { field: 'date_of_birth', headerName: 'Data di nascita', width: 130 },
    {field: 'registration_number',headerName: 'Matricola', width: 100 },
    {
    field: 'Modifica',
    headerName: 'Modifica',
    width: 120,
    renderCell: (cellValues) => {
      return (
             props?.modifyUser &&
        <ButtonMui
          variant="contained"
          color="primary"
          onClick={(event) => {
            props.setDipSel(cellValues.row)
            props.setModifyPersonale(true);
          }}
        >
          Modifica
        </ButtonMui>
      );},
   },
   {
    field: 'Cancella',
    headerName: 'Cancella',
    width: 120,
    renderCell: (cellValues) => {
      return (
             props?.modifyUser &&
        <ButtonMui
          variant="contained"
          color="error"
          onClick={(event) => {
            let res=window.confirm("Sei sicuro di voler eliminare l'utente?");
            if(res){
              deleteUser({variables:{ id:cellValues.row.id}})
            }
          }}
        >
          Cancella
        </ButtonMui>
      );},
   }
  ];

    let filtered_user=data && data[`${tenant}_users_user${tenantType}`].filter((el)=>el.auth_user?.is_superuser!==true)

    return (
      <Box overflow="auto"  height="100%" width="100%" alignSelf='center' style={{marginTop:'40px'}}>
        <div style={{ height: '75vh', width: '100%' }}>
          <DataGrid
            rows={filtered_user || []}
            columns={columnsDip}
          />
        </div>
      </Box>
    )
}

export default Personale;