import React from "react"
import {useLazyQuery, useMutation} from "@apollo/client";
import {DELETE_AREA_TYPE, GET_TREE as GET_AREA_TYPE_TREE} from "./areaTypeGQL.js";
import {tenant, tenantTypeFirstUpperCase} from "../../tenant.js";
import {Box} from "grommet";
import TreeViewAree from "../../treeView/TreeView.jsx";
import AddAreaType from "./AddAreaType.js";


function TypologyAree(){

    const [tree,setTree]=React.useState({});
    const [deleteMutationAreaType]=useMutation(DELETE_AREA_TYPE);

    const [ queryGetTree
    ] = useLazyQuery(GET_AREA_TYPE_TREE, {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const tree=data[`${tenant}`][`areaTypes${tenantTypeFirstUpperCase}First`][0].tree
        if(tree){
          setTree(JSON.parse(tree).trees)//JSON.parse(tree).trees)
        }
      },
      notifyOnNetworkStatusChange: true, // did the work
    });

    return (
       <Box style={{overflowX:"hidden"}} overflow="auto" width="98%">
         <TreeViewAree  form={AddAreaType} deleteMutation={deleteMutationAreaType} addElement={true} loadTree={queryGetTree} tree={tree} singleChildForNode={true} multiSelect={false}/>
       </Box>
    )
}
export default TypologyAree;