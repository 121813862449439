import { gql } from "@apollo/client";
import { tenant,tenantType } from '../tenant';


const QUERY_GET_AREE=gql`
query GetAree($id: bigint!) {
    ${tenant}_users_userarea(where: {user_id: {_eq: $id}, area_area${tenantType}: {father_id: {_is_null: true}}}) {
        area_area${tenantType}{
            name
        }
    }
}
`

const QUERY_GET_ALL_USER_AREA=gql`
query GetAllUserArea($userId:bigint!){
  ${tenant}_users_userarea(where:{user_id:{_eq:$userId}}){
		area_id
  }
}
`

export {QUERY_GET_AREE,QUERY_GET_ALL_USER_AREA};
  