import React, { useState } from "react";
import {
  Box,
  DateInput,
  Heading,
  Form,
  FormField,
  TextInput,
  Button,
  Select,
  CheckBox,
  Anchor,
  FileInput
} from "grommet";
import moment from 'moment';
import { useLazyQuery,useMutation } from '@apollo/client';
import { UPSERT_CUSTOMER} from "./customerGQL"
import TreeViewAree from "../../treeView/TreeView";
import { GET_TREE } from "../aree/areeGQL";
import { tenant, tenantTypeFirstUpperCase } from "../../tenant";
import {
  extractIdFormData,
  GOLF_ADD_ATTACHMENT,
  GOLF_GET_ATTACHMENT,
  QUERY_CONTENT_TYPE,
  sortTree
} from "../../../utilis/utils";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download.js";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import FolderIcon from "@mui/icons-material/Folder.js";
import ListItemText from "@mui/material/ListItemText";
import {Typography} from "@mui/material";

export default function AddCus(props) {

  const [formData,setFormData]=React.useState({});
  const [upsertMutationMachine]=useMutation(UPSERT_CUSTOMER);
  const [machineTypeList,setMachineTypeList]=React.useState([]);
  const [canEdgeList,setCanEdgeList]=React.useState([]);
  const [canSel,setCanSel]=React.useState(null);
  const [jobsList,setJobsList]=React.useState([]);
  const [selectedNodes,setSelectedNodes]=React.useState([]);
  const [selectedJobs,setSelectedJobs]=React.useState([]);
  const [tree,setTree]=React.useState([]);
  const [clickedNodes,setClickedNodes]=React.useState([]);
  const [contentType,setContentType]=React.useState();
  const [elencoFile,setElencoFile]=React.useState([]);
  const [addAttachment]=useMutation(GOLF_ADD_ATTACHMENT);
  const [ queryGetTree
  ] = useLazyQuery(GET_TREE, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const tree=data[`${tenant}`]["whoami"].profile.treeArea   //[`areas${tenantTypeFirstUpperCase}First`][0].trees;
      if(tree){
          setTree(JSON.parse(tree).tree);
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });
   const [queryGetContentType] = useLazyQuery(QUERY_CONTENT_TYPE, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      let contentType=data[`django_content_type`][0]
      if(contentType){
        setContentType(contentType);
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });

   const [ queryGetAttachments
    ] = useLazyQuery(GOLF_GET_ATTACHMENT, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const attachments=data[`${tenant}_attachments_attachment`]
      if(attachments){
        setElencoFile(attachments);
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });

  const onChangeTextInput=(event)=>{
    if(event.target.type==="number"){
      setFormData({...formData,[event.target.name]:parseFloat(event.target.value)})
    }else if(event.target.type==="date"){
      setFormData({...formData,[event.target.name]:moment(event.target.value).format("YYYY-MM-DD")})
    }else{
      setFormData({...formData,[event.target.name]:event.target.value})
    }
  }


  const onChangeCheckBox=(event)=>{
    setFormData({...formData,[event.target.name]:event.target.checked})
  }

  function upsertMachine(){
   
    if ( formData?.chassis_number  && formData?.name ){
      let obj=extractIdFormData({...formData,aree:JSON.stringify({areeIds:selectedNodes}),jobs:JSON.stringify({jobsIds:[]}),can:JSON.stringify({canId:[]})},true)
      delete obj?.variables?.machinetypeMachinetype
      delete obj?.variables?.machinesCanmachines
      delete obj?.variables?.machinesMachinejobs
      delete obj?.variables?._Typename
      delete obj?.variables?.machinesMachineareas
      delete obj?.variables?.cansel;
      delete obj?.variables?.job_id;
      console.log("OBJ",obj);
      upsertMutationMachine(obj)
      .then((value)=>{
        if(props.customer){
            alert("Cliente aggiornato correttamente");
            if(contentType) {
              for (let i = 0; i < elencoFile.length; i++) {
                addAttachment({
                  variables: {
                    name: elencoFile[i].name,
                    contentType: contentType.id,
                    objectId: props.customer.id,
                    attachment: JSON.stringify(elencoFile[i])
                  }
                })
                    .then((value) => console.log(value))
                    .catch((error) => console.log(error))
              }
            }
        }else{
          alert("Cliente aggiunto correttamente");
         /* if(contentType) {
              for (let i = 0; i < elencoFile.length; i++) {
                addAttachment({
                  variables: {
                    name: elencoFile[i].name,
                    contentType: contentType.id,
                    objectId: value["data"][tenant]["upsertMachine"+tenantTypeFirstUpperCase],
                    attachment: JSON.stringify(elencoFile[i])
                  }
                })
                    .then((value) => console.log(value))
                    .catch((error) => console.log(error))
              }
            }*/
        }
        onEscLayer();
      })
          .catch((value)=>{
        console.error(value);
        alert("Errore durante l'operazione");
      })
    }else{
      alert("Compila i campi obbligatori")
    }
  }

  React.useEffect(()=>{
    if(props.customer){
      let areaNodes=props.customer?.machines_machineareas?.map((machineArea)=>machineArea.area_areagolf.id);
      setSelectedNodes(areaNodes);
      setFormData({...props.customer})
      queryGetContentType({variables:{model:"machinegolf"}});
    }
  },[tree])

  React.useEffect(()=>{
     if(contentType && props.customer?.id){
         queryGetAttachments({variables:{contentTypeId:contentType?.id,objectId:props.customer.id}})
      }
  },[contentType])

   function onEscLayer(){
    if(props.onEscLayer){
        props.onEscLayer();
    }
    setFormData({});
  }

  function downloadFile(allegatoSel){
        window.open("/media/"+allegatoSel.attachment)
  }

  return (
    <Box round pad="medium" direction="column" background="white" align="center" overflow="scroll">
      <Heading level="2" margin="none" size="small">
        <Box direction="row" gap="large">
          {!props.customer?"Inserisci il macchinario":"Aggiorna il macchinario"}
         {/* <Button label="Scarica modello" href={"./modelliCsv/Modello_Macchinari.zip"} style={{width:'200px',fontSize:'20px',background:'#397aa3',color:'white',fontWeight:'bold',textTransform: 'uppercase',textAlign:"center"}}></Button> */}
        </Box>
      </Heading>
      <Box fill gap="medium" pad={{ vertical: "small" }} >
        <Form>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <FormField required label="Nome*">
              <TextInput
                name="name"
                value={ formData?.name}
                onChange={e => onChangeTextInput(e)}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Codice Cliente*">
              <TextInput
                name="chassis_number"
                placeholder="es. 134528736"
                value={ formData?.chassis_number}
                onChange={e => onChangeTextInput(e)}
              />
            </FormField>
          </Box>
        </Box>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <FormField required label="Note">
              <TextInput
                name="notes"
                placeholder="es. Note Aggiuntive"
                value={formData?.notes}
                onChange={e => onChangeTextInput(e)}
              />
            </FormField>
          </Box>
        </Box>
        <Box pad="xsmall">
            <FormField required label="Codice gestionale esterno">
              <TextInput
                name="external_erp_code"
                value={formData && formData.external_erp_code}
                onChange={e => onChangeTextInput(e)}
              />
            </FormField>
        </Box>
        <Box style={{overflowX:"hidden"}} overflow="auto" >
          <TreeViewAree clickedNodes={clickedNodes} setClickedNodes={setClickedNodes} checkAssignedAree={true} selectedNodes={selectedNodes} setSelectedNodes={setSelectedNodes} addElement={false} loadTree={queryGetTree} tree={tree} singleChildForNode={false} multiSelect={true}/>
        </Box>
          {
            props.customer &&
                <FileInput
              multiple
              name="file"
              onChange={event => {
                const fileList = event.target.files;
                const file=fileList[0];
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = function () {
                    const result=reader.result;
                   let fileObj={
                        name: file.name,
                        type:file.type,
                        content:result,
                        size:file.size,
                        modified: file.lastModifiedDate,
                    }
                    let elencoFileApp=[...elencoFile]
                    elencoFileApp.push(fileObj);
                  setElencoFile(elencoFileApp);
                }
              }
              }
            />
          }
          {
                      elencoFile.length>0?

                        <List dense={false}>
                        {elencoFile.map((allegato)=>
                          <ListItem
                            secondaryAction={
                              <Box gap="small" direction="row">
                                <IconButton edge="end" aria-label="delete" onClick={()=>downloadFile(allegato)}>
                                  <DownloadIcon />
                                </IconButton>
                            </Box>
                            }
                          >
                            <ListItemAvatar>
                              <Avatar>
                                <FolderIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={<Typography variant="h6" style={ allegato.name.length>=50? { fontSize:"10px"}:{ fontSize:"15px"}}>{allegato.name}</Typography>}
                            />
                          </ListItem>
                        )}
                      </List>

                    :
                    null
                  }
        </Form>
        <Box direction="row" gap="medium" alignSelf="end">
          <Button label={!props.customer?"Aggiungi":"Aggiorna"} onClick={upsertMachine} style={{width:'138px',height:'34px',fontSize:'15px',background:'#397aa3',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}/>
        </Box>
      </Box>
    </Box>
  )
}