import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box,Button ,Text } from 'grommet';
import { Button as ButtonMui } from "@mui/material";
import {
    DELETE_MAINTENANCE_SCHEDULE,
    UPSERT_MAINTENANCE_SCHEDULE
} from './maintenanceGQL';
import { useMutation } from '@apollo/client';

function TabMaintenance(props){
  
    const [rows,setRows]=React.useState([]);
    let count=-1;
    const [deleteMaintenanceSchedule]=useMutation(DELETE_MAINTENANCE_SCHEDULE);
    const [upsertMaintenanceSchedule] = useMutation(UPSERT_MAINTENANCE_SCHEDULE);

    const columns = [
        { field: 'interval', headerName: 'Intervallo', width: 130, editable: true },
        {
            field: 'Modifica',
            headerName: 'Cancella',
            width: 400,
            renderCell: (cellValues) => {
                if(cellValues.row.id){
                     return (
                        <ButtonMui
                            variant="contained"
                            color="error"
                            onClick={(event) => {
                                if (cellValues.row.id) {
                                    let result = window.confirm("Sei sicuro di voler eliminare l'elemento selezionato?")
                                    if (result) {
                                        deleteMaintenanceSchedule({variables: {id: cellValues.row.id}})
                                            .then((value)=>alert("Intervallo eliminato correttamente"))
                                            .catch((error)=>{alert("Errore nella cancellazione");console.error(error)})
                                        props.queryMaintenanceSchedule({variables: {idMachine: props.selectedMachine?.id,idInterventionType:props.interventiontType?.id}})
                                    }
                                }
                            }}
                        >
                            Cancella
                        </ButtonMui>
                        );
                    }
                }
            }
      ];

    React.useEffect(()=>{
      setRows(props.rows)
    },[props.rows])

    function addRows(){
      const rowsApp=[...rows];
      rowsApp.push({id:count,machineId: props.selectedMachine?.id,typeInterventionId:props.interventiontType?.id,interval:0}); //Devo aggiungere id per far funzionare correttamente la datagrid, questo id non verrà passato al backend
        count=count-1;
      setRows(rowsApp);
    }

    return (
        <Box gap="small" style={{ height: '100vh', width:'70vw'}} pad="small">
            <Box direction="row" gap="small" align="center">
              {
                props.nameInterventiontType && props.nameInterventiontType.code &&  <Text weight="bold" size="medium">{props.nameInterventiontType.code.toUpperCase()}</Text>
              }
              <Button round="medium" margin="small" label= {"Aggiungi"} onClick={addRows} style={{width:'138px',height:'34px',fontSize:'15px',background:'#397aa3',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}/>
            </Box>
            <DataGrid
                rows={rows}
                columns={columns}
                experimentalFeatures={{ newEditingApi: true }}
                editMode={"cell"}
                showCellRightBorder={true}
                onCellEditStop={(params, event) => {
                  if(!isNaN(parseInt(event.target.value))){
                      const id=params.row.id;
                      let obj={machineId: props.selectedMachine?.id,typeInterventionId:props.interventiontType?.id,interval:parseInt(event.target.value),sequence:rows.length+1,iterations:-1};
                      delete obj.__typename;
                      if(id && id>=0){
                          upsertMaintenanceSchedule({variables:obj});
                      }else{
                          delete obj.id;
                          delete obj.typeIntervention
                          upsertMaintenanceSchedule({variables:obj});
                        }
                    props.queryMaintenanceSchedule({variables:{idMachine: props.selectedMachine?.id,idInterventionType:props.interventiontType?.id}})
                  }
                }}
            />
        </Box>
    )
}

export default TabMaintenance;
   