import React, { useState, useEffect, useRef } from "react";
import { Box, Menu, Grid, Grommet, Text, Card, CardHeader, CardBody, Image, Select, CardFooter, TableCell, Clock, TextInput, Button, Calendar, Anchor, CheckBoxGroup, WorldMap, DataChart, DataTable, Meter, Heading, Collapsible, Layer, ResponsiveContext, DropButton, Notification as NotificationApp } from 'grommet';
import { FormClose, Notification, Home, CircleInformation, Add, Favorite, ShareOption, Edit, LinkPrevious, UserWorker, MapLocation, Analytics, Task, Configure, Location, Trash } from "grommet-icons";
import MapBuche from "./MapBuche";
import  { Redirect } from 'react-router-dom';
import { ScrollMenu, } from "react-horizontal-scrolling-menu";
import { useLazyQuery,useMutation } from '@apollo/client';
import {extractIdFormData, extractRootTreeFromWhoami} from "../../utilis/utils"
import { GEO_REF_TENANT } from "./geoRefGQL";
import { tenant,tenantType ,tenantTypeFirstUpperCase} from "../tenant";
import { GET_HOME ,TRACK_AREA} from "./geoRefGQL";
import ArchitectureIcon from '@mui/icons-material/Architecture';
import {Button as ButtonMUI} from '@mui/material';


const theme = {
  global: {
    colors: {
      brand: '#397aa3',
      'accent-1': 'white',
      'focus': '#397aa3',
      "my-text-color": "",
      background: '#ecedf3',
    },
    font: {
      family: '',
      size: '18px',
      height: '20px',
    },
    menu:{
      icons:{
        color:'white',
        },
    },
  },
};


const App = (props) => {
  const [buche, setBuche] = useState([]);
  const [savePolygon]=useMutation(TRACK_AREA)
  const [queryGeoRefTenant] = useLazyQuery(GEO_REF_TENANT, {
  fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setBuche(data[`${tenant}_area_area${tenantType}`]);
    },
    notifyOnNetworkStatusChange: true, // did the work
  });


  /**
   * Funzione che cancella l'intero tracciamento
   */
  function deleteBuca(id,name,type) {
    let res=window.confirm("Sei sicuro di voler eliminare la traccia?")
    if(res){
      savePolygon({variables:{id:id,coordinates:null,color:""}})
      .then((value)=>{
        queryGeoRefTenant({variables:{areeId:props.aree.length>0? props.aree : JSON.parse(sessionStorage.getItem("areeId"))}})
        alert("Traccia eliminata correttamete")
      })
      .catch((error)=>{
        alert("Errore durante l'operazione")
      })
    }
  }

  useEffect(() => {
     queryGeoRefTenant({variables:{areeId:props.aree.length>0? props.aree : JSON.parse(sessionStorage.getItem("areeId"))}})
  }, [props.aree]);

  /**
   * IMPORTANTE: il componente si comporta in modo diverso in base all'utenza di login
   * admin generale: Mostra le card contenti tutti i tracciati
   * admin cantiere/zona: Mostra una mappa con i tracciati che rigurdano solo il cantiere/zona dell'utente loggato
   */
  return (
    <Grommet theme={theme} themeMode="dark" className="font-link">
      <ResponsiveContext.Consumer>
        {size => (
        <Box fill pad="small">
            <Box direction="row" >
            <Box width="100%" height="94vh" direction='row' flex="false" overflow={{ horizontal: 'hidden' }}>
                <Box flex >
                  <Box height="550px" gap="medium" pad="medium">
                    <ScrollMenu>
                    {buche.map(buca => {
                      return(
                            <Box width="400px" >
                            <Card margin="small" background="light-1">
                              <CardHeader pad="medium">
                                <Text style={{fontWeight:'bold'}}>{buca.name}</Text>
                                <Button style={{marginRight: '0px'}} label="" size="medium" icon={<Trash color="brand"/>} onClick={() => deleteBuca(buca.id,buca.name,buca.type_id)}></Button>
                                <Button style={{marginRight: '0px'}} label="" size="medium" icon={<Edit color="brand"/>} onClick={() => window.location.href = window.location.origin + "/modificaTraccia/" + buca.id}></Button>
                              </CardHeader>
                              <CardBody pad="small">
                                {
                                  buca.TotArea && <><Text>Area Buca (mq): {Math.round(buca.TotArea)}</Text><br/><br/></>
                                }
                                Area: {buca.name} <br/><br/>
                                <ButtonMUI variant="outlined" startIcon={<ArchitectureIcon />}>
                                  Mostra Aree
                                </ButtonMUI>
                              </CardBody>
                              <CardFooter height="medium" pad={{horizontal: "small"}} background="light-2" style={{position: 'relative'}}>  
                                <MapBuche admin={true} color={buca?.color ||  "#000000"} coords={buca && buca.coordinates && buca.coordinates.coordinates.map((polygon)=>polygon)} home={buca && buca.home_coordinates && buca.home_coordinates.coordinates} name={buca.name}/>
                              </CardFooter>
                            </Card>
                            </Box>
                        )})}
                        </ScrollMenu>
                        <Button style={{width:'217px',fontSize:'15px',background:"#397aa3",color:'white',fontWeight:'bold',textTransform: 'uppercase',borderStyle: 'solid',borderColor:'#397aa3',borderWidth:"3px", borderRadius:"10px"}} onClick={()=>window.location.href = window.location.origin + "/tracciaAdL"}>
                          <Box pad="small" direction="row" align="center" gap="medium">
                              <Location color="white"/>
                              <Text size="small" color="white">Traccia ADL</Text>
                          </Box>
                        </Button>
                      </Box>
                </Box>
                </Box>
            </Box>
        </Box>
        )}
      </ResponsiveContext.Consumer>
    </Grommet>
  );
}

export default App;
