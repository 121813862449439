import React, { useState } from "react";
import { Box, Heading, Form, FormField, TextInput, Button,Layer,Select } from "grommet";
import { FormClose } from "grommet-icons";
import { useLazyQuery,useMutation } from '@apollo/client';
import { UPDATE_AREA_TYPE,ADD_AREA,GET_AREA_TYPE } from "./areaTypeGQL";
import { QUERY_GET_AREE_TYPE } from "../aree/areeGQL";
import { tenant,tenantType } from "../../tenant";
import { extractIdFormData } from "../../../utilis/utils";

export default function AddAreaType(props) {

  const [formData,setFormData]=React.useState({}); //impostati di default
  const [areaTypeList,setAreaTypeList]=React.useState([]);
  const [addAreaTypeMutation]=useMutation(ADD_AREA);
  const [updateAreaTypeMutation]=useMutation(UPDATE_AREA_TYPE);
  

  const [  queryGetAreaType //Per modifica
  ] = useLazyQuery(GET_AREA_TYPE, {
    variables: {
      id: props.nodeModify?.id || props.father?.id
    },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {  
      const res=data[`${tenant}_area_areatype${tenantType}_by_pk`];
      if(res){  
        let formDataApp;
        if(props.nodeModify){
            formDataApp={...res,father_id:res[`areaAreatype${tenantType}ByFatherId`]}
            delete formDataApp[`areaAreatype${tenantType}ByFatherId`]
            delete formDataApp.__typename
        }else{
            formDataApp={sequence:res.sequence+1,father_id:res[`areaAreatype${tenantType}ByFatherId`]}
        }
        setFormData(formDataApp);      
      }
      
    },
    notifyOnNetworkStatusChange: true, // did the work
  });
  const [ queryAreeType
    ]  = useLazyQuery(QUERY_GET_AREE_TYPE, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if(data[`${tenant}_area_areatype${tenantType}`]){
        setAreaTypeList(data[`${tenant}_area_areatype${tenantType}`])
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });

  React.useEffect(()=>{
    queryAreeType();
  },[])

  React.useEffect(()=>{
    
    queryGetAreaType();
  
  },[areaTypeList])

  const onChangeTextInput=(event)=>{
    setFormData({...formData,[event.target.name]:event.target.value})
  }

  const onChangeSelect=(event)=>{
    setFormData({...formData,[event.target.name]:event.value})
  }

  function addArea(){
    addAreaTypeMutation(extractIdFormData(formData,false)).then((value)=>{
      if(props.loadTree){
        props.loadTree();
         alert("Area creata con successo");
      }
    })
  }

  function updateArea(){
    updateAreaTypeMutation(extractIdFormData(formData,false)).then((value)=>{
      if(props.loadTree){
        props.loadTree();
         alert("Area aggiornata con successo");
      }
    })
  }

  function onEscLayer(){
    props.onEscLayer();
    setFormData({});
  }

  return (
    <Layer full={window.screen.width<1100} onClickOutside={onEscLayer} onEsc={onEscLayer}>
      <Box height="100%" round="medium" pad="15px" background="#ecedf3">
        <Button  alignSelf="end" icon={<FormClose color="grey" size="35px" onClick={onEscLayer}/>}></Button>
          <Box round pad="medium" direction="column" background="white" align="center" overflow="auto">
            <Heading level="2" margin="none" size="small" >
              <Box direction="row" gap="small">
                {!props.nodeModify?"Inserisci L'Anagrafica dell'area":"Aggiorna anagrafica area"}
                {/*<Button label="Scarica modello" href={"./modelliCsv/Modello_Aree.csv"} style={{width:'200px',fontSize:'20px',background:'#0C6B13',color:'white',fontWeight:'bold',textTransform: 'uppercase',textAlign:"center"}}></Button> */}
              </Box>
            </Heading>
            <Box fill gap="medium" pad={{ vertical: "small" }} align="center">
            <Form>
              <Box direction="row" align="center">
                <Box pad="xsmall">
                  <FormField required label="Nome Tipo">
                    <TextInput
                      name="type_name"
                      value={ formData?.type_name || ""}
                      placeholder="es. Cantiere"
                      onChange={e => onChangeTextInput(e)}
                    />
                  </FormField>
                </Box>
                <Box pad="xsmall">
                  <FormField required label="Numero livello">
                    <TextInput
                      type="number"
                      name="sequence"
                      value={ formData?.sequence +1 || ""}
                      placeholder="es. 0"
                      onChange={e => onChangeTextInput(e)}
                    />
                  </FormField>
                </Box>
                {
                 
                  <Box pad="xsmall">
                    <FormField required label="Padre">
                      <Select
                        name="father_id"
                        value={ formData?.father_id?.id || ""}
                        options={areaTypeList}
                        labelKey={(option)=>option?.type_name}
                        onChange={e => onChangeSelect(e)}
                      />
                    </FormField>
                  </Box>
                }
              
              </Box>
            </Form>
            <Box direction="row" gap="medium" alignSelf="end">
                <Button label={!props.nodeModify?"Aggiungi":"Aggiorna"} onClick={!props.nodeModify?addArea:updateArea} style={{width:'138px',height:'34px',fontSize:'15px',background:'#0C6B13',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}/>
            </Box>
          </Box>
        </Box>
      </Box>
    </Layer>
  )
}
