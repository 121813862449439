import { gql } from '@apollo/client';
import { tenant,tenantType,tenantTypeFirstUpperCase } from '../../tenant';

const GET_TREE=gql`
query GetAreaTypeTree {
  ${tenant}{
    areaTypes${tenantTypeFirstUpperCase}First{
      tree
    }
  }
}
`

const GET_AREA_TYPE=gql`
query getAreaType($id:bigint!) {
  ${tenant}_area_areatype${tenantType}_by_pk(id: $id){
    id
    father_id
    sequence
    child_id
    type_name
    areaAreatypegolfByFatherId{
      id
      child_id
      type_name
    }
  }
}
`

const ADD_AREA=gql`
mutation AddAreaType($child_id:bigint, $father_id:bigint,$sequence:Int,$type_name:String) {
  insert_${tenant}_area_areatype${tenantType}(objects: {child_id: $child_id, father_id: $father_id, sequence: $sequence, type_name: $type_name}){
    returning{
      id
      child_id
      father_id
      sequence
      type_name
    }
  }
}
`

const DELETE_AREA_TYPE=gql`
mutation deleteAreaType($id: ID!) {
  ${tenant}{
    deleteAreaType${tenantTypeFirstUpperCase}(id: $id) {
      message
      ok
    }
  }
}
`

const UPDATE_AREA_TYPE=gql`
mutation UpdateAreaType($id:bigint!,$child_id:bigint, $father_id:bigint,$sequence:Int,$type_name:String) {
  update_${tenant}_area_areatype${tenantType}_by_pk(pk_columns: {id: $id}, _set: {type_name: $type_name, sequence: $sequence, father_id: $father_id, child_id: $child_id}){
    id
    child_id
    father_id
    sequence
    type_name
  }
}
`


export {GET_TREE,DELETE_AREA_TYPE,ADD_AREA,UPDATE_AREA_TYPE,GET_AREA_TYPE}
