import { gql } from '@apollo/client';
import { tenant,tenantType,tenantTypeFirstUpperCase } from '../tenant';

const QUERY_GET_CLIENTS=gql`query getJobsFromAree{
    ${tenant}_machines_machine${tenantType}(order_by: {name: asc}, where: {is_machine: {_eq: false}}) {
      id
      name
    }
  }`

const ADD_AUTOMATIC_ACTIVITY = gql`
  mutation AddAutomaticActivity(
    $notes: String,
    $start: timestamptz!,
    $end: timestamptz,
    $approved: String,
    $job: bigint!,
    $area: bigint!,
    $machine: bigint!,
    $user: bigint!,
    $created_by_user: Boolean,
    $data: date
  ) {
    insert_${tenant}_activity_activity${tenantType}_one(
      object: {
        notes: $notes,
        start: $start,
        end: $end,
        approved: $approved,
        job_id: $job,
        area_id: $area,
        created_by_user: $created_by_user,
        machine_id: $machine,
        user_id: $user,
        data: $data
      }
    ) {
      id
    }
  }
`;


const UPDATE_AUTOMATIC_ACTIVITY = gql`
  mutation UpdateAutomaticActivity(
    $id_activity: bigint!,
    $approved: String,
    $end: timestamptz,
    $start: timestamptz
  ) {
    update_${tenant}_activity_activity${tenantType}(
      where: { id: { _eq: $id_activity } },
      _set: { approved: $approved, end: $end, start: $start }
    ) {
      affected_rows
    }
  }
`;

const GET_ACTIVITY_IN_PROGRESS_USER = gql`query GET_ACTIVITY_IN_PROGRESS($user_id: bigint!) {
    ${tenant}_activity_activity${tenantType}(where: {user_id: {_eq: $user_id}, approved: {_eq: "PARTITA"}}) {
      approved
      data
      end
      id
      jobs_job${tenantType} {
        id
        name
      }
      machines_machine${tenantType} {
        id
        name
      }
      start
      area_id
      notes
    }
  }`

  const GET_ACTIVITY_PAUSED_USER = gql`query GET_ACTIVITY_PAUSED($user_id: bigint!) {
    ${tenant}_activity_activity${tenantType}(where: {user_id: {_eq: $user_id}, approved: {_eq: "PAUSA"}}) {
      approved
      data
      end
      id
      jobs_job${tenantType}{
        id
        name
      }
      machines_machine${tenantType} {
        id
        name
      }
      start
      area_id
      notes
    }
  }`

export {QUERY_GET_CLIENTS,ADD_AUTOMATIC_ACTIVITY,UPDATE_AUTOMATIC_ACTIVITY,GET_ACTIVITY_PAUSED_USER,GET_ACTIVITY_IN_PROGRESS_USER};