import { gql } from '@apollo/client';
import { tenant,tenantType,tenantTypeFirstUpperCase} from "../tenant.js";

const GET_PRODUCTS=gql`
query GetProducts($area: [ID!],$page:Int!,$pageSize:Int!) {
  ${tenant} {
     products(area:$area,page:$page,pageSize:$pageSize){
      id
      name
      description
      code
      quantity
      currentlyInStock
      area{
        id
      }
 	 }
   }
}
`

const GET_PRODUCTS_BY_NAME=gql`
query GetProducts($area: [ID!], $name: String) @cached {
  ${tenant} {
    productsByName(area: $area, name: $name) {
      id
      name
      code
      description
      quantity
      currentlyInStock
    }
  }
}
`

const GET_PRODUCTS_BY_CODE=gql`
query GetProducts($area: [ID!], $code: String) @cached {
  ${tenant} {
    productsByCode(area: $area, code: $code) {
      id
      name
      code
      description
      quantity
      currentlyInStock
    }
  }
}
`

const GET_PRODUCTS_BY_DESCRIPTION=gql`
query GetProducts($area: [ID!], $desc: String) @cached {
  ${tenant} {
    productsByDescription(area: $area, desc: $desc) {
      id
      name
      code
      description
      quantity
      currentlyInStock
    }
  }
}
`

const UPSERT_PRODUCT=gql`
mutation insertToDo($id:ID,$name:String!,$code:String,$description:String,$area_id:ID!,$currentlyInStock:Boolean){
	${tenant}{
        upsertProduct(input:{id:$id,name:$name,code:$code,description:$description,area:$area_id,currentlyInStock:$currentlyInStock}){
          product{
            id
            name
            code
            description
            currentlyInStock
          }
    }
  }
}
`

const DELETE_PRODUCT=gql`
mutation DeleteProduct($id:ID!){
  ${tenant}{
    deleteProduct(id:$id){
      ok
      message
    }
  }
}   
`

const UPSERT_MOVEMENT=gql`
mutation upsertMovement($id:ID,$data:DateTime!,$product:ID!,$quantity:Float!,$user:ID!){
	${tenant}{
    upsertMovement(input:{id:$id,data:$data,product:$product,quantity:$quantity,user:$user}){
      movement{
        id
        data
      }
    }   
  }
}
`

const GET_MOVEMENTS=gql`
query getMovements($productId:bigint!){
  ${tenant}_products_movement(where:{product_id:{_eq:$productId}}, order_by: {data: desc}){
    id
    products_product{
      id
      name
      code
    }
   	users_user${tenantType}{
      id
      name
      surname
    }
    data
    quantity
    area_area${tenantType}{
      id
      name
    }
  }
}
`

const DELETE_MOVEMENT=gql`
mutation delete_movement($movementId:ID!){
  ${tenant}{
    deleteMovement(id:$movementId){
      ok
      message
    }
  }
}
`

const QUERY_MACHINE_AREAS=gql`
query machineArea($areas:[bigint!]){
    ${tenant}_machines_machinearea(where:{area_id:{_in:$areas}},distinct_on:machine_id){
        machines_machinegolf{
            id
            name
            chassis_number
        }
    }
}
`

const QUERY_MACHINE_PRODUCTS=gql`
query machineProducts($machineId: bigint!) {
  ${tenant}_products_machineproduct(where: {machines_machinegolf: {id: {_eq: $machineId}}}, order_by: {products_product: {name: asc}}) {
    id
    data
    quantity
    users_user${tenantType}{
      id
      name
      surname
      fiscal_code
    }
    products_product {
       id
      name
      code
      description
      quantity
    }
  }
}
`

const DELETE_MACHINE_PRODUCT=gql`
mutation deleteMachineProducts($id:ID!){
  tarter{
    deleteMachineProduct(id:$id){
      ok
      message
    }
  }
}
`

export {GET_PRODUCTS,UPSERT_PRODUCT,DELETE_PRODUCT,UPSERT_MOVEMENT,GET_MOVEMENTS,DELETE_MOVEMENT,GET_PRODUCTS_BY_NAME,GET_PRODUCTS_BY_CODE,GET_PRODUCTS_BY_DESCRIPTION,QUERY_MACHINE_AREAS,QUERY_MACHINE_PRODUCTS,DELETE_MACHINE_PRODUCT};