import {useMutation, useSubscription} from "@apollo/client";
import {QUERY_JOBS} from "../registryComponentsGQL.js";
import {Box} from "grommet";
import {DataGrid} from "@mui/x-data-grid";
import {tenant, tenantType} from "../../tenant.js";
import {Button as ButtonMui} from "@mui/material";
import {DELETE_JOB} from "./jobsGQL.js";

function Jobs(props){

     const [deleteJob]=useMutation(DELETE_JOB);
    const { data, loading } = useSubscription(
      QUERY_JOBS,{variables:{areeId:props.aree.length>0? props.aree : JSON.parse(sessionStorage.getItem("areeId"))}}
    );

    const columnsLav = [
        { field: 'name', headerName: 'Nome', width: 300 },
        {
        field: 'Modifica',
        headerName: 'Modifica',
        width: 400,
        renderCell: (cellValues) => {
          return (
                props?.modifyRegistry &&
            <Box direction="row" gap="small">
                <ButtonMui
                    variant="contained"
                    color="primary"
                    onClick={(event) => {
                        console.log("value row",cellValues.row)
                        props.setLavSel(cellValues.row);
                        props.setModifyLavori(true);
                    }}
                  >
                    Modifica
                  </ButtonMui>
              <ButtonMui
                variant="contained"
                color="error"
                onClick={(event) => {
                  let res=window.confirm("Sei sicuro di voler eliminare il lavoro?");
                  if(res){
                    deleteJob({variables:{ id:cellValues.row.id}})
                  }
                }}
              >
                Cancella
              </ButtonMui>
            </Box>
          );},
        }
    ];


    return (
      <Box overflow="auto"  height="100%" width="100%" alignSelf='center' style={{marginTop:'40px'}}>
        <div style={{ height: '75vh', width: '100%' }}>
          <DataGrid
            rows={data && data[`${tenant}_jobs_job${tenantType}`] || []}
            columns={columnsLav}
          />
        </div>
      </Box>
    )
}

export default Jobs;