
import { gql } from '@apollo/client';
import { tenant,tenantType,tenantTypeFirstUpperCase } from '../../tenant';

const QUERY_GET_CLIENT_PROFILE=gql`
query getProfileFromId($machine_id: bigint!) {
    ${tenant}_machines_machineprofile(where: {machine_id: {_eq: $machine_id}}) {
      id
      machine_id
      profile
      status
    }
  }
`

const UPDATE_PROFILE=gql`
mutation MyMutation($profile_id: bigint!, $profile: jsonb!, $status: String) {
  update_${tenant}_machines_machineprofile_by_pk(pk_columns: {id: $profile_id}, _set: {profile: $profile, status: $status}) {
    id
  }
}

  `

export {QUERY_GET_CLIENT_PROFILE, UPDATE_PROFILE};