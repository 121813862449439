import {useMutation, useSubscription} from "@apollo/client";
import {QUERY_JOBS} from "../registryComponentsGQL.js";
import {Box} from "grommet";
import {DataGrid} from "@mui/x-data-grid";
import {tenant, tenantType} from "../../tenant.js";
import {Button as ButtonMui} from "@mui/material";
import {DEARCHIVED_AREA,ARCHIVED_AREA_LIST} from "./archivedAreaGQL.js";

function ArchivedAreaTable(props){

    const [dearchiveArea]=useMutation(DEARCHIVED_AREA);
    const { data, loading } = useSubscription(
      ARCHIVED_AREA_LIST
    );

    const columnsArchiveARea = [
        { field: 'name', headerName: 'Nome', width: 300 },
        {
        field: 'Modifica',
        headerName: 'Modifica',
        width: 400,
        renderCell: (cellValues) => {
          return (
                <ButtonMui
                    variant="contained"
                    color="success"
                    onClick={(event) => {
                        let res=window.confirm("Sei sicuro di voler rendere nuovamente disponibile l'area?");
                        if(res){
                            dearchiveArea({variables:{id:cellValues?.row?.id,archive:false}});
                        }
                    }}
                  >
                    Rendi nuovamente disponibile
                </ButtonMui>
          );},
        }
    ];


    return (
      <Box overflow="auto"  height="100%" width="100%" alignSelf='center' style={{marginTop:'40px'}}>
        <div style={{ height: '75vh', width: '100%' }}>
          <DataGrid
            rows={data && data[`${tenant}_area_area${tenantType}`] || []}
            columns={columnsArchiveARea}
          />
        </div>
      </Box>
    )
}

export default ArchivedAreaTable;