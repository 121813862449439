import { gql } from '@apollo/client';
import {tenant,tenantType,tenantTypeFirstUpperCase} from "../tenant.js";

const QUERY_MAC=gql`
query MacList($areeId:[bigint!]) {
  ${tenant}_machines_machine${tenantType}(where: {machines_canmachines: {can_id: {_is_null: false}}, machines_machineareas: {area_id: {_in: $areeId}}}, order_by: {name: asc}){
    id
    name
    chassis_number
    machines_canmachines{
      id
       machines_can{
        can_number
      }
    }
  }
}
`

const GET_DASHBOARD_DATA=gql`
query machineTimeSerie($canId:String!,$start:DateTime!,$end:DateTime!){
 	${tenant}{
    machineTimeSeries(canId:$canId,start:$start,end:$end)
  }
}
`

export {QUERY_MAC,GET_DASHBOARD_DATA};