import React, { useState } from "react";
import { Box, Button, Text } from "grommet";
import { FormClose } from "grommet-icons";
import {useLazyQuery,useMutation} from "@apollo/client";
import {DELETE_NOTIFICATION, USER_NOTIFICATION} from "./notificationGQL.js";

/**
 * Componente che implementa la lista di notificheà
 * Verrà poi inserita in Notifiche.js
 */
const NotificationApp = (props) => {

  const [notifications,setNotifications] = useState(props.notifications);
  const [deleteNotification]=useMutation(DELETE_NOTIFICATION);

  React.useEffect(()=>{
    setNotifications(props.notifications);
  },[props.notifications])

  /**
   * Funzione che cancella una notifica
   */
  function handleNotification(notificaid) {
    console.log("NOTIFICA ID",notificaid);
    let res=window.confirm("Sei sicuro di voler eliminare la notifica?")
    if(res){
       deleteNotification({variables:{id:notificaid,read:true}})
           .then((value)=>alert("Notifica eliminata correttamente"))
           .catch((error)=>{alert("Errore durante la cancellazione");console.error(error)});
       props.getNotification({variables:{id:sessionStorage.getItem("userId")}});
    }
  }



  return (
    <Box width="90%" align="center">
    {
    notifications.map(notifica =>
    <Box
      width="70%"
      round
      pad="small"
      margin="1%"
      direction="column"
      background="src/components#FAFAFABF"
      style={{display: 'inline-table',borderStyle: 'solid',borderColor:'#707070',borderWidth:"1px"}}
    >
      <Box  direction="row" justify="between" pad={{ vertical: "small" }} style={{display: 'table!important'}}>
        <Box  direction="column" gap="small" pad={{ vertical: "small" }}>
          {
            notifica.data?
            <Text
            color="#000000"
            size="large"
            weight="bold"
            margin={{ horizontal: "small" }}
          >
            {notifica.data+" - "+notifica.title}
          </Text>
          :
          <Text
          color="#000000"
          size="large"
          weight="bold"
          margin={{ horizontal: "small" }}
        >
          {notifica.title}  
        </Text>
          }   
          <Text size="medium" color="#000000" margin={{ horizontal: "small" }}>
            {notifica.body}
          </Text>
        </Box>
        <Box>
          <Button plain icon={<FormClose color="#000000" />} onClick={() =>handleNotification(notifica.id)} />
        </Box>
      </Box>
    </Box>
    )
    }
  </Box>
  );
}

export default NotificationApp;