import React from "react";
import {useLazyQuery, useMutation} from "@apollo/client";
import {DELETE_AREA, GET_TREE} from "./areeGQL.js";
import {tenant} from "../../tenant.js";
import {Box} from "grommet";
import TreeViewAree from "../../treeView/TreeView.jsx";
import AddArea from "./AddArea.js";

function Aree(props){

    const [tree,setTree]=React.useState({});

    const [ queryGetTree
    ] = useLazyQuery(GET_TREE, {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const tree=data[`${tenant}`]["whoami"].profile.treeArea;
        if(tree){
          setTree(JSON.parse(tree).tree);
        }
      },
      notifyOnNetworkStatusChange: true, // did the work
    });
    const [deleteMutation]=useMutation(DELETE_AREA);

    return (
        <Box style={{overflowX:"hidden"}} overflow="auto" width="98%">
          <TreeViewAree checkAssignedAree={true} form={AddArea} deleteMutation={deleteMutation} addElement={props?.addElement || true} loadTree={queryGetTree} tree={tree} singleChildForNode={false} multiSelect={false}/>
        </Box>
    )
}

export default Aree;