
import Box from '@material-ui/core/Box';
import Autocomplete from '@mui/material/Autocomplete'
import { Text, TextArea } from 'grommet';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {useMutation, useSubscription, gql, useLazyQuery} from '@apollo/client';
import React from "react";
import {tenant, tenantType} from "../tenant";
import { QUERY_GET_JOBS_FROM_AREA } from "./activityGQL";
import { GET_TREE } from "../registryComponent/aree/areeGQL";
import { QUERY_GET_CLIENTS,ADD_AUTOMATIC_ACTIVITY,UPDATE_AUTOMATIC_ACTIVITY, GET_ACTIVITY_IN_PROGRESS_USER, GET_ACTIVITY_PAUSED_USER } from './AutomaticheGQL';
import TreeViewAree from "../treeView/TreeView";
import { set } from 'date-fns';
import moment from "moment";
import { extractIdFormData } from '../../utilis/utils';
import {Card, CardBody, CardHeader, Tip} from "grommet";
import {
  Pause,
  StopFill,
  Play,
} from "grommet-icons";

function Automatiche(props){
    const [jobs,setJobs] = React.useState([]);
    const [tree,setTree]=React.useState([]);
    const [selectedNodes,setSelectedNodes]=React.useState([]);
    const [selectedJob,setSelectedJob]=React.useState([]);
    const [clientList,setClientList]=React.useState([]);
    const [selectedClient,setSelectedClient]=React.useState([]);
    const [activityPaused,setActivityPaused]=React.useState([]);
    const [notes,setNotes]=React.useState("");
    const [showJobsAutocomplete,setShowJobsAutocomplete]=React.useState(false);
    const [areThereActivityInProgress,setAreThereActivityInProgress]=React.useState(false);
    const [areThereActivityPaused,setAreThereActivityPaused]=React.useState(false);

    const [addActivity]=useMutation(ADD_AUTOMATIC_ACTIVITY);
    const [updateActivity]=useMutation(UPDATE_AUTOMATIC_ACTIVITY);

    const [ queryGetActivityUser
    ] = useLazyQuery(GET_ACTIVITY_IN_PROGRESS_USER, {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        if(data){
          const dataApp=data[`${tenant}_activity_activity${tenantType}`];
          if(dataApp){
            dataApp.forEach(item => {
              let fieldName = `jobs_job${tenantType}`;
              item.job= item[fieldName];  // Sposta il contenuto del campo specifico sotto 'user'
              delete item[fieldName]; // Elimina il vecchio campo
              fieldName = `machines_machine${tenantType}`;
              item.cliente= item[fieldName];  // Sposta il contenuto del campo specifico sotto 'user'
              delete item[fieldName]; // Elimina il vecchio campo
            })
            console.log("ACTIVITY",dataApp)
            props?.setActivity(dataApp)
          }
        }
      },
      notifyOnNetworkStatusChange: true, // did the work
    });

    const [ queryGetActivityPausedUser
    ] = useLazyQuery(GET_ACTIVITY_PAUSED_USER, {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        if(data){
          const dataApp=data[`${tenant}_activity_activity${tenantType}`];
          if(dataApp){
            dataApp.forEach(item => {
              let fieldName = `jobs_job${tenantType}`;
              item.job= item[fieldName];  // Sposta il contenuto del campo specifico sotto 'user'
              delete item[fieldName]; // Elimina il vecchio campo
              fieldName = `machines_machine${tenantType}`;
              item.cliente= item[fieldName];  // Sposta il contenuto del campo specifico sotto 'user'
              delete item[fieldName]; // Elimina il vecchio campo
            })
            console.log("ACTIVITY PAUSED",dataApp)
            setActivityPaused(dataApp)
          }
        }
      },
      notifyOnNetworkStatusChange: true, // did the work
    });

    const [ queryGetTree
    ] = useLazyQuery(GET_TREE, {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const tree=data[`${tenant}`]["whoami"].profile.treeArea   //[`areas${tenantTypeFirstUpperCase}First`][0].trees;
        if(tree){
          console.log("TREE",tree);
          setTree(JSON.parse(tree).tree);
        }
      },
      notifyOnNetworkStatusChange: true, // did the work
    });

    const [ queryGetClients
    ] = useLazyQuery(QUERY_GET_CLIENTS, {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        if(data){
          const dataApp=data[`${tenant}_machines_machine${tenantType}`];
          if(dataApp){
            console.log("CLIENTI",dataApp)
            setClientList(dataApp)
          }
        }
      },
      notifyOnNetworkStatusChange: true, // did the work
    });

    const [queryGetJobFromArea]= useLazyQuery(QUERY_GET_JOBS_FROM_AREA, {
        fetchPolicy: "no-cache",
          onCompleted: (data) => {
          if(data){
              const dataApp=data[`${tenant}_jobs_jobsarea`];
              if(dataApp){
                let filtred_jobs=[];
                dataApp.forEach(item => {
                  filtred_jobs.push(item[`jobs_job${tenantType}`])
                })
                console.log("JOBS",filtred_jobs)
                setJobs(filtred_jobs)
              }
            }
          },
          onError:(error)=>{
            console.log(error);
          },
          notifyOnNetworkStatusChange: true, // did the work
    });

    React.useEffect(()=>{
        queryGetTree()
        queryGetActivityUser({variables:{user_id:sessionStorage.getItem("userId")}});
        queryGetActivityPausedUser({variables:{user_id:sessionStorage.getItem("userId")}});
        queryGetClients();
    },[])

    React.useEffect(()=>{
      if(selectedNodes.length>0){
        queryGetJobFromArea({variables:{areaId:selectedNodes[0]}})
        console.log("SELECTED NODES",selectedNodes)
      }
    },[selectedNodes])

    React.useEffect(()=>{
      if(jobs.length>0){
        setSelectedJob(jobs[0]);
        setShowJobsAutocomplete(true)
      }
    },[jobs])

    React.useEffect(()=>{
      if(props?.activity.length>0){
        setAreThereActivityInProgress(true);
      }else{
        setAreThereActivityInProgress(false);
      }
    },[props?.activity])

    React.useEffect(()=>{
      if(activityPaused.length>0){
        setAreThereActivityPaused(true);
      }else{
        setAreThereActivityPaused(false);
      }
    },[activityPaused])

    React.useEffect(()=>{
      if(clientList.length>0){
        setSelectedClient(clientList[0]);
      }
    },[clientList])

    

    function handleInsertActivity(){
      let start=new Date();
      let end= new Date(start.getTime() + 30 * 60 * 1000);
      let data={variables:
        {
          start:start,
          end:end,
          notes:notes,
          job:selectedJob.id,
          machine:selectedClient.id,
          data:moment(start).format("YYYY/MM/DD"),
          area:selectedNodes[0],
          approved:"PARTITA",
          created_by_user:false,
          user:sessionStorage.getItem("userId")
        }
      }
      console.log("DATA",data);
      addActivity(data).then((value)=>{
        alert("Attività aggiunta correttamente");
        queryGetActivityUser({variables:{user_id:sessionStorage.getItem("userId")}});
        queryGetActivityPausedUser({variables:{user_id:sessionStorage.getItem("userId")}});
        setShowJobsAutocomplete(false);
        setNotes("");
        setSelectedJob(jobs[0]);
        setSelectedClient(clientList[0]);
        setSelectedNodes([]);
        
      })
      .catch((error)=>{
        console.error(error);
        alert("Errore durante l'operazione");
      }) 
    }

    function handleStartActivity(activityId){
      let thisActivity;

      props?.activity.forEach((value,index)=>{
        if(value.id===activityId){
          thisActivity=value;
        }
      })

      let start=new Date()
      let data={variables:
        {
          id_activity:activityId,
          start:start,
          end:new Date(start.getTime() + 30 * 60 * 1000),
          approved:"PARTITA"
        }
      }

      updateActivity(data).then((value)=>{
        alert("Attività aggiornata correttamente");
        props?.setActivity([])
        setActivityPaused([]);
        queryGetActivityUser({variables:{user_id:sessionStorage.getItem("userId")}});
        queryGetActivityPausedUser({variables:{user_id:sessionStorage.getItem("userId")}});
        
      })
      .catch((error)=>{
        console.error(error);
        alert("Errore durante l'operazione");
      })

    }

    function handlePauseActivity(activityId){
      let thisActivity;

      props?.activity.forEach((value,index)=>{
        if(value.id===activityId){
          thisActivity=value;
        }
      })

      let end=new Date()
      let data={variables:
        {
          id_activity:activityId,
          start:thisActivity.start,
          end:end,
          approved:"SI"
        }
      }

      let dataNewActivity={variables:
        {
          start:new Date(end.getTime() + 5 * 60 * 1000),
          end:new Date(end.getTime() + 35 * 60 * 1000),
          notes:thisActivity.notes,
          job:thisActivity.job.id,
          machine:thisActivity.cliente.id,
          data:moment(end).format("YYYY/MM/DD"),
          area:thisActivity.area_id,
          approved:"PAUSA",
          created_by_user:false,
          user:sessionStorage.getItem("userId")
        }
      }

      updateActivity(data).then((value)=>{
        alert("Attività aggiornata correttamente");
        addActivity(dataNewActivity).then((value)=>{
          alert("Attività aggiunta correttamente");
          props?.setActivity([])
          setActivityPaused([]);
          queryGetActivityUser({variables:{user_id:sessionStorage.getItem("userId")}});
          queryGetActivityPausedUser({variables:{user_id:sessionStorage.getItem("userId")}});
        })
        .catch((error)=>{
          console.error(error);
          alert("Errore durante l'operazione");
        }) 
        
      })
      .catch((error)=>{
        console.error(error);
        alert("Errore durante l'operazione");
      }) 

      console.log("DATA NEW ACTIVITY",dataNewActivity);
      console.log("DATA UPDATE ACTIVITY",data);
    }

    function handleStopActivity(activityId){
      let thisActivity;

      props?.activity.forEach((value,index)=>{
        if(value.id===activityId){
          thisActivity=value;
        }
      })

      let end=new Date()
      let data={variables:
        {
          id_activity:activityId,
          start:thisActivity.start,
          end:end,
          approved:"SI"
        }
      }

      updateActivity(data).then((value)=>{
        alert("Attività aggiornata correttamente");
        props?.setActivity([])
        setActivityPaused([]);
        queryGetActivityUser({variables:{user_id:sessionStorage.getItem("userId")}});
        queryGetActivityPausedUser({variables:{user_id:sessionStorage.getItem("userId")}});
      })
      .catch((error)=>{
        console.error(error);
        alert("Errore durante l'operazione");
      })

      
    }

    return(
        <Box style={{padding:"20px", overflowY:"scroll"}} width={"100%"} height={"800px"}>
              <center style={{marginTop:"40px"}}>
            {
              (areThereActivityInProgress || areThereActivityPaused) && areThereActivityInProgress ?
              props?.activity.map((value, i) => (
                     <Card
                         key={i}
                         style={{
                             width: "400px",
                             height: "fit content",
                             padding: "20px",
                             marginBottom: "20px",
                             fontSize: "14px"
                         }}
                     >
                         <CardHeader>
                             <b>{value.job.name} PER {value.cliente.name}</b> <i>in corso</i>
                         </CardHeader>
                         <CardBody pad="medium" gap='large' align="center" direction="column">
                              <Tip content="Pausa">
                                  <Pause size="large" color="#397aa3" onClick={() => handlePauseActivity(value.id)} />
                              </Tip>
                              <Tip content="Stop">
                                  <StopFill size="large" color="red"  onClick={() => handleStopActivity(value.id)} />
                              </Tip>
                         </CardBody>
                     </Card>
                 ))
                 :
                 activityPaused.map((value, i) => (
                  <Card
                      key={i}
                      style={{
                          width: "400px",
                          height: "fit content",
                          padding: "20px",
                          marginBottom: "20px",
                          fontSize: "14px"
                      }}
                  >
                      <CardHeader>
                          <b>{value.job.name} PER {value.cliente.name}</b> <i>in pausa...</i>
                      </CardHeader>
                      <CardBody pad="medium" gap='large' align="center" direction="column">
                           <Tip content="Riprendi">
                               <Play size="large" color="#397aa3" onClick={() => handleStartActivity(value.id)} />
                           </Tip>
                      </CardBody>
                  </Card>
              ))

            }
                </center>
            { (showJobsAutocomplete && (!areThereActivityInProgress || areThereActivityPaused)) &&
            <center>
              <Box style={{marginTop:"40px"}}>
                  <Text><b>SELEZIONA UNA TIPOLOGIA DI ATTIVITA'</b></Text>
                  <Autocomplete
                    style={{width:"300px"}}
                    disablePortal
                    id="combo-box-demo"
                    options={jobs}
                    value={selectedJob}
                    onChange={(event,newValue,index) => {
                      setSelectedJob(newValue);
                    }}
                    getOptionLabel={(option)=>option.name}
                    renderInput={(params) => <TextField {...params} size="medium" variant="standard"/>}
                  />
                  <Box style={{marginTop:"30px"}}>
                    <Text><b>SELEZIONA UN CLIENTE</b></Text>
                    <Autocomplete
                      style={{width:"300px"}}
                      disablePortal
                      id="combo-box-demo"
                      options={clientList}
                      value={selectedClient}
                      onChange={(event,newValue,index) => {
                        setSelectedClient(newValue);
                      }}
                      getOptionLabel={(option)=>option.name}
                      renderInput={(params) => <TextField {...params} size="medium" variant="standard"/>}
                    />

                  <TextArea
                    value={notes}
                    style={{height:"180px",width:"300px",marginTop:"30px"}}
                    resize={false}
                    placeholder="Inserisci nota... (opzionale)"
                    onChange={event => setNotes(event.target.value)}
                  />
                  </Box>
                  <Button variant="contained" style={{marginTop:"30px"}} onClick={(e)=>{handleInsertActivity()}} color="success">CREA</Button>
              </Box>
            </center>
          }
           { !areThereActivityInProgress && 
            <Box style={{marginTop:"40px"}}>
              <TreeViewAree checkAssignedAree={true} selectedNodes={selectedNodes} setSelectedNodes={setSelectedNodes} addElement={false} tree={tree} singleChildForNode={false} singleSelect={true}/>
            </Box>
          }
            
        </Box>
    )
}

export default Automatiche;