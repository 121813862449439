import {Layer,Box,Text} from "grommet";
import { CircularProgress } from "@mui/material";

function LoadingLayer(props){
    return(
        <Layer>
            <Box align="center" pad="medium" gap="small">
                 <CircularProgress  value={90}/>
                {props?.text && <Text>{props?.text}</Text>}
            </Box>
        </Layer>
    )
}

export default LoadingLayer;