import React from "react";
import { Box, Text, Heading, Form, FormField, Button, Select, Anchor, CheckBox,TextArea } from "grommet";
import { useHistory } from "react-router-dom";
import { useLazyQuery,useMutation } from '@apollo/client'
import {
  QUERY_GET_USERS,
  QUERY_GET_MACHINE_FROM_AREA,
  QUERY_GET_MACHINE_FROM_JOB,
  QUERY_GET_JOBS_FROM_AREA,
  QUERY_GET_JOBS_FROM_MACHINE,
  ADD_ACTIVITY,
  UPDATE_ACTIVITY,
  QUERY_GET_SINGLE_ACTIVITY,
  GET_USER_TREE, MUTATION_PARTECIPANT, QUERY_GET_CUSTOMER_FROM_AREA, QUERY_GET_CUSTOMER_FROM_JOB
} from "./activityGQL";
import { tenant,tenantType,tenantTypeFirstUpperCase  } from "../tenant";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from "moment";
import { extractIdFormData } from "../../utilis/utils";
import TreeViewAree from "../treeView/TreeView";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import {it} from 'date-fns/locale'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {LinkPrevious} from "grommet-icons";
import {useLocation} from "react-router-dom";
import {GET_PRODUCTS, UPSERT_PRODUCT} from "../wareHouse/ProductGQL.jsx";
import {Button as ButtonMui} from "@mui/material";

export default function AddAtt(props) {
  
  const history = useHistory();
  const location = useLocation();
  const [usersList,setUsersList]=React.useState([]);
  const [jobsList,setJobsList]=React.useState([]);
  const [machinesList,setMachinesList]=React.useState([]);
  const [formData,setFormData]=React.useState({approved:"",createdByUser:false,ordinary:"ordinaria"});
  const [userTree,setUserTree]=React.useState([]);
  const [selectedNodes,setSelectedNodes]=React.useState([]);
  const [firstFilterMachine,setFirstFilterMachine]=React.useState();
  const [products,setProducts]=React.useState([]);
  let [productSel,setProductSel]=React.useState(null);
  const [activityOffice,setActivityOffice]=React.useState(false);
  const [addActivity]=useMutation(ADD_ACTIVITY);
  const [updateActivity]=useMutation(UPDATE_ACTIVITY);
  const [mutationProduct]=useMutation(UPSERT_PRODUCT);
  const [mutationActivityPartecipant]=useMutation(MUTATION_PARTECIPANT)
  let [quantityUsed,setQuantityUsed]=React.useState(0);
  const [partecipants,setPartecipants]=React.useState([]);
   const [ queryGetProducts
    ] = useLazyQuery(GET_PRODUCTS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
        const products=data[`${tenant}_products_product`]
        if(products){
            setProducts(products);
        }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });
  const ordinary=["ORDINARIA","STRAORDINARIA"]

  const [ queryGetUsers
  ]  = useLazyQuery(QUERY_GET_USERS, {
  fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const res=data[`${tenant}_users_user${tenantType}`];
      if(res){
        const userApp=[];
        for(let i=0;i<res.length;i++){
          userApp.push({...res[i],label:`${res[i].name} ${res[i].surname} ${res[i].registration_number}`})
        }
        setUsersList(userApp);
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });

  const [ queryGetUserTree
  ]  = useLazyQuery(GET_USER_TREE, {
  fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setUserTree(JSON.parse(data[`${tenant}`][`user${tenantTypeFirstUpperCase}`].treeArea).tree);
    },
    notifyOnNetworkStatusChange: true, // did the work
  });

  const [ queryGetJobsFromMachine
  ]  = useLazyQuery(QUERY_GET_JOBS_FROM_MACHINE, {
  fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const res=data[`${tenant}_machines_machinejobs`];
      if(res){
        setJobsList(res.map((job)=>job[`jobs_job${tenantType}`]));
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });
  
  const [ queryGetMachineFromArea
  ]  = useLazyQuery(QUERY_GET_MACHINE_FROM_AREA, {
  fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const res=data[`${tenant}_machines_machinearea`];
      if(res){
        setMachinesList(res.map((machine)=>machine[`machines_machine${tenantType}`]));
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });

  const [ queryGetCustomerFromArea
  ]  = useLazyQuery(QUERY_GET_CUSTOMER_FROM_AREA, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const res=data[`${tenant}_machines_machinearea`];
      if(res){
        setMachinesList(res.map((machine)=>machine[`machines_machine${tenantType}`]));
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });

  const [ queryGetJobsFromArea
  ]  = useLazyQuery(QUERY_GET_JOBS_FROM_AREA, {
  fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const res=data[`${tenant}_jobs_jobsarea`];
      if(res){
        setJobsList(res.map((job)=>job[`jobs_job${tenantType}`]));
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });
  
  const [ queryGetMachineFromJob
  ]  = useLazyQuery(QUERY_GET_MACHINE_FROM_JOB, {
  fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const res=data[`${tenant}_machines_machinejobs`];
      if(res){
        setMachinesList(res.map((machine)=>machine[`machines_machine${tenantType}`]));
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });

  const [ queryGetCustomerFromJob
  ]  = useLazyQuery(QUERY_GET_CUSTOMER_FROM_JOB, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const res=data[`${tenant}_machines_machinejobs`];
      if(res){
        setMachinesList(res.map((machine)=>machine[`machines_machine${tenantType}`]));
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });
  
   const [queryGetActivity]=useLazyQuery(QUERY_GET_SINGLE_ACTIVITY, {
    fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const res=data[`${tenant}_activity_activity${tenantType}_by_pk`];
        if(res){
          setSelectedNodes([res[`area_area${tenantType}`].id])
          queryGetJobsFromArea({variables:{areaId:res[`area_area${tenantType}`].id}})
          queryGetMachineFromJob({variables:{jobId:res[`jobs_job${tenantType}`].id,areaId:res[`area_area${tenantType}`].id}});
          setFormData({
            id: res.id,
            user: {label:`${res[`users_user${tenantType}`].name} ${res[`users_user${tenantType}`].surname} ${res[`users_user${tenantType}`].registration_number}`,id:res[`users_user${tenantType}`].id},
            area: res[`area_area${tenantType}`],
            job: res[`jobs_job${tenantType}`],
            machine: res[`machines_machine${tenantType}`],
            start: res.start,
            end: res.end,
            ordinary: res.ordinary?res.ordinary:"ordinaria",
            approved: res.approved==="SI" || res.approved==="si"?res.approved:res.approved==="MANUALE"?true:false,
            notes: res.notes,
            created_by_user:false,
          });

        }
      },
      notifyOnNetworkStatusChange: true, // did the work
    })

  React.useEffect(()=>{
    queryGetProducts({variables:{areaId:JSON.parse(sessionStorage.getItem("Cantiere"))}})
    const choiceFirstFilter=localStorage.getItem("firstFilterMachine")
    if(choiceFirstFilter){
      setFirstFilterMachine(choiceFirstFilter==="true"?true:false);
    }
  },[])

  React.useEffect(()=>{
     queryGetUsers({variables:{areeId: JSON.parse(sessionStorage.getItem("areeId"))}});
  },[location])
 
  React.useEffect(()=>{
    const startDateSelected=sessionStorage.getItem('StartDateSelected') || location && location.state && location.state.start || null
    let formDataApp={...formData};
    if(startDateSelected){
      formDataApp={...formDataApp,start:moment(startDateSelected)}
      sessionStorage.removeItem("StartDateSelected");
    }
    const endDateSelected=sessionStorage.getItem('EndDateSelected') || location && location.state && location.state.end || null
    if(endDateSelected){
      formDataApp={...formDataApp,end:moment(endDateSelected)}
      sessionStorage.removeItem("EndDateSelected");
    }
    const userId=sessionStorage.getItem('UserId') ||  location && location.state && location.state.userId || null
    if(userId){
      let user=usersList.filter((user)=>user.id==userId)[0];
      if(user){
        formDataApp={...formDataApp,user:user}
        sessionStorage.removeItem("UserId");
      }
    }
    setFormData(formDataApp);
  },[usersList])

  React.useEffect(()=>{
    if(formData.user){
      queryGetUserTree({variables:{id:formData.user.id}})
    }
  },[formData.user])

  React.useEffect(()=>{
    if(selectedNodes && selectedNodes[0] ){
      setFormData({...formData,area:selectedNodes[0]});
      if(firstFilterMachine){
          queryGetMachineFromArea({variables:{areaId:selectedNodes[0]}})
      }else{ //Filtro prima i lavori
        queryGetJobsFromArea({variables:{areaId:selectedNodes[0]}})
      }
    }
  },[selectedNodes])

  React.useEffect(()=>{
    if(formData.machine){
      if(!activityOffice){
        if(firstFilterMachine){
          queryGetJobsFromMachine({variables:{machineId:formData.machine.id,areaId:selectedNodes[0]}});
        }
      }else{
          queryGetJobsFromArea({variables:{areaId:selectedNodes[0]}})
      }
    }
  },[formData.machine])

  React.useEffect(()=>{
    if(formData.job){
      if(!activityOffice){
        if(!firstFilterMachine){
          queryGetMachineFromJob({variables:{jobId:formData.job.id,areaId:selectedNodes[0]}});
        }
      }else{
        queryGetCustomerFromArea()
      }
    }
  },[formData.job])

  React.useEffect(()=>{
    setFormData({...formData,data:moment(formData.fromData).format("YYYY/MM/DD")})
  },[formData.fromData])

  React.useEffect(() => {
    if(location && location.state && location.state && location.state.activityData && location.state.activityData.id){
      queryGetActivity({variables:{id:location.state.activityData.id}})
    }
  }, [usersList]);

   React.useEffect(()=>{
     if(activityOffice){
       queryGetCustomerFromArea();
     }
   },[activityOffice])

  const onChangeTextInput=(event)=>{
    setFormData({...formData,[event.target.name]:event.target.value})
  }

  const onChangeSelect=(event)=>{
    setFormData({...formData,[event.target.name]:event.value})
  }

  const onChangeCheckBox=(event)=>{
    setFormData({...formData,[event.target.name]:event.target.checked})
  }

  React.useEffect(()=>{
    if(quantityUsed<0){
      alert("Non puoi selezionare una quantità negativa");
      setQuantityUsed(0);
    }else if(quantityUsed>productSel?.quantity){
      alert("Non hai abbastanza giacenze per questo prodotto");
      setQuantityUsed(quantityUsed--);
    }
  },[quantityUsed])

  function onAddActivity(){
    let obj=extractIdFormData(formData,false);
    if(obj.variables.approved){
      obj.variables.approved="MANUALE"
    }else{
      obj.variables.approved=""
    }
    obj.variables.data = moment(obj.variables?.start).format("YYYY-MM-DD")
    addActivity(obj).then((value)=>{
      console.log("VALUE");
      let activityId=value["data"][`insert_${tenant}_activity_activity${tenantType}_one`].id;
      alert("Attività aggiunta correttamente");
      if(productSel){
          let obj={...productSel};
          obj.quantity=obj.quantity-quantityUsed
          delete obj.__typename
          mutationProduct({variables:obj})
              .then((value)=>console.log(value))
              .catch((error)=>console.error(error))
      }
      if(partecipants.length>0){
        for(let i=0;i<partecipants.length;i++){
            mutationActivityPartecipant({variables:{activityLeader:activityId,user:partecipants[i].id}})
              .then((value)=>console.log(value))
              .catch((error)=>console.error(error))
        }
      }
      history.push({
        pathname: '/home',
        page: "Attività"
      });
    })
    .catch((error)=>{
      console.error(error);
      alert("Errore durante l'operazione");
    })
  }

  function onUpdateActivity(){
    console.log("FORMDATA",formData);
    let obj=extractIdFormData(formData,false);
    if(formData.approved==="SI" || formData.approved==="SI"){
      obj.variables.approved="SI"
    }else if(obj.variables.approved){
      obj.variables.approved="manuale"
    }else{
      obj.variables.approved=""
    }
    console.log("OBJ1",obj);
    obj.variables.data = moment(obj.variables?.start).format("YYYY-MM-DD")
    obj.variables.start = moment(obj.variables?.start)
    updateActivity(obj).then((value)=>{
      alert("Attività aggiornata correttamente");
       let activityId=value["data"][`update_${tenant}_activity_activity${tenantType}_by_pk`].id;
       if(productSel){
          let obj={...productSel};
          obj.quantity=obj.quantity-quantityUsed
          delete obj.__typename
          mutationProduct({variables:obj})
              .then((value)=>console.log(value))
              .catch((error)=>console.error(error))
      }
       if(partecipants.length>0){
        for(let i=0;i<partecipants.length;i++){
            mutationActivityPartecipant({variables:{activityLeader:activityId,user:partecipants[i].id}})
              .then((value)=>console.log(value))
              .catch((error)=>console.error(error))
        }
      }
      history.push({
        pathname: '/home',
        page: "Attività"
      });
    })
    .catch((error)=>{
       console.error(error);
      alert("Errore durante l'operazione");

    })
  }

  return (
    <Box margin="small" direction="column" width={"800px"}>
       <Button margin="small" style={{width:'150px',fontSize:'15px',background:"#397aa3",color:'white',fontWeight:'bold',textTransform: 'uppercase',borderStyle: 'solid',borderColor:'#397aa3',borderWidth:"3px", borderRadius:"10px"}}
          onClick={()=>
            history.push({
              pathname: '/home',
              page: "home"
            })}>
          <Box pad="small" direction="row" align="center" gap="medium">
              <LinkPrevious color="white"/>
              <Text size="small" color="white">Indietro</Text>
          </Box>
        </Button>
      <Box round pad="medium" direction="column" background="white">
        <Heading level="2" alignSelf="center" margin="small" size="small" style={{textTransform:'uppercase'}}>
          Crea Attività
        </Heading>
        <Box>
        <Form>
          <Box direction="column">
            <FormControlLabel
                control={
                  <Switch checked={activityOffice} onChange={(event)=>{setActivityOffice(event.target.checked)}} name={"è un attività di ufficio?"} />
                }
                label={"è un'attività di ufficio?"}
            />
            {
                !activityOffice &&
                <FormControlLabel
                    control={
                      <Switch checked={firstFilterMachine} onChange={(event)=>{setFirstFilterMachine(event.target.checked);localStorage.setItem("firstFilterMachine",event.target.checked)}} name={firstFilterMachine?"Filtra prima le macchine":"Filtra prima i lavori"} />
                    }
                    label={firstFilterMachine?"Filtra prima le macchine":"Filtra prima i lavori"}
                />
            }
            <FormField required margin="medium">
              <Box direction="row">
                <Text style={{marginRight:'10px',marginTop:'-3px'}}>Seleziona il dipendente: *</Text>
                { formData?.user && <Anchor onClick={() => {setFormData({...formData,user:""});setUserTree([])}} label="Rimuovi" /> }
              </Box>
              <Select
                name="user"
                options={usersList}
                labelKey={(option)=>option?.label}
                value={formData?.user}
                onChange={( e ) =>onChangeSelect(e)}
              />
            </FormField>
            <FormField required margin="medium">
              <Box direction="row">
                <Text style={{marginRight:'10px',marginTop:'-3px'}}>Seleziona partecipanti (se presenti): *</Text>
              </Box>
              <Select
                  multiple={true}
                name="partecipant"
                options={usersList}
                  closeOnChange={false}
                labelKey={(option)=>option?.label}
                value={partecipants}
                onChange={( e ) =>{setPartecipants(e.value)}}
              />
            </FormField>
              {
                 userTree.length!==0 &&
                <Box style={{overflowX:"hidden"}} overflow="auto" width="98%">
                  <TreeViewAree checkAssignedAree={true} selectedNodes={selectedNodes} setSelectedNodes={setSelectedNodes} addElement={false} tree={userTree} singleChildForNode={false} singleSelect={true}/>
                </Box>
             }

            {
              !activityOffice ?
                  <Box >
                    {
                      firstFilterMachine?
                          <FormField required margin="medium">
                            <Box direction="row">
                              <Text style={{marginRight:'10px',marginTop:'-3px'}}>Seleziona il macchinario: *</Text>
                              { formData?.machine && <Anchor onClick={() => setFormData({...formData,machine:""})} label="Rimuovi" />
                              }
                            </Box>
                            <Select
                                name="machine"
                                options={machinesList}
                                value={formData?.machine}
                                labelKey={(option)=>option?.name}
                                onChange={( e ) =>onChangeSelect(e)}
                            />
                          </FormField>
                          :
                          <FormField required margin="medium">
                            <Box direction="row">
                              <Text style={{marginRight:'10px',marginTop:'-3px'}}>Seleziona il lavoro: *</Text>
                              {formData?.job && <Anchor onClick={() => setFormData({...formData,job:""})} label="Rimuovi" />
                              }
                            </Box>
                            <Select
                                name="job"
                                labelKey="name"
                                options={jobsList}
                                value={formData?.job}
                                onChange={( e ) =>onChangeSelect(e)}
                            />
                          </FormField>
                    }
                  </Box>
                  :
                  <FormField required margin="medium">
                    <Box direction="row">
                      <Text style={{marginRight:'10px',marginTop:'-3px'}}>Seleziona il lavoro: *</Text>
                      {formData?.job && <Anchor onClick={() => setFormData({...formData,job:""})} label="Rimuovi" />
                      }
                    </Box>
                    <Select
                        name="job"
                        labelKey="name"
                        options={jobsList}
                        value={formData?.job}
                        onChange={( e ) =>onChangeSelect(e)}
                    />
                  </FormField>
            }
            {
              !activityOffice ?
                  <Box>
                    {
                      firstFilterMachine?
                          <FormField required margin="medium">
                            <Box direction="row">
                              <Text style={{marginRight:'10px',marginTop:'-3px'}}>Seleziona il lavoro: *</Text>
                              {formData?.job && <Anchor onClick={() => setFormData({...formData,job:""})} label="Rimuovi" />}
                            </Box>
                            <Select
                                name="job"
                                labelKey="name"
                                options={jobsList}
                                value={formData?.job}
                                onChange={( e ) =>onChangeSelect(e)}
                            />
                          </FormField>
                          :
                          <FormField required margin="medium">
                            <Box direction="row">
                              <Text style={{marginRight:'10px',marginTop:'-3px'}}>Seleziona il macchinario: *</Text>
                              { formData?.machine && <Anchor onClick={() => setFormData({...formData,machine:""})} label="Rimuovi" />
                              }
                            </Box>
                            <Select
                                name="machine"
                                options={machinesList}
                                value={formData?.machine}
                                labelKey="name"
                                onChange={( e ) =>onChangeSelect(e)}
                            />
                          </FormField>
                    }
                  </Box>
                  :
                  <FormField required margin="medium">
                    <Box direction="row">
                      <Text style={{marginRight:'10px',marginTop:'-3px'}}>Seleziona il cliente: *</Text>
                      { formData?.machine && <Anchor onClick={() => setFormData({...formData,machine:""})} label="Rimuovi" />
                      }
                    </Box>
                    <Select
                        name="machine"
                        options={machinesList}
                        value={formData?.machine}
                        labelKey="name"
                        onChange={( e ) =>onChangeSelect(e)}
                    />
                  </FormField>
            }

            <Box direction="row" gap="small">
              <LocalizationProvider locale={it} dateAdapter={AdapterMoment}>
                <DateTimePicker 
                  ampm={false}
                  format="DD-MM-YYYY HH:mm"
                  label="Ora inizio"
                  value={formData?.start && moment(formData.start)}
                  onChange={(newValue)=>setFormData({...formData,start:newValue,end:newValue})}
                  />
              </LocalizationProvider>
              <LocalizationProvider locale={it} dateAdapter={AdapterMoment}>
                  <DateTimePicker 
                    ampm={false}
                    format="DD-MM-YYYY HH:mm"
                    label="Ora fine"
                    value={formData?.end && moment(formData.end)}
                    onChange={(newValue)=>setFormData({...formData,end:newValue})}
                  />
               </LocalizationProvider>
            </Box>
          </Box>
          <Box direction="row" align="center">
            {
              /**
               * <FormField required margin="medium">
               *               <Box direction="row">
               *                 <Text style={{marginRight:'10px',marginTop:'-3px'}}>Ordinaria/Straordinaria:</Text>
               *                 { formData?.ordinary && <Anchor onClick={() => setFormData({...formData,ordinary:""})} label="Rimuovi" />  }
               *               </Box>
               *               <Select
               *                 name="ordinary"
               *                 options={ordinary}
               *                 value={ordinary[0]}
               *                 onChange={( e ) => onChangeSelect(e)}
               *               />
               *             </FormField>
               * */
            }
            {
              /*
              * <FormField margin="medium">
               <Text >Prodotto: </Text>
               <Select
                  name="product"
                  value={productSel}
                  options={products}
                  labelKey={(option)=>option.name}
                  onChange={e => {setProductSel(e.value);}}
               />
            </FormField>
              * */
            }
            {
              productSel &&
                <Box direction="row" gap="medium">
                  <Box width="30px">
                    <Text>{quantityUsed}</Text>
                  </Box>
                  <ButtonMui
                      variant="contained"
                      color="primary"
                      onClick={(event) => {
                          let quantityUsedApp=quantityUsed;
                          quantityUsedApp=quantityUsedApp+productSel.interval;
                          setQuantityUsed(quantityUsedApp)

                      }}
                    >
                      +
                  </ButtonMui>
                   <ButtonMui
                      variant="contained"
                      color="error"
                      onClick={(event) => {
                           let quantityUsedApp=quantityUsed;
                           quantityUsedApp=quantityUsedApp-productSel.interval;
                          setQuantityUsed(quantityUsedApp)
                          /*let obj={...productSel};
                          obj.quantity--
                          delete obj.__typename;
                          setProductSel({...obj})*/
                      }}
                    >
                      -
                  </ButtonMui>
              </Box>
            }
          </Box>
          <Box margin="medium" alignSelf="center" justify="end">
              <CheckBox
                name="approved"
                checked={formData && formData.approved===true} //===true perchè deve essere specificato che sia diverso da si
                label="Chiusura Manuale"
                onChange={(e) => onChangeCheckBox(e)}
              />
            </Box>
            <Box >
              <FormField required margin="medium">
                <Text style={{marginRight:'10px',marginTop:'-3px'}}>Note (MAX 40 CARATTERI)</Text>
                <TextArea
                  name="notes"
                  resize={false}
                  value={formData?.notes}
                  placeholder="es. Effettuare una foto terminato il lavoro"
                  onChange={e => onChangeTextInput(e)}
                />
              </FormField>
            </Box>
          <Box direction="row" justify="end">
            {/*
              timeError?
              <Text color="red" weight="bold">L'orario inserito non è corretto</Text>
              :
            <Button label="Crea" onClick={onAssignActivity} style={{width:'138px',height:'34px',fontSize:'15px',background:'#397aa3',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}/>
              */}
            <Button label={location?.state?.activityData?.id ?"Aggiorna": "Crea"} onClick={location?.state?.activityData && location?.state?.activityData.id? onUpdateActivity:onAddActivity} style={{width:'138px',height:'34px',fontSize:'15px',background:'#397aa3',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}/>
          </Box>
        </Form>
        </Box>
      </Box>
    </Box>
  )
}