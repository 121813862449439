import {tenant} from "../components/tenant";
import {gql, useLazyQuery} from "@apollo/client";

function shadeColor(color, percent) {

    let R = parseInt(color.substring(1,3),16);
    let G = parseInt(color.substring(3,5),16);
    let B = parseInt(color.substring(5,7),16);

    R = parseInt(R * (100 + percent) / 100);
    G = parseInt(G * (100 + percent) / 100);
    B = parseInt(B * (100 + percent) / 100);

    R = (R<255)?R:255;
    G = (G<255)?G:255;
    B = (B<255)?B:255;

    R = Math.round(R)
    G = Math.round(G)
    B = Math.round(B)

    let RR = ((R.toString(16).length===1)?"0"+R.toString(16):R.toString(16));
    let GG = ((G.toString(16).length===1)?"0"+G.toString(16):G.toString(16));
    let BB = ((B.toString(16).length===1)?"0"+B.toString(16):B.toString(16));

    return "#"+RR+GG+BB;
}

function extractIdFormData(formData,camelCase){
    let formDataApp={...formData};
    for (const [key, value] of Object.entries(formDataApp)) { //Lascio solo gli ID
        if(value && typeof(value)==="object" && Array.isArray(value)){ //
            if(camelCase && snakeToCamel(key)!==key){
                formDataApp[snakeToCamel(key)]=value.map((val)=>val.id)
                delete formDataApp[key];
            }else{
                formDataApp[key]=value.map((val)=>val.id)
            }
        }else if((value && typeof(value)==="object" && !Array.isArray(value))){
            if(value?.id){ //Se è un JSON object (es. geo_info), ritorno l'oggetto
                if(camelCase && snakeToCamel(key)!==key){
                    formDataApp[snakeToCamel(key)]=value.id
                    delete formDataApp[key];
                }else{
                    formDataApp[key]=value.id
                }
            }
        }else if(camelCase && snakeToCamel(key)!==key){
            formDataApp[snakeToCamel(key)]=value
            delete formDataApp[key];
        }
    }
    return {
        variables: {
            ...formDataApp
        }
    }
}

const snakeToCamel = str =>
  str.toLowerCase().replace(/([-_][a-z])/g, group =>
    group
      .toUpperCase()
      .replace('-', '')
      .replace('_', '')
  );


const extractRoleFromWhoami=(data,setRuoli,setCantieri,setCantSel,setAreeId,queryGetChildrenArea)=>{
    const role=data[`${tenant}`]?.whoami.profile.userroleSet.map(function role(el){return {Ruolo:el.role.roleName}})
    setRuoli(role);
    if(role[0]?.Ruolo==="admin"){
        extractAreeFromWhoami(data,setAreeId);
        //extractCantieriFromWhoami(data,setCantieri,setCantSel,null)
       // extractCantieriFromWhoami(data,setCantieri,setCantSel,queryGetChildrenArea)
        // extractCantieriFromWhoami(data,setCantieri,setCantSel,null)
        setCantSel(null)
    }else{
        extractCantieriFromWhoami(data,setCantieri,setCantSel,queryGetChildrenArea)
    }

}

const extractUserProfileFromWhoami=(data,setUser)=>{
    const profile=data[`${tenant}`]?.whoami.profile;
    setUser(profile);
}

const extractAreeFromWhoami=(data,setAree)=>{
    let aree=data[`${tenant}`]?.whoami.profile.userareaSet;
    setAree(aree.map((el)=>el.area.id));
    sessionStorage.setItem("Cantiere",JSON.stringify(aree.map((el)=>el.area.id)))
    sessionStorage.setItem("areeId",JSON.stringify(aree.map((el)=>el.area.id)))
}


const extractCantieriFromWhoami=(data,setCantieri,setCantiereSel,queryGetChildrenArea)=>{
    const treeRoot=JSON.parse(data[`${tenant}`]["whoami"].profile.treeRoot)
   // console.log("TREE ROOT",treeRoot);
    setCantieri(treeRoot.sort((a,b)=>a.name.localeCompare(b.name)));
    const sessionCantId=JSON.parse(sessionStorage.getItem("Cantiere"));
    if(queryGetChildrenArea){ //Caso non admin
        if(sessionCantId){
            const cantFound=treeRoot.find((el)=>sessionCantId.includes(el?.id))
            setCantiereSel(cantFound);
            queryGetChildrenArea({variables:{id:sessionCantId}})
        }else if(treeRoot?.length>0 && !sessionCantId){
            setCantiereSel(treeRoot[0]);
            sessionStorage.setItem("Cantiere",JSON.stringify([treeRoot[0].id]))
            queryGetChildrenArea({variables:{id:treeRoot[0]?.id}})
        }
    }else{
         setCantiereSel(treeRoot[0]);
         sessionStorage.setItem("Cantiere",JSON.stringify(treeRoot.sort((a,b)=>a.name.localeCompare(b.name)).map((el)=>el?.id)))
    }

}

const extractRootTreeFromWhoami=(data,setRoot)=>{
    console.log(data);
}


function deleteCookie( name, path, domain ) {
    if( getCookie( name ) ) {
      document.cookie = name + "=" +
        ((path) ? ";path="+path:"")+
        ((domain)?";domain="+domain:"") +
        ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
    }
  }

  function getCookie(name) {
      let v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
      return v ? v[2] : null;
  }

  const setSession = (name, accessToken) => {
    if (accessToken) {
        sessionStorage.setItem(name, accessToken)
      //  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
        sessionStorage.removeItem(name)
      //  delete axios.defaults.headers.common.Authorization
    }
}

function oreMinutiToString(oreDouble) {
  // Assicuriamoci che il valore in input sia un numero
  if (typeof oreDouble !== 'number') {
    throw new Error('Il parametro deve essere un numero.');
  }

  // Calcoliamo le ore e i minuti separati
  let ore = Math.floor(oreDouble);
  const minutiDecimali = (oreDouble - ore) * 60;
  let minuti = Math.round(minutiDecimali);
  if(minuti===60){
      minuti=0;
      ore=ore+1
  }

  // Formattiamo la stringa nel formato ore:minuti
  const oreString = ore < 10 ? `0${ore}` : `${ore}`;
  const minutiString = minuti < 10 ? `0${minuti}` : `${minuti}`;

  return `${oreString}:${minutiString}`;
}

const QUERY_CONTENT_TYPE=gql`
query ContentType($model:String) {
  django_content_type(where: {model:{_eq:$model}}) {
    id
    app_label
    model
  }
}
`

const GOLF_ADD_ATTACHMENT=gql`
mutation upsertAttachment($name:String!,$contentType:ID!,$objectId:Int!,$attachment:String!){
  ${tenant}{
    upsertAttachment(input:{name:$name,contentType:$contentType,objectId:$objectId,attachment:$attachment}){
      objectId
      name
      contentType
      objectId
      errors{
        messages
      }
    }
  }
}
`

const GOLF_GET_ATTACHMENT=gql`
query getAttachment($contentTypeId:Int!,$objectId:Int!){
  ${tenant}_attachments_attachment(where:{content_type_id:{_eq:$contentTypeId},object_id:{_eq:$objectId}}){
    id
    name
    attachment
  }
}
`

export {shadeColor,extractIdFormData,extractRoleFromWhoami,extractUserProfileFromWhoami,extractAreeFromWhoami, deleteCookie, getCookie ,setSession,oreMinutiToString,extractRootTreeFromWhoami,extractCantieriFromWhoami,QUERY_CONTENT_TYPE,GOLF_ADD_ATTACHMENT,GOLF_GET_ATTACHMENT}