import { gql } from '@apollo/client';
import { tenant, tenantType, tenantTypeFirstUpperCase } from '../../tenant';


const UPSERT_CUSTOMER=gql`
mutation UpsertMachine($id:ID,$aree:String!,$chassisNumber:String!,$name:String!,$externalErpCode:String,$notes:String,$can:String!,$jobs:String!){
  ${tenant}{
      upsertMachine${tenantTypeFirstUpperCase}(input:{id:$id,aree:$aree,chassisNumber:$chassisNumber,name:$name,externalErpCode:$externalErpCode,notes:$notes,can:$can,jobs:$jobs,hourlyCost:0,isMachine: false}){
      errors{
        field
      }
      machine${tenantTypeFirstUpperCase}{
        id
      }
    }
  }
}
`

const DELETE_CUSTOMER=gql`
mutation deleteMachine($id:ID!){
  ${tenant}{
    deleteMachine${tenantTypeFirstUpperCase}(id:$id){
      ok
      message
    }
  }
}
`


const QUERY_CUSTOMER=gql`
subscription GetCustomer($areeId: [bigint!]) {
  ${tenant}_machines_machine${tenantType}(where: {machines_machineareas: {area_id: {_in: $areeId}}, is_machine: {_eq: false}}, order_by: {name: asc}) {
    id
    name
    chassis_number
    notes
    external_erp_code
    machines_machineareas{
      id
      area_area${tenantType}{
        id
        name
      }
    }
  }
}
`


export {UPSERT_CUSTOMER,DELETE_CUSTOMER, QUERY_CUSTOMER};