import React from "react";
import { tenant,tenantType } from "../tenant";
import moment from "moment";
import { useHistory } from "react-router-dom";
import {
    Day,
    Week,
    Month,
    ScheduleComponent,
    ViewsDirective,
    ViewDirective,
    ResourceDirective,
    ResourcesDirective,
    Inject,
    Resize,
    DragAndDrop,
    TimelineViews
} from '@syncfusion/ej2-react-schedule';
import { registerLicense } from '@syncfusion/ej2-base';
import 'moment-timezone'; // Importa Moment Timezone
import 'moment/locale/it'; // Importa le impostazioni di localizzazione italiana per Moment.js
import it from '../../it.json'; // Carica il file di traduzione
import { loadCldr, setCulture, setCurrencyCode ,L10n} from '@syncfusion/ej2-base';
import "../../App.css"
import "../../css/index.css"

export default function CustomCalendar({resources,events,setDateFilter,updateActivity,modifyMode}){


    registerLicense(process.env.REACT_APP_CALENDAR_KEY);
    const history=useHistory();
    const [view,setView]=React.useState(sessionStorage.getItem("selectedView") && sessionStorage.getItem("selectedView")!=="undefined"?sessionStorage.getItem("selectedView"):"Day")
    const [activity,setActivity]=React.useState([]);
    const [adaptiveUI,setAdaptiveUI]=React.useState(false);
    require('moment/locale/it');
    let [selectedDate,setSelectedDate]=React.useState(sessionStorage.getItem("selectedDate") && sessionStorage.getItem("selectedDate")!=="undefined"?
        moment(sessionStorage.getItem("selectedDate")).toDate()
        :
        moment(new Date()).toDate()
    )

    React.useEffect(()=>{
        setDateFilter({start:
                sessionStorage.getItem("selectedDate") && sessionStorage.getItem("selectedDate")!=="undefined"?
                moment(sessionStorage.getItem("selectedDate")).add("days",-1).format()
                :
                moment(new Date()).startOf('day').add("days",-1).format(),
            end:
        sessionStorage.getItem("selectedDate") && sessionStorage.getItem("selectedDate")!=="undefined"?
        moment(sessionStorage.getItem("selectedDate"))
            .add("days",1)
            .format()
        :
        moment(new Date()).startOf('day').add("days",1).format()})
    },[selectedDate])


    loadCldr(
      require('cldr-data/main/it/ca-gregorian.json'),
      require('cldr-data/main/it/numbers.json'),
      require('cldr-data/supplemental/numberingSystems.json'),
      require('cldr-data/main/it/timeZoneNames.json')
    );

    moment.locale('it'); // Imposta la localizzazione italiana
    moment.tz.setDefault(); // Imposta il fuso orario locale come predefinito
    setCulture('it'); // Imposta la cultura italiana
    setCurrencyCode('EUR'); // Imposta il codice valuta

    L10n.load({
      'it': it // Carica le traduzioni italiane
    });

    React.useEffect(()=>{
      if(events){
        const res=events[`${tenant}_activity_activity${tenantType}`];
        if(res){
            const activityApp=[];
            for(let i=0;i<res.length;i++){
              const item=res[i];
              activityApp.push(
                {
                  Id: item.id,
                  Subject: item.notes? `${item[`jobs_job${tenantType}`] && item[`jobs_job${tenantType}`].name} ${item[`area_area${tenantType}`] && item[`area_area${tenantType}`].name} ${item.notes}`:`${item[`jobs_job${tenantType}`] && item[`jobs_job${tenantType}`].name}  ${item[`area_area${tenantType}`] && item[`area_area${tenantType}`].name}`,
                  StartTime: new Date(item.start),
                  EndTime: new Date(item.end),
                  UserId: item.users_usergolf.id,
                  StartTimezone: 'Europe/Rome',
                  EndTimezone: 'Europe/Rome',
                  ...item
                },
              )
            }
          setActivity(activityApp);
        }
      }
    },[events])

    React.useEffect(()=>{
        if(resources.length>8 && view==="Day"){
            setAdaptiveUI(true);
        }else{
            setAdaptiveUI(false);
        }
    },[resources])

    function dateRange(event){
      if(event.action==="view"){ //Cambio view
        if(event.currentView==="Week" || event.currentView==="Day" ||  event.currentView==="TimelineDay"){
          let start=moment(event.currentDate).startOf('day').add("days",-3).format();
          let end=moment(event.previousDate).startOf('day').add("days",8).format();
          sessionStorage.setItem("selectedDate",moment(event.currentDate).startOf("day").format())
          setView(event.currentView);
          sessionStorage.setItem("selectedView",event.currentView)
          setDateFilter({start:start,end:end})
        }else if(event.currentView==="Month"){
          let start=moment(event.currentDate).startOf('month').startOf("day").format();
          let end=moment(event.previousDate).endOf('month').endOf("day").format();
          sessionStorage.setItem("selectedDate",moment(event.currentDate).startOf("day").format())
          setView(event.currentView);
          sessionStorage.setItem("selectedView",event.currentView)
          setDateFilter({start:start,end:end})
        }
      }else if(event.action==="date" ){ ////Cambio data
        if(view==="Week" || view==="Day" || view==="TimelineDay"){
           if(moment(event.currentDate).isBefore(event.previousDate)){
             let start=moment(event.currentDate).startOf('day').add("days",-7).format();
             let end=moment(event.previousDate).startOf('day').add("days",8).format();
               setDateFilter({start:start,end:end})
           }else if(moment(event.currentDate).isAfter(event.previousDate)){
               let start=moment(event.previousDate).startOf('day').add("days",-7).format();
               let end=moment(event.currentDate).startOf('day').add("days",8).format();
                setDateFilter({start:start,end:end})
           }
          sessionStorage.setItem("selectedDate",moment(event.currentDate).startOf("day").format())
        //  setView(event.currentView);

        }else if(view==="Month"){
          let start=moment(event.currentDate).startOf('month').startOf("day").format();
          let end=moment(event.currentDate).endOf('month').endOf("day").format();
          console.log("END",end);
          sessionStorage.setItem("selectedDate",moment(event.currentDate).add("days",1).startOf("day").format())
          setDateFilter({start:start,end:end})
         // setView(event.currentView);
      }
    } 
  }

    function actionBegin(event){
      if(event){
        if(event.requestType==="eventChange"){ //DRAG AND DROP E CAMBIO ORARIO EVENTO
             const data=event.data;
            const updatedActivity={variables:{id:data.Id,data:moment(data.StartTime).format("YYYY/MM/DD"),start:data.StartTime,end:data.EndTime,user:data.UserId,area:data.area_areagolf.id,job:data.jobs_jobgolf.id,created_by_user:data.created_by_user,machine:data.machines_machinegolf.id,approved:data.approved}}
            updateActivity(updatedActivity)
            .then((value)=>{
              console.log(value);
            })
            .catch((error)=>{
              console.error(error);
            })
          }
      }else{
          alert("Abilita la modalità modifica")
      }
    }

    function popupOpen(event){
      if(event){
        if(event.type==="Editor"){
          history.push({
            pathname: '/attivitaDettaglio',
            state: {
              id:event.data.Id
            }
          })
        }else if(event.type==="QuickInfo" && !event.data.Id){ //Click su calendario ma non su una attività
          history.push({
            pathname: '/attivitaDettaglio',
            state: {
              userId:event.data.UserId,
              start:event.data.StartTime,
              end:event.data.EndTime
            }
          })
        }else if((event.type==="QuickInfo" || event.type==="ViewEventInfo") && event.data.Id){ //Click su una attività, mando alla traccia
            history.push({
            pathname: '/attivitaDettaglio',
            state: {
              userId:event.data.UserId,
              start:event.data.StartTime,
              end:event.data.EndTime,
              activityData:event.data,
            }
          })
        }
      }
    }
    
    function onEventRendered(args) {
      const data=args.data;
      if(data.approved==="MANUALE" || data.approved==="manuale"){
        args.element.style.backgroundColor = "#3F51B5";
      }else if(data.approved==="SI" || data.approved==="si"){
        args.element.style.backgroundColor = "#4CAF50";
      }else if(data.approved==="PARTITA" || data.approved==="partita"){
        args.element.style.backgroundColor = "#FFC107";
      }else if(data.approved==="CONFERMATA" ||  data.approved==="confermata") {
          args.element.style.backgroundColor = "#fe7652";
      }else if(data.approved==="ERRATA" || data.approved==="errata"){
          args.element.style.backgroundColor = "#c80100";
      }else{
           args.element.style.backgroundColor = "#607D8B";
      }
    }


    return (

        <ScheduleComponent
          cssClass='schedule-group' width='100%' height='100%'
          cellWidth='100px'
          selectedDate={selectedDate}
          eventSettings={ { dataSource: activity }}
          group={{ resources: ['Users'] }}
          resources
          timeFormat="HH:mm"
          actionBegin={actionBegin}
          popupOpen={popupOpen}
          eventRendered={onEventRendered}
          navigating={dateRange}
          currentView={view}
          enableAdaptiveUI={false}
            >
          <ResourcesDirective >
              <ResourceDirective
                  cssClass="schedule-group"
                field='UserId'
                title='User Name'
                name='Users'
                allowMultiple={true}
                dataSource={resources}
                textField='text'
                subtext="subtext"
                idField='id'
                colorField="color"
                  width="100%"
                height="650px"
                >
              </ResourceDirective>
          </ResourcesDirective>
          <ViewsDirective>
                <ViewDirective option='TimelineDay'/>
                <ViewDirective option='Day'/>
                <ViewDirective option='Week'/>
                <ViewDirective option='Month'/>
          </ViewsDirective>
          <Inject services={modifyMode?[TimelineViews,Day,Week, Month, Resize, DragAndDrop]:[TimelineViews,Day,Week, Month]}/>
        </ScheduleComponent>
      );

}
