import React  from "react";
import { Box, Heading, Form, FormField, TextInput, Button,Layer } from "grommet";
import { FormClose } from "grommet-icons";
import { useMutation } from '@apollo/client';
import { ADD_CANEDGE,UPDATE_CANEDGE } from "./canedgeGQL";
import { extractIdFormData } from "../../../utilis/utils";

export default function AddCan(props) {

  const [formData,setFormData]=React.useState({}); //impostati di default
  const [addCan]=useMutation(ADD_CANEDGE);
  const [updateCan]=useMutation(UPDATE_CANEDGE);

  React.useEffect(()=>{
    if(props.can){
      setFormData(props.can)
    }
  },[])

  const onChangeTextInput=(event)=>{
    setFormData({...formData,[event.target.name]:event.target.value})
  }

  function addCanedge(){
    let obj=extractIdFormData(formData,true);
    delete obj.variables._Typename;
    addCan(obj).then((value)=>{
      alert("Canedge aggiunto correttamente");
       onEscLayer();
    })
  }

  function updateCanedge(){
    let obj=extractIdFormData(formData,true);
    delete obj.variables._Typename;
    updateCan(obj).then((value)=>{
        alert("Canedge aggiornato correttamente");
        onEscLayer();
    })
  }

  function onEscLayer(){
    if(props.onEscLayer){
        props.onEscLayer();
    }
    setFormData({});
  }

  return (
    <Layer full={window.screen.width<1100?true:false} onClickOutside={onEscLayer} onEsc={onEscLayer}>
      <Box height="100%" round="medium" pad="15px" background="#ecedf3">
        <Button  alignSelf="end" icon={<FormClose color="grey" size="35px" onClick={onEscLayer}/>}></Button>
          <Box round pad="medium" direction="column" background="white" align="center" overflow="auto">
            <Heading level="2" margin="none" size="small" >
              <Box direction="row" gap="small">
                {!props.can?"Inserisci Canedge":"Aggiorna Canedge"}
              </Box>
            </Heading>
            <Box fill gap="medium" pad={{ vertical: "small" }} align="center">
            <Form>
                <Box pad="xsmall">
                  <FormField required label="Can ID">
                    <TextInput
                      name="can_number"
                      value={ formData?.can_number || ""}
                      placeholder=""
                      onChange={e => onChangeTextInput(e)}
                    />
                  </FormField>
                </Box>
            </Form>
            <Box direction="row" gap="medium" alignSelf="end">
                <Button label={!props.can?"Aggiungi":"Aggiorna"} onClick={!props.can?addCanedge:updateCanedge} style={{width:'138px',height:'34px',fontSize:'15px',background:'#0C6B13',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}/>
            </Box>
          </Box>
        </Box>
      </Box>
    </Layer>
  )
}
