import React, { useState } from "react";
import {
  Box,
  Card,
  CardBody,
  Button
} from "grommet";
import TextField from '@mui/material/TextField';
import { useLazyQuery,useMutation } from '@apollo/client';
import { tenant,tenantType } from "../../tenant";
import { QUERY_GET_CLIENTS } from "../../activity/AutomaticheGQL";
import { QUERY_GET_CLIENT_PROFILE, UPDATE_PROFILE } from "./MachineProfileGQL";
import ProfileTab from "./ProfileTab";

export default function MachineProfile(props) {

    const [clientList, setClientList] = useState([]);
    const [clientSel, setClientSel] = useState(null);
    const [showedClientList, setShowedClientList] = useState([]);
    const [textSearch, setTextSearch] = useState("");
    const [profile, setProfile] = useState(null);
    const [valueTab, setValueTab] = React.useState(0);
    const [showSave, setShowSave] = useState(false);
    const [modifiedKeys, setModifiedKeys] = React.useState([]);

    const [updateProfile]=useMutation(UPDATE_PROFILE);

    const handleChange = (event, newValue) => {
      setValueTab(newValue);
    };

    const [ queryGetProfile
    ] = useLazyQuery(QUERY_GET_CLIENT_PROFILE, {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        if(data){
          const dataApp=data[`${tenant}_machines_machineprofile`];
          if(dataApp){
            console.log("GET PROFILE",dataApp)
            setProfile(dataApp[0])
          }
        }
      },
      notifyOnNetworkStatusChange: true, // did the work
    });

    const [ queryGetClients
    ] = useLazyQuery(QUERY_GET_CLIENTS, {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        if(data){
          const dataApp=data[`${tenant}_machines_machine${tenantType}`];
          if(dataApp){
            console.log("CLIENTS",dataApp)
            setClientList(dataApp)
          }
        }
      },
      notifyOnNetworkStatusChange: true, // did the work
    });

    React.useEffect(()=>{
      queryGetClients()
    },[])

    React.useEffect(()=>{
      setShowedClientList(clientList)
    },[clientList])

    React.useEffect(()=>{
        console.log("PROFILE",profile)
        console.log(Object.keys({}).length === 0)
    },[profile])

    React.useEffect(()=>{
      if(textSearch!==""){
        setShowedClientList(clientList.filter((client)=>{
          return client.name.toLowerCase().includes(textSearch.toLowerCase())
        }))
      }else{
        setShowedClientList(clientList)
      }
    },[textSearch])

    function handleClickCustomer(id){
      if(profile && showSave)
        handleSave(true);
      queryGetProfile({variables:{machine_id:id}})
      setClientSel(id)
      
    }

    function handleSave(changingCustomer) {
      let data={
          profile_id:profile.id,
          profile: profile.profile,
          status:profile.status
      }

      console.log("DATA",data)

      updateProfile({variables:data}).then((value)=>{
        alert("Attività aggiornata correttamente");
        if(!changingCustomer)
          queryGetProfile({variables:{machine_id:profile.machine_id}}) //Se sto cambiando cliente non prendo il profilo con il vecchio ID ( tanto questa operazione la faccio già sull'onclick)

        setModifiedKeys([]);
        setShowSave(false);
        
      })
      .catch((error)=>{
        console.error(error);
        alert("Errore durante l'operazione");
      })
    }
    

    return (
        <center>
            <Box width="50%" style={{ marginTop: "20px" }}>
                <TextField id="standard-basic" label="Ricerca per nome" variant="standard" onChange={(e)=>{setTextSearch(e.target.value)}} />
            </Box>
            
            <Box direction="row" style={{ 
                paddingTop: "10px", 
                paddingBottom: "10px",  
                backgroundColor: 'transparent', 
                margin: "40px", 
                height: "120px", 
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.25)", 
                overflowX: "scroll", // Cambiato overflowX da "scroll" a "auto"
                width: "900px"
            }}>
                
                {showedClientList.length>0?
                    showedClientList.map((client)=>{
                        return(
                            <Card 
                            style={{ 
                              height: "70px",
                              minWidth: "220px", 
                              margin: "0px!important", 
                              marginLeft: "30px" 
                            }} 
                            background={clientSel===client.id?"light-5":"light-1"}
                            onClick={() => handleClickCustomer(client.id)}>
                                <CardBody pad={"small"} style={{}} >
                                    {client.name}
                                </CardBody>
                            </Card>
                        )
                    })
                    :
                    null
                }               
            </Box>
            {profile !== undefined && profile !== null && Object.keys(profile).length !== 0 && (
                <>

                  { showSave && <Button primary label="Salva" style={{marginTop: "20px", marginBottom: "20px"}} onClick={() => handleSave(false)} />}
                  <ProfileTab 
                    profile={profile} 
                    setProfile={setProfile}
                    setShowSave={setShowSave} 
                    valueTab={valueTab} 
                    handleChange={handleChange}
                    setModifiedKeys={setModifiedKeys}
                    modifiedKeys={modifiedKeys} 
                  />
                </>
            )}

        </center>
    )
}