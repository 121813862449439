import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export const CustomRightClickMenu=(props)=>{
    return(
      <Menu
          id="basic-menu"
          anchorEl={props.anchorEl}
          open={props.open}
          onClose={()=>props.setAnchorEl(null)}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
      >
        { props.menuItems.map((item, index)=>
            <MenuItem key={index} onClick={item.onClick}>{item.name}</MenuItem>
        )}
      </Menu>
    )
  }
