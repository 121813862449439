import React, { useState, useEffect } from "react";
import {DataGrid} from "@mui/x-data-grid";
import {useLazyQuery, useMutation} from "@apollo/client";
import {
    DELETE_MAINTENANCE, DELETE_RELIEF_HOUR,
    QUERY_MACHINE,
    QUERY_RELIEF_HOURS
} from "../registryComponent/maintenance/maintenanceGQL.jsx";
import {tenant, tenantType} from "../tenant.js";
import {Box, Text} from "grommet";
import {Button as ButtonMui} from "@mui/material";
import moment from "moment/moment.js";

function ReliefHoursList(props){

    const [ reliefHours,setReliefHours] = React.useState([]);
    const [deleteReliefHour] = useMutation(DELETE_RELIEF_HOUR)
    const [ queryReliefHours
      ] = useLazyQuery(QUERY_RELIEF_HOURS, {
        fetchPolicy: "no-cache",
        onCompleted: (data) => {
          const reliefHoursData=data[`${tenant}_machines_reliefhour`];
          if(reliefHoursData){
              const reliefHoursApp=[];
              for (let i=0;i<reliefHoursData.length;i++){ //Devo fare il for perchè la muiDataGrid non trova i subfields
                  reliefHoursApp.push({
                                    id:reliefHoursData[i]["id"],
                                    machine_name:reliefHoursData[i]["machines_machine"+tenantType]?.name,
                                    user:reliefHoursData[i]["users_user"+tenantType]?.name +" "+reliefHoursData[i]["users_user"+tenantType]?.surname+" "+reliefHoursData[i]["users_user"+tenantType]?.registration_number,
                                    datetime:moment(reliefHoursData[i]["datetime"]).format("DD/MM/yyyy"),
                                    machine_hour:reliefHoursData[i]["machine_hour"]
                  })
              }
              if(reliefHoursApp){
                  setReliefHours(reliefHoursApp);
              }
          }
        },
        notifyOnNetworkStatusChange: true, // did the work
      });

      React.useEffect(()=>{
        queryReliefHours({variables:{areeId:props.aree.length>0? props.aree : JSON.parse(sessionStorage.getItem("areeId"))}});
      },[props.aree])


  const columnsExecutedMaintenance = [
        { field: 'machine_name', headerName: 'Macchinario', width: 300 ,},
        { field: 'user', headerName: 'Registrato da', width: 300 },
        { field: 'datetime', headerName: 'Data', width: 300 ,},
        { field: 'machine_hour', headerName: 'Ore Macchina', width: 300 },
        {
        field: 'Modifica',
        headerName: 'Modifica',
        width: 400,
        renderCell: (cellValues) => {
          return (
            <Box direction="row" gap="small">
              <ButtonMui
                variant="contained"
                color="error"
                onClick={(event) => {
                  let res=window.confirm("Sei sicuro di voler eliminare il rilievo ore?");
                  if(res){
                    deleteReliefHour({variables:{ id:cellValues.row.id}})
                        .then((val)=>{
                            queryReliefHours({variables:{areeId:props.aree.length>0? props.aree : JSON.parse(sessionStorage.getItem("areeId"))}});
                        })
                        .catch((err)=>{
                            alert("Errore nella cancellazione del rilievo ore")
                        })
                  }
                }}
              >
                Cancella
              </ButtonMui>
            </Box>
          );},
        }
    ];

    return(
        <Box pad={"small"} overflow="auto"  height="97vh" width="100%" alignSelf='center' style={{marginTop:'40px'}}>
        <div style={{ height: '75vh', width: '100%' }}>
          <DataGrid
            rows={reliefHours || []}
            columns={columnsExecutedMaintenance}
          />
        </div>
      </Box>
    )
}

export default ReliefHoursList;