import { gql } from '@apollo/client';
import { tenant,tenantType } from '../tenant';


const USER_NOTIFICATION=gql`
query GetUserNotification($id: bigint!) {
  ${tenant}_users_usernotification(where: {user_id: {_eq: $id}, users_notification: {read: {_eq: false}}}) {
    users_notification {
      body
      data
      id
      read
      title
    }
  }
}
`

const DELETE_NOTIFICATION=gql`
mutation readNotification($id: bigint!,$read:Boolean) {
	update_${tenant}_users_notification_by_pk(pk_columns:{id:$id},_set:{read:$read}){
    id
  }
}

`

export {USER_NOTIFICATION,DELETE_NOTIFICATION}