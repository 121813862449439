import React from "react";
import {Box, Heading, Form, FormField, TextInput, Button, Select} from "grommet";
import { extractIdFormData } from "../../../utilis/utils";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabMaintenance from "./TabMaintenance";
import { useLazyQuery, useMutation } from '@apollo/client';
import {
    QUERY_GET_INTERVENTION_TYPE,
    QUERY_MACHINE,
    QUERY_MAINTENANCE_BY_MACHINE_BY_INTERVENTION_TYPE
} from "./maintenanceGQL";
import {tenant, tenantType} from "../../tenant.js";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";


export default function AddMaintenance(props) {

  const [value, setValue] = React.useState(null);
  const [maintenanceTypeNameList,setMaintenanceTypeNameList]=React.useState([]);
  const [maintenanceList,setMaintenanceList]=React.useState([]);
  const [machineList,setMachineList]=React.useState([]);
  const [selectedMachine,setSelectedMachine]=React.useState(null);
  const [ queryMaintenanceSchedule
  ] = useLazyQuery(QUERY_MAINTENANCE_BY_MACHINE_BY_INTERVENTION_TYPE, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
        const maintenance=data[`${tenant}_machines_machine${tenantType}_by_pk`];
        setMaintenanceList(maintenance.machines_machinemaintenanceschedules);
      },
    notifyOnNetworkStatusChange: true, // did the work
  });

  const [ queryInterventionType
  ] = useLazyQuery(QUERY_GET_INTERVENTION_TYPE, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => { 
      const interventionTypeList=data["machineType_interventiontype"];
      if(interventionTypeList){
        setMaintenanceTypeNameList(interventionTypeList);
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });

    const [ queryGetMachine
      ] = useLazyQuery(QUERY_MACHINE, {
        fetchPolicy: "no-cache",
        onCompleted: (data) => {
          const machines=data[`${tenant}_machines_machinearea`].map((machine)=>machine[`machines_machine${tenantType}`]);
          const maintenance=data[`${tenant}_machines_machinearea`].map((machine)=>machine[`machines_machine${tenantType}`]["machines_maintenancegolves"]);
          if(machines){
              setMachineList(machines.sort((a,b)=>a.name.localeCompare(b.name)))
          }
          if(maintenance){
           //setEseguite(unisciListe(maintenance));
          }
        },
        notifyOnNetworkStatusChange: true, // did the work
      });



  React.useEffect(()=>{
    if(props.machineType){
      //setMachineTypeSel(props.machineType);
     // setFormData(props.machineType)

    }
     queryGetMachine({variables:{area:JSON.parse(sessionStorage.getItem("areeId"))}});
     queryInterventionType();
  },[])

  React.useEffect(()=>{
    if(value && selectedMachine?.id){
        queryMaintenanceSchedule({variables:{idMachine:selectedMachine?.id,idInterventionType:value}})
    }
  },[value,selectedMachine])


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box width="90vw" round pad="medium" direction="column" background="white" align="center" overflow="auto">
      <Heading level="2" margin="none" size="small">
        <Box direction="row" gap="large">
          {!props.lavoro?"Inserisci Manutenzioni":"Aggiorna Manutenzioni"}
        </Box>
      </Heading>
      <Box fill gap="medium" pad={{ vertical: "small" }}>
          <Form>
              <Box pad="xsmall">
                <FormField required label="Seleziona macchinario">
                  <Select
                    name="type_name"
                    labelKey={(option)=>option?.name}
                    options={machineList || []}
                    onChange={({option}) => setSelectedMachine(option)}
                  />
                </FormField>
              </Box>
          </Form>
          {
              selectedMachine &&
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="scrollable" scrollButtons="auto">
                  {
                    maintenanceTypeNameList.map((maintenanceType,index)=>{
                      return <Tab value={maintenanceType.id} key={maintenanceType.id} label={maintenanceType.code}/>
                    })
                  }
              </Tabs>
          }
          {
              selectedMachine && value &&
               <TabMaintenance rows={[...maintenanceList?.sort((a,b)=>a?.interval - b?.interval)]} selectedMachine={selectedMachine} interventiontType={maintenanceTypeNameList?.find((el)=>el?.id===value)} queryMaintenanceSchedule={queryMaintenanceSchedule}/>
          }
      </Box>
    </Box>
  )
}