import {useMutation, useSubscription} from "@apollo/client";
import {QUERY_MACHINE} from "../registryComponentsGQL.js";
import {Box, Text} from "grommet";
import {DataGrid} from "@mui/x-data-grid";
import {tenant, tenantType} from "../../tenant.js";
import {DELETE_MACHINE} from "./machineGQL.js";
import {Button as ButtonMui} from "@mui/material";
import QRCode_Gen from "qrcode.react";

function Machine(props){

    const columnsMac = [
    { field: 'name', headerName: 'Nome Macchina', width: 300 },
    { field: 'chassis_number', headerName: 'Telaio', width: 150 },
    { field: 'machines_canmachines', headerName: 'CanId', width: 120 ,
      renderCell: (cellValues) => {
        return (

            cellValues.row.machines_canmachines[cellValues.row.machines_canmachines.length-1]?
            <Text>{cellValues.row.machines_canmachines[cellValues.row.machines_canmachines.length-1].machines_can.can_number}</Text>
            :
            <Text></Text>


        );}, },
    {
      field: 'brand',
      headerName: 'Marca',
      width: 170,
    },
    {
      field: 'hourly_cost',
      headerName: 'Costo orario',
      width: 170,
    },
    {
      field: 'hourly_rental_cost',
      headerName: 'Costo orario noleggio',
      width: 170,
    },
  {
    field: 'Download',
    headerName: 'Qr Code Download',
    width: 170,
    renderCell: (cellValues) => {
      return (
        <ButtonMui
          variant="contained"
          color="success"
          onClick={(event) => {
            props.downloadQR(cellValues.row.name);
          }}
        >
          <QRCode_Gen
				  	style={{display: 'none'}}
				    id={cellValues.row.name}
				    value={cellValues.row.chassis_number}
				    size={290}
				    level={"H"}
				    includeMargin={true}
				  />
          Download
        </ButtonMui>
      );},
   },
   {
    field: 'Modifica',
    headerName: 'Modifica',
    width: 120,
    renderCell: (cellValues) => {
      return (
            props?.modifyRegistry &&
        <ButtonMui
          variant="contained"
          color="primary"
          onClick={(event) => {
            console.log(cellValues.row);
            if(cellValues.row.machines_canmachines.length-1>=0){
               props.setMacSel({...cellValues.row,canSel:cellValues.row.machines_canmachines[cellValues.row.machines_canmachines.length-1].machines_can})
            }else{
              props.setMacSel({...cellValues.row,canSel:""})
            }
            props.setModifyMacchinari(true);
          }}
        >
          Modifica
        </ButtonMui>
      );},
   },
   {
    field: 'Cancella',
    headerName: 'Cancella',
    width: 150,
    renderCell: (cellValues) => {
      return (
             props?.modifyRegistry &&
        <ButtonMui
          variant="contained"
          color="error"
          onClick={(event) => {
            let res=window.confirm("Sei sicuro di voler eliminare il macchinario?");
            if(res){
              deleteMachine({variables:{ id:cellValues.row.id}})
            }
          }}
        >
          Cancella
        </ButtonMui>
      );},
   },

  ];

    const [deleteMachine]=useMutation(DELETE_MACHINE);

    const { data, loading } = useSubscription(
      QUERY_MACHINE,{variables:{areeId:props.aree.length>0? props.aree : JSON.parse(sessionStorage.getItem("areeId"))}}
    );

    return (
      <Box overflow="auto"  height="100%" width="100%" alignSelf='center' style={{marginTop:'40px'}}>
        <div style={{ height: '75vh', width: '100%' }}>
          <DataGrid
            rows={data && data[`${tenant}_machines_machine${tenantType}`] || []}
            columns={columnsMac}
          />
        </div>
      </Box>
    )
}

export default Machine;

