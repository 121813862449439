import React from "react";
import {Box, Heading, Form, FormField, Select, TextInput, Button, DateInput} from "grommet";
import { useLazyQuery,useMutation } from '@apollo/client';
import {QUERY_MACHINE, INTERVENTION_TYPE,ADD_MAINTENANCE} from "./maintenanceGQL";
import { tenant,tenantType  } from "../../tenant";
import { extractIdFormData } from "../../../utilis/utils";

export default function AddEse(props) {

  const [machines,setMachines]=React.useState([]);
  const [interventionTypes,setInterventionTypes]=React.useState([]);
  const [formData,setFormData]=React.useState({});
  const [addMaintenance]=useMutation(ADD_MAINTENANCE);

  const [ queryGetMachine
  ] = useLazyQuery(QUERY_MACHINE, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => { 
      const machines=data[`${tenant}_machines_machinearea`].map((machine)=>machine[`machines_machine${tenantType}`])
      if(machines){
        const sortedMachines = [...machines].sort((a, b) => a.name.localeCompare(b.name));
        setMachines(sortedMachines);
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });

  const [ queryGetInterventionType
  ] = useLazyQuery(INTERVENTION_TYPE, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const interventionTypes=data[`machineType_interventiontype`];
      if(interventionTypes){
        setInterventionTypes(interventionTypes);
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });

  React.useEffect(()=>{
     if(props.machine){
      setFormData({...formData,machine:props.machine})
    }
    queryGetMachine({variables:{area:JSON.parse(sessionStorage.getItem("areeId"))}})
  },[])

  React.useEffect(()=>{
    if( formData?.machine?.id){
      queryGetInterventionType();
    }
  },[formData.machine])


  function onEscLayer(){
    if(props.onEscLayer){
        props.onEscLayer();
    }
    setFormData({});
  }

  const reset = () => {
    setFormData({});
  }

  const onChangeSelect=(event)=>{
    setFormData({...formData,[event.target.name]:event.value})
  }

  function onAddMaintenance(){
    addMaintenance(extractIdFormData(formData,false))
    .then((value)=>{
      alert("Manutenzione aggiunta");
      onEscLayer();
      queryGetMachine({variables:{area:JSON.parse(sessionStorage.getItem("areeId"))}});
      props.reloadMaintenance();
    })
    .catch((error)=>{alert("Errore durante l'operazione");console.log(error)});
  }

  return (
    <Box round pad="medium" direction="column" background="white" overflow="auto">
      <Heading level="2" margin="none" size="small">
        Inserisci intervento eseguito
      </Heading>
      <Box fill gap="medium" pad={{ vertical: "small" }}>
        <Form>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <FormField required label="Macchinario">
              <Select
                name="machine"
                multiple={false}
                options={machines}
                value={ formData?.machine }
                onChange={(option ) =>onChangeSelect(option)}
                labelKey={(option)=>option && `${option.name} ${option.chassis_number}`}
                closeOnChange={true}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Codice Intervento">
              <Select
                name="typeIntervention"
                multiple={false}
                options={interventionTypes}
                value={ formData?.typeIntervention }
                onChange={(option ) =>onChangeSelect(option)}
                labelKey={(option)=>option && `${option.code}`}
                closeOnChange={true}
              />
            </FormField>
          </Box>
        </Box>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <FormField required label="Data del Rilievo">
               <DateInput
                format="dd/mm/yyyy"
                value={formData?.datetime}
                onChange={({ value }) => {setFormData({...formData, datetime: value})}}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Inserisci ore rilevate">
              <TextInput
                type="number"
                name="machineHour"
                placeholder="es. 20"
                value={ formData?.machineHour}
                onChange={e =>setFormData({...formData,machineHour:parseInt(e.target.value)})}
              />
            </FormField>
          </Box>
        </Box>
      </Form>
      <Box direction="row" gap="medium" alignSelf="end">
          <Button label="Aggiungi" onClick={onAddMaintenance} style={{width:'138px',height:'34px',fontSize:'15px',background:'#397aa3',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}/>
          <Button type="reset" onClick={reset} label="Cancella" style={{marginLeft:'5px',width:'138px',height:'34px',fontSize:'15px',background:'#c51d34',color:'white',fontWeight:'bold',textTransform: 'uppercase', borderColor:'#c51d34'}}/>
      </Box>
      </Box>
    </Box>
  )
}