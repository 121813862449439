import React, { useState, useEffect } from "react";
import { Map, GoogleApiWrapper, Polygon } from 'google-maps-react';
import { Box } from "grommet";

const App = (props) => {

	  const [coordinate, setCoords] = useState([]);
	  const [coordsRev, setCoordsRev]=useState([]);
	  const [polygonsMap,setPolygonsMap]=React.useState([]);
	  const [home,setHome]=React.useState();

		/**
		 * Funzione che distingue due casi:
		 * Loggato come admin generale: rileva il tracciamento per la singola Card, quindi in base ai props passati (nome buca, tipologia,cantiere e zona).
		 * Loggato come admin cantiere/zona: scorre l'array di tracciati passato per props (già filtrato dal componente BucheDet.js)
		 */
		
		function getCoords() {
			const coordsApp=[];
			for(let i=0;i<props.coords.length;i++){
				for(let z=0;z<props.coords[i].length;z++){
					const polygonPath=props.coords[i][z];
					const path=[];
					for(let j=0;j<polygonPath.length;j++){
						if(polygonPath[j][0]>30){
							path.push({lat:polygonPath[j][0],lng:polygonPath[j][1]})
						}else{
							path.push({lat:polygonPath[j][1],lng:polygonPath[j][0]})
						}

					}
					coordsApp.push(path);
				}
			}
			setCoords(coordsApp)
			if(props.home && props.home[0] && props.home[1]){		
				setHome({lat:props.home[0],lng:props.home[1]})
			}else if(coordsApp[0] && coordsApp[0][0]){
				setHome(coordsApp[0][0])
			}else{
				setHome({lat:0,lng:0})
			}
			/*
			if (props.admin) {

			    coords.where('Tracciato', '==', 'si').where('Buca', '==', props.foo).where('Tipologia', '==', props.zone).where('Cantiere', '==', props.cantiere).where('ZonaFilter', '==', props.filter).onSnapshot((querySnapshot) => {
			        const items = [];
			        const coord = [];
			        querySnapshot.forEach((doc) => {
			          items.push( { ... doc.data(), id: doc.id } );
			        });		        
			        setColor(items[0].Colore)
			        for (var i = 0; i < items[0].Coords.length; i++)
			        {	
				        	items.map(coordinate => (
								//Modifica dev-mattia-mora
								coord.push({ percorso: coordinate.Coords[i].percorso, Index: i, colore: coordinate.Colore, label: coordinate.Cantiere +"-"+ coordinate.ZonaFilter, buca: coordinate.Coords[i].label, id: coordinate.id, tipologia: coordinate.Tipologia})
					     		
					     	));
			        }
			     	setCoords(coord); //ARRAY FINALE PER ADMIN GENERALE
			    });

			} else {

				//Array supporto logica esclusione
				const bucheArr = [];
				const greenArr = [];
				const collarArr = [];
				const apronArr = [];
				const teeArr = [];
				const bunkerArr = [];
				const roughArr = [];
				const fairwayArr = [];
				const altroArr = [];
				const campoPraticaArr=[];
			
				const coord = []; //Array contente tutti i dati dei poligoni
						props.buche.forEach(buca => {
							if (buca.Coords.length === undefined) {
								bucheArr.push(buca.Coords.label);
								coord.push({ percorso: buca.Coords.percorso, Index: 0, colore: buca.Colore, label: buca.Cantiere +"-"+ buca.ZonaFilter, buca: buca.Coords.label, id: buca.id, tipologia: buca.Tipologia});
							} else {
								for (var j = 0; j < buca.Coords.length; j++)
								{	
										bucheArr.push(buca.Coords[j].label);
										coord.push({ percorso: buca.Coords[j].percorso, Index: j, colore: buca.Colore, label: buca.Cantiere +"-"+ buca.ZonaFilter, buca: buca.Coords[j].label, id: buca.id, tipologia: buca.Tipologia});
								}
							}
						})

				let uniqueBucheArr = [...new Set(bucheArr)]; //Array contente le label

				uniqueBucheArr.forEach(buca => { //Creo gli array di poligoni suddivisi in base alla tipologia
					for (var j = 0; j < coord.length; j++)
					{
						if (coord[j].buca == buca && coord[j].tipologia == "Green")
						{
							greenArr.push({ percorso: coord[j].percorso, buca: coord[j].buca, tipologia: coord[j].tipologia ,color:coord[j].colore});
						} else if (coord[j].buca == buca && coord[j].tipologia == "Fairway")
						{
							fairwayArr.push({ percorso: coord[j].percorso, buca: coord[j].buca, tipologia: coord[j].tipologia,color:coord[j].colore  });
						} else if (coord[j].buca == buca && coord[j].tipologia == "Rough")
						{
							roughArr.push({ percorso: coord[j].percorso, buca: coord[j].buca, tipologia: coord[j].tipologia,color:coord[j].colore });
						} else if (coord[j].buca == buca && coord[j].tipologia == "Tee")
						{
							teeArr.push({ percorso: coord[j].percorso, buca: coord[j].buca, tipologia: coord[j].tipologia ,color:coord[j].colore});
						} else if (coord[j].buca == buca && coord[j].tipologia == "Aprons")
						{
							apronArr.push({ percorso: coord[j].percorso, buca: coord[j].buca, tipologia: coord[j].tipologia,color:coord[j].colore });
						} else if (coord[j].buca == buca && coord[j].tipologia == "Bunker")
						{
							bunkerArr.push({ percorso: coord[j].percorso, buca: coord[j].buca, tipologia: coord[j].tipologia,color:coord[j].colore });
						} else if (coord[j].buca == buca && coord[j].tipologia == "Collar")
						{
							collarArr.push({ percorso: coord[j].percorso, buca: coord[j].buca, tipologia: coord[j].tipologia,color:coord[j].colore });
						}else if(coord[j].buca == buca && coord[j].tipologia == "Campo Pratica"){
							campoPraticaArr.push({ percorso: coord[j].percorso, buca: coord[j].buca, tipologia: coord[j].tipologia,color:coord[j].colore })
						}else if (coord[j].buca == buca && coord[j].tipologia == "Altro") {
							altroArr.push({ percorso: coord[j].percorso, buca: coord[j].buca, tipologia: coord[j].tipologia,color:coord[j].colore });
						} else {

						}
					}
				})
				const totBuche = [];

				uniqueBucheArr.forEach((buca, i) => { //Creo l'array totale suddiviso per buca -> ogni buca ha i poligoni suddivisi per categoria
													totBuche.push({	"Buca": buca
														, "Green":findPath("Green",greenArr,buca)
														, "Aprons": findPath("Aprons",apronArr,buca) 
														, "Tee":findPath("Tee",teeArr,buca)
														, "Collar": findPath("Collar",collarArr,buca)
														, "Rough":  findPath("Rough",roughArr,buca) 
														, "Bunker": findPath("Bunker",bunkerArr,buca)
														, "Fairway":findPath("Fairway",fairwayArr,buca)
														, "CampoPratica":findPath("Campo Pratica",campoPraticaArr,buca)
														, "Altro":  findPath("Altro",altroArr,buca)
													})
										})

				setCoordsRev(totBuche);
				setCoords(coord);
			}
			*/
		}

		/**
		 * 
		 * @param {*} tipologia Gren/aprons/tee/collar/rough/bunker/fairway/campo pratica/altro
		 * @param {*} arrayTipologia array contente solo poligoni di quella tipologia 
		 * @param {*} buca nome buca
		 * @returns array corretto per la visualizzazione dei poligoni suddivisi per categoria (passata come parametro formale) della
		 *  buca (passata per parametro formale)
		 */
		function findPath(tipologia,arrayTipologia,buca){	
				let paths=[];
				arrayTipologia.forEach((path)=>{
					if(path.buca==buca){
						if(tipologia=="Tee" || tipologia=="Rough" || tipologia=="Campo Pratica"||tipologia=="Fairway" || tipologia=="Collar" || tipologia=="Altro"){
							paths.push({percorso:path.percorso,color:path.color});
						}else{
							paths.push({percorso:path.percorso,color:path.color});
						}		
					}
				})
				return paths;
		}


		React.useEffect(()=>{
			createPolygons()
		},[coordsRev])

		/**
		 * Funzione che crea l'array contenente i poligoni che verranno definitivamente mostrati sulla mappaù
		 * Gestisce anche lo zIndex, fondamentale per l'implementazione della gerarichia delle aree
		 */
		function createPolygons(){
			const polygons=[]; //Array che poi verrà visualizzato sulla mappa
			coordsRev.forEach((buca,index)=>{ //Per ogni buca creo i poligoni suddivisi per categorie
			
				if(buca.Rough.length>0){ 
					buca.Rough.map((rough,index)=>{ //CREO I ROUGH CON ESCLUSIONI
						
						polygons.push({
							editable:true,
							paths:[
								rough.percorso,	
							],
							zIndex:1,
							strokeColor:rough.color,
							strokeOpacity:0.8,
							strokeWeight:2,
							fillColor:rough.color,
							fillOpacity:0.35,
							}
							)
					})
				}
				if(buca.CampoPratica.length>0){
					buca.CampoPratica.map((campoPratica,index)=>{ //CREO I ROUGH CON ESCLUSIONI
						polygons.push({
							editable:true,
							paths:[
								campoPratica.percorso,	
							],
							zIndex:1,
							strokeColor:campoPratica.color,
							strokeOpacity:0.8,
							strokeWeight:2,
							fillColor:campoPratica.color,
							fillOpacity:0.35,
							}
							)
					})
				}
				if(buca.Collar.length>0){
					buca.Collar.map((collar,index)=>{ //Creo i collar con esclusioni
						polygons.push({
							editable:true,
							paths:[
								collar.percorso,
							],
							zIndex:2,
							strokeColor:collar.color,
							strokeOpacity:0.8,
							strokeWeight:2,
							fillColor:collar.color,
							fillOpacity:0.5,
							})
					})
				}	
				if(buca.Green.length>0){
					buca.Green.map((green,index)=>{  //Creo i green
						polygons.push({
							editable:true,
							paths:[
								green.percorso
							],
							zIndex:3,
							strokeColor:green.color,
							strokeOpacity:0.8,
							strokeWeight:2,
							fillColor:green.color,
							fillOpacity:0.7,
							})
					})
				}
				if(buca.Fairway.length>0){
					buca.Fairway.map((fairway,index)=>{  //Creo i fairway
						polygons.push({
							editable:true,
							paths:[
								fairway.percorso
							],
							zIndex:2,
							strokeColor:fairway.color,
							strokeOpacity:0.8,
							strokeWeight:2,
							fillColor:fairway.color,
							fillOpacity:0.5,
							})
					})
				}
				if(buca.Bunker.length>0){
					buca.Bunker.map((bunker,index)=>{  //Creo i fairway
						polygons.push({
							editable:true,
							paths:[
								bunker.percorso
							],
							zIndex:3,
							strokeColor:bunker.color,
							strokeOpacity:0.8,
							strokeWeight:2,
							fillColor:bunker.color,
							fillOpacity:0.7,
							})
					})
				}
				if(buca.Tee.length>0){
					buca.Tee.map((tee,index)=>{  //Creo i tee
						polygons.push({
							editable:true,
							paths:[
								tee.percorso
							],
							zIndex:2,
							strokeColor:tee.color,
							strokeOpacity:0.8,
							strokeWeight:2,
							fillColor:tee.color,
							fillOpacity:0.5,
							})
					})
				}
				if(buca.Aprons.length>0){
					buca.Aprons.map((aprons,index)=>{  //Creo aprons
						polygons.push({
							editable:true,
							paths:[
								aprons.percorso
							],
							zIndex:2,
							strokeColor:aprons.color,
							strokeOpacity:0.8,
							strokeWeight:2,
							fillColor:aprons.color,
							fillOpacity:0.5,
							})
					})
				}
				if(buca.Altro.length>0){
					buca.Altro.map((altro,index)=>{  //Creo altro
						polygons.push({
							editable:true,
							paths:[
								altro.percorso
							],
							zIndex:1,
							strokeColor:altro.color,
							strokeOpacity:0.8,
							strokeWeight:2,
							fillColor:altro.color,
							fillOpacity:0.35,
							})
					})
				}
				})
				setPolygonsMap(polygons);  //ARRAY FINALE PER ADMIN CANTIERE/ZONA
		}

		useEffect(() => {
		 getCoords();
		}, []);


	  return(
	  	<Box flex style={{position: 'absolute', height: '100%', width: '100%', top: '0', left: '0'}}>
		    { home !== undefined ?
			    <Map google={window.google} 										//Modifica dev-mattia-mora
			        style={{width: '100%', height: '100%', position: 'relative'}}
			        className={'map'}
			        initialCenter={home}
			        zoom={16}>
					{
							coordinate.map(coordinata=>{
								return(
								<Polygon

									editable={false}
									paths={coordinata}
									zIndex={1}
									strokeColor={props?.color}
									strokeOpacity={0.8}
									strokeWeight={2}
									fillColor={props?.color}
									fillOpacity={0.35}

								>
								</Polygon>
								)}
							)
					}
					    </Map>
				    :
				    	null
			}
	    </Box>
	  )
}


 
export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY
})(App)