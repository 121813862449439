import React, { useEffect } from "react";
import {Box, Heading, Form, FormField, Select, TextInput, Button, DateInput} from "grommet";
import {QUERY_MACHINE, QUERY_USER,ADD_RELIEF_HOUR, UPDATE_RELIEF_HOUR} from "./maintenanceGQL";
import { useLazyQuery,useMutation } from '@apollo/client';
import { tenant,tenantType } from "../../tenant";
import { extractIdFormData } from "../../../utilis/utils";
export default function AddLav(props) {

  const [users,setUsers]=React.useState([]);
  const [machines,setMachines]=React.useState([]);
  const [formData,setFormData]=React.useState({});
  const [addReliefHour]=useMutation(ADD_RELIEF_HOUR);

  const [ queryGetUser
  ] = useLazyQuery(QUERY_USER, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => { 
      const users=data[`${tenant}_users_userarea`].map((user)=>user[`users_user${tenantType}`])
      if(users){
        if(props?.ruolo==="Meccanico"){
          console.log("USERS",users);
          setUsers(users.filter((el)=>el?.id==sessionStorage.getItem("userId")))
        }else{
          setUsers(users);
        }
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });

  const [ queryGetMachine
  ] = useLazyQuery(QUERY_MACHINE, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => { 
      const machines=data[`${tenant}_machines_machinearea`].map((machine)=>machine[`machines_machine${tenantType}`])
      if(machines){
         const sortedMachines = [...machines].sort((a, b) => a.name.localeCompare(b.name));
        setMachines(sortedMachines);
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });

   /*
    Funzione che resetta i campi dell'anagrafica
  */
  const reset=()=>{
    setFormData({});
  }

   /*
    Funzione che resetta i campi dell'anagrafica
  */
  useEffect(() => {
    if(props.rilievo){
      setFormData(props.rilievo);
    }
    if(props.machine){
      setFormData({...formData,machine:props.machine})
    }
    queryGetUser({variables:{area:JSON.parse(sessionStorage.getItem("areeId"))}}); //Da prendere poi da tenant selezionato
    queryGetMachine({variables:{area:JSON.parse(sessionStorage.getItem("areeId"))}})
  }, []);

  const onChangeSelect=(event)=>{
    setFormData({...formData,[event.target.name]:event.value})
  }

  function onAddRil(){
    addReliefHour(extractIdFormData(formData,false))
    .then((value)=>{
      alert("Rilievo ore aggiunto");
      queryGetMachine({variables:{area:JSON.parse(sessionStorage.getItem("areeId"))}});
      if(props.getMachineMaintenance){
        props.getMachineMaintenance();
      }
      onEscLayer()
    })
    .catch((error)=>{alert("Errore durante l'operazione");console.log(error)});
  }

  function onEscLayer(){
    if(props.onEscLayer){
        props.onEscLayer();
    }
    setFormData({});
  }


  return (
    <Box round pad="medium" direction="column" background="white" overflow="auto">
      <Heading level="2" margin="none" size="small">
        Inserisci ore macchinario rilevate
      </Heading>
      <Box fill gap="medium" pad={{ vertical: "small" }}>
        <Form>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <FormField required label="Matricola Operatore">
              <Select
                name="user"
                multiple={false}
                options={users}
                value={formData?.user}
                onChange={(option ) =>onChangeSelect(option)}
                labelKey={(option)=>option && `${option.name} ${option.surname} ${option.registration_number}`}
                closeOnChange={true}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Data del Rilievo">
              <DateInput
                format="dd/mm/yyyy"
                value={formData?.datetime}
                onChange={({ value }) => {setFormData({...formData, datetime: value})}}
              />
            </FormField>
          </Box>
        </Box>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <FormField required label="Macchinario">
              <Select
                name="machine"
                multiple={false}
                options={machines}
                value={formData?.machine}
                onChange={(option ) =>onChangeSelect(option)}
                labelKey={(option)=>option && `${option.name} ${option.chassis_number}`}
                closeOnChange={true}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Inserisci ore rilevate">
              <TextInput
                type="number"
                name="machineHour"
                placeholder="es. 20"
                value={formData?.machineHour}
                onChange={e =>setFormData({...formData,machineHour:parseInt(e.target.value)})}
              />
            </FormField>
          </Box>
        </Box>
      </Form>
      <Box direction="row" gap="medium" alignSelf="end">
          <Button margin="small" label={!props.rilievo ? "Aggiungi" : "Aggiorna"} onClick={!props.rilievo ? onAddRil:props.onDelete} style={{width:'138px',height:'34px',fontSize:'15px',background:'#397aa3',color:'white',fontWeight:'bold',textTransform: 'uppercase'}} />
          <Button margin="small" type="reset" label={!props.rilievo?"Reset":"Cancella"}  onClick={!props.rilievo?reset:props.onDelete} style={{marginLeft:'5px',width:'138px',height:'34px',fontSize:'15px',background:'#c51d34',color:'white',fontWeight:'bold',textTransform: 'uppercase', borderColor:'#c51d34'}}/>
        </Box>
      </Box>
    </Box>
  )
}