import { gql } from '@apollo/client';
import { tenant,tenantType,tenantTypeFirstUpperCase } from '../../tenant';

const GET_TREE=gql`
query GetTree {
  ${tenant}{
    whoami{
      profile{
        treeArea
      }
    }
  }
}
`

const DELETE_AREA=gql`
mutation deleteAreaType($id: ID!) {
	${tenant}{
    deleteArea${tenantTypeFirstUpperCase}(id:$id){
     	ok
    	message
    }
  }
}
`

const QUERY_GET_AREE_TYPE=gql`
query GetAreeType {
  ${tenant}_area_areatype${tenantType}(order_by: {sequence: asc}) {
    id
    type_name
    sequence
  }
}
`

const QUERY_GET_FATHER_TYPE=gql`
query GetArea($id: bigint!) {
  ${tenant}_area_area${tenantType}_by_pk(id: $id){
    area_areatype${tenantType}{
      id
      type_name
      sequence
      father_id
    }
  }
}
`
const ADD_AREA=gql`
mutation AddArea($father_id:ID!,$is_alias:Boolean!,$type_id:ID!,$name:String!,$external_erp_code:String,$archive:Boolean!,$assignedAutomaticallyToAllJobs:Boolean,$assignedAutomaticallyToAllMachine:Boolean,$assignedAutomaticallyToAllUser:Boolean,$homeCoordinatesJson:String) {
	${tenant}{
    upsertArea${tenantTypeFirstUpperCase}(input:{father:$father_id,isAlias:$is_alias,type:$type_id,name:$name,externalErpCode:$external_erp_code,archive:$archive,assignedAutomaticallyToAllJobs:$assignedAutomaticallyToAllJobs,assignedAutomaticallyToAllMachine:$assignedAutomaticallyToAllMachine,assignedAutomaticallyToAllUser:$assignedAutomaticallyToAllUser,homeCoordinatesJson:$homeCoordinatesJson}){
      name
      assignedAutomaticallyToAllJobs
      assignedAutomaticallyToAllMachine
      assignedAutomaticallyToAllUser
    }
  }
}
`

const GET_AREA=gql`
query GetArea($id:bigint!) {
  ${tenant}_area_area${tenantType}_by_pk(id:$id){
    id
    name
    father_id
    is_alias
    external_erp_code
    archive
    area_areatype${tenantType}{
      id
      type_name
      sequence
    }
    area_area${tenantType}{
      id
      name
    }
  }
}
`

const UPDATE_AREA=gql`
mutation UpdateArea($id:bigint!, $father_id: bigint, $is_alias: Boolean, $name: String!, $type_id: bigint!,$external_erp_code:String,$archive:Boolean!,$assignedAutomaticallyToAllJobs:Boolean,$assignedAutomaticallyToAllMachine:Boolean,$assignedAutomaticallyToAllUser:Boolean,$homeCoordinatesJson:jsonb) {
  update_${tenant}_area_area${tenantType}_by_pk(pk_columns: {id: $id}, _set: {father_id: $father_id,is_alias: $is_alias, name: $name, type_id: $type_id,external_erp_code:$external_erp_code,archive:$archive,assigned_automatically_to_all_jobs:$assignedAutomaticallyToAllJobs,assigned_automatically_to_all_machine:$assignedAutomaticallyToAllMachine,assigned_automatically_to_all_user:$assignedAutomaticallyToAllUser,home_coordinates_json:$homeCoordinatesJson}) {
    id
    is_alias
    name
    type_id
    father_id
    external_erp_code
    archive
    assigned_automatically_to_all_machine
    assigned_automatically_to_all_user
    assigned_automatically_to_all_jobs
  }
}
`

const GET_AREE=gql`
query GetAree{
  ${tenant}_area_area${tenantType}{
    id
    name
  }
}
`

export {GET_TREE,DELETE_AREA,QUERY_GET_AREE_TYPE,QUERY_GET_FATHER_TYPE,ADD_AREA,GET_AREA,UPDATE_AREA,GET_AREE};