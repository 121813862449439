import React from "react";
import {Box,Accordion,AccordionPanel,Text,Button} from "grommet";
import {Logout} from "grommet-icons"

/**
 * Implementa il componente di logout posto nella Home della web app.
 * @param {} props 
 * @returns 
 */
const AccountBox=(props)=>{
    
    return(
        <Box direction="row" gap="small">
            <Box width="50px" height="50px" background="#397aa370" align="center" >
                <Box direction="row" pad="small">
                    {
                        props.userLogged &&
                        <>
                          <Text size="large" weight="bold">{props.userLogged?.name?.substring(0,1) || ""}</Text>
                          <Text size="large" weight="bold">{props.userLogged?.surname?.substring(0,1) || ""}</Text>
                        </>
                    }

                </Box>
            </Box>
            <Accordion>
                <AccordionPanel label={
                    <Box gap="5px">
                        <Text size="medium" weight="bold">{props.userLogged?.name || ""}</Text>
                        <Text size="small">{props.userLogged?.email || ""}</Text>
                    </Box>
                }>
                    <Button margin="2%" style={{width:'200px',fontSize:'10px',background:'#397aa3',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}>
                            <Button align="center" onClick={props.logout}>
                                <Box direction="row" gap="small" align="center" pad="small">
                                    <Logout color='white' />
                                    <Text size="medium" color='#FFFFFF'>Logout</Text>
                                </Box>
                            </Button>        
                    </Button>
                </AccordionPanel>
            </Accordion>
        </Box>

    )
}

export default AccountBox;