import React, {useState} from "react";
import {useLazyQuery, useMutation, useSubscription} from "@apollo/client";
import {Box, Grommet, Text, Layer} from "grommet";
import {DataGrid} from "@mui/x-data-grid";
import {tenant, tenantType} from "../tenant.js";
import {Button as ButtonMui, Divider} from "@mui/material";
import {DELETE_MOVEMENT, DELETE_PRODUCT, GET_MOVEMENTS, GET_PRODUCTS, UPSERT_PRODUCT} from "./ProductGQL.jsx";
import moment from "moment";

function MovementList(props){

    const [movements,setMovements] = React.useState([]);
    const [deleteMovement]=useMutation(DELETE_MOVEMENT)
    const [ queryGetMovement
    ] = useLazyQuery(GET_MOVEMENTS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
        const movements=data[`${tenant}_products_movement`];
        if(movements){
            let movementsApp=[];
            for(let i=0;i<movements.length;i++){
                movementsApp.push({...movements[i],data:moment(movements[i].data).format("DD-MM-YYYY HH:mm"),area:movements[i][`area_area${tenantType}`]?.name})
            }
            setMovements(movementsApp);
        }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });
    const [deletedMovement,setDeletedMovement] = useState(false);

    function getMovements(){
        queryGetMovement({variables:{productId:props?.prodSel?.id}})
            .then((value)=>console.log("VALUE",value))
            .catch((err)=>console.log("ERR",err));
    }

    const columnsMovements = [
    { field: 'data', headerName: 'Data', width: 300 },
        { field: 'user', headerName: 'Effettuato da', width: 300 ,
         renderCell: (cellValues) => {
            return(
                 <Box width="150px">
                    <Text size={"small"}>{cellValues.row[`users_user${tenantType}`]["name"]} {cellValues.row[`users_user${tenantType}`]["surname"]}</Text>
                  </Box>
            )
         }
        },
    { field: 'quantity', headerName: 'Quantità', width: 150 },
        {field: 'area',headerName: 'Area',width: 150},
   {
    field: 'Cancella',
    headerName: 'Cancella',
    width: 150,
    renderCell: (cellValues) => {
      return (
        <ButtonMui
          variant="contained"
          color="error"
          onClick={(event) => {
              let res=window.confirm("Sei sicuro di voler eliminare il movimento selezionato?");
              if(res){
                  setDeletedMovement(true);
                  deleteMovement({variables:{movementId:cellValues.row.id}})
                  getMovements();
              }
          }}
        >
          Cancella
        </ButtonMui>
      );},
     },
  ];

  function onEscLayer(){
    if(props.onEscLayer){
        props.onEscLayer();
        if(deletedMovement && props.getProducts){
            props.getProducts();
        }
    }
  }


  React.useEffect(()=>{
     getMovements();
  },[props?.prodSel])


    return (
        <Layer onEsc={onEscLayer} onClickOutside={onEscLayer}>
            <Box width={"90%"} overflow="hidden" aligns="center" pad="small" margin="10px">
              <Text color="#397aa3" size="large" weight="bold" alignSelf="start">MOVIMENTI PER {props?.prodSel?.name}</Text>
              <Divider/>
            </Box>
            <Box pad={"small"} overflow="auto" height="83vh" width="100%" alignSelf='center' style={{marginTop:'40px'}}>
                <div style={{ height: '75vh', width: '70vw' }}>
                  <DataGrid
                    rows={movements || []}
                    columns={columnsMovements}
                  />
                </div>
            </Box>
        </Layer>
    )
}

export default MovementList;

