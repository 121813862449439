import React, {useState} from "react";
import { Box, Heading, Form, FormField, TextInput, Button,Layer,Select,CheckBox } from "grommet";
import { FormClose } from "grommet-icons";
import { useLazyQuery,useMutation } from '@apollo/client';
import {QUERY_GET_AREE_TYPE, QUERY_GET_FATHER_TYPE, ADD_AREA, GET_AREA, UPDATE_AREA, GET_AREE} from "./areeGQL";
import {tenant, tenantType, tenantTypeFirstUpperCase} from "../../tenant";
import { extractIdFormData } from "../../../utilis/utils";
import { GoogleMap, useJsApiLoader, DrawingManager, Polygon,Marker } from '@react-google-maps/api';
import {GET_HOME} from "../../geoRef/geoRefGQL.js";
import Switch from '@mui/material/Switch';
import FormControlLabel from "@mui/material/FormControlLabel";

export default function AddArea(props) {

  const [formData,setFormData]=React.useState({father_id:props.father,external_erp_code:"",is_alias:false,archive:false,assignedAutomaticallyToAllUser:false,assignedAutomaticallyToAllMachine:false,assignedAutomaticallyToAllJobs:false}); //impostati di default
  const [areaTypeList,setAreaTypeList]=React.useState([]);
  const [addAreaMutation]=useMutation(ADD_AREA);
  const [updateAreaMutation]=useMutation(UPDATE_AREA);
  const [homeWithMap,setHomeWithMap] = React.useState(false);
  const [listAree,setListAree]=React.useState([]);
  const [ queryAreeType
  ] = useLazyQuery(QUERY_GET_AREE_TYPE, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if(data[`${tenant}_area_areatype${tenantType}`]){
        setAreaTypeList(data[`${tenant}_area_areatype${tenantType}`])
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });
   const [ queryAree
  ] = useLazyQuery(GET_AREE, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if(data[`${tenant}_area_area${tenantType}`]){
        setListAree(data[`${tenant}_area_area${tenantType}`])
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });
  const [ queryGetTypeFather, ] = useLazyQuery(QUERY_GET_FATHER_TYPE, {
    variables: {
      id: props?.father?.id
    },
    fetchPolicy: "no-cache",
    onCompleted: (data) => { 
      if(data[`${tenant}_area_area${tenantType}_by_pk`][`area_areatype${tenantType}`]){
        let typeFather=data[`${tenant}_area_area${tenantType}_by_pk`][`area_areatype${tenantType}`];
        let typeNewArea=areaTypeList.filter((type)=>type.sequence===typeFather.sequence+1)[0] //Avrà come tipo il figlio del tipo del padre
        if(typeNewArea){
          setFormData({...formData,["type_id"]:typeNewArea})
        }else{
          if(!props.nodeModify){
            props.onEscLayer();
            alert(`Non è impostato un altro livello nel tipo aree al di sotto di "${typeFather.type_name}".\n Imposta un altro livello nella sezione "Tipologia aree"`)
          }
        }
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });

  const [  queryGetArea //Per modifica
  ] = useLazyQuery(GET_AREA, {
    variables: {
      id: props?.nodeModify?.id
    },
    fetchPolicy: "no-cache",
    onCompleted: (data) => { 
      const res=data[`${tenant}_area_area${tenantType}_by_pk`];
      if(res){
        let formDataApp={...res,father_id:res[`area_area${tenantType}`],type_id:res[`area_areatype${tenantType}`]};
        delete formDataApp[`area_area${tenantType}`]
        delete formDataApp.__typename
        delete formDataApp[`area_areatype${tenantType}`]
        setFormData(formDataApp);      
      }
      
    },
    notifyOnNetworkStatusChange: true, // did the work
  });

  const [ queryGetHome
] = useLazyQuery(GET_HOME, {
  fetchPolicy: "no-cache",
  onCompleted: (data) => {
    const homeData=data[tenant][`area${tenantTypeFirstUpperCase}`]?.recursiveHomeCoords;
    if(homeData){
      setFormData({...formData,homeCoordinatesJson: JSON.parse(homeData)})
    }
  },
  notifyOnNetworkStatusChange: true, // did the work
});

  React.useEffect(()=>{
    queryAreeType();
    queryAree();
  },[])

  React.useEffect(()=>{
     queryGetHome({variables:{id:formData?.id}});
  },[formData?.id])

  React.useEffect(()=>{
    if(!props.nodeModify){
      queryGetTypeFather();
    }else{
      queryGetArea();
    }
  },[areaTypeList])

  const onChangeTextInput=(event)=>{
    setFormData({...formData,[event.target.name]:event.target.value})
  }

  const onChangeSelect=(event)=>{
    setFormData({...formData,[event.target.name]:event.value})
  }

  const onChangeCheckBox=(event)=>{
    setFormData({...formData,[event.target.name]:event.target.checked})
  }

  function addArea(){
    let formDataApp={...formData};
    if(formDataApp["father_id"]===undefined){
      formDataApp["father_id"]=null;
    }
    addAreaMutation(extractIdFormData(formDataApp,false))
    .then((value)=>{
      if(props.loadTree){
        props.loadTree();
        alert("Area creata con successo");
        setFormData({...formData,name:""});
        queryAree();
      }
    }).catch((value)=>{
      console.error(value);
      alert("Errore durante l'operazione")
    })
  }

  function updateArea(){
    updateAreaMutation(extractIdFormData(formData,false))
    .then((value)=>{
      if(props.loadTree){
        props.loadTree();
         alert("Area aggiornata con successo");
         setFormData({...formData,name:""});
         queryAree();
      }
    }).catch((value)=>{
      console.error(value);
      alert("Errore durante l'operazione")
    })
  }

  function onEscLayer(){
    props.onEscLayer();
    setFormData({});
  }

  const MapWithADirectionsRenderer =()=>{

  const [libraries] = useState(['drawing']);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    libraries,
    googleMapsApiKey: "AIzaSyBH__gEjglI0DzkCuq33ioG3_ayXHP4ojo"
  })

  const onMapLoad = map => {};

    const handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setFormData({
      ...formData,
      homeCoordinatesJson:{lat:lat,lng:lng}
    });
  };


  return isLoaded && formData?.homeCoordinatesJson?(
    <GoogleMap
    center={new window.google.maps.LatLng(formData?.homeCoordinatesJson?.lat,formData?.homeCoordinatesJson?.lng)}
    zoom={16}
    onLoad={map => onMapLoad(map)}
    mapContainerStyle={{ height: "700px", width: "1000px" }}
  >
        <Marker
            position={new window.google.maps.LatLng(formData?.homeCoordinatesJson?.lat,formData?.homeCoordinatesJson?.lng)}
            draggable={true}
            onDragEnd={(event) => handleMapClick(event)}
          />

  </GoogleMap>
  ):<></>}

  return (
    <Layer full={window.screen.width<1100?true:false} onClickOutside={onEscLayer} onEsc={onEscLayer}>
      <Box height="100%" round="medium" pad="15px" background="#ecedf3">
        <Button  alignSelf="end" icon={<FormClose color="grey" size="35px" onClick={onEscLayer}/>}></Button>
          <Box round pad="medium" direction="column" background="white" align="center" overflow="auto">
            <Heading level="2" margin="none" size="small" >
              <Box direction="row" gap="small">
                {!props.nodeModify?"Inserisci L'Anagrafica dell'area":"Aggiorna anagrafica area"}
               { /*<Button label="Scarica modello" href={"./modelliCsv/Modello_Aree.csv"} style={{width:'200px',fontSize:'20px',background:'#0C6B13',color:'white',fontWeight:'bold',textTransform: 'uppercase',textAlign:"center"}}></Button> */ }
              </Box>
            </Heading>
            <Box fill gap="medium" pad={{ vertical: "small" }} align="center">
            <Form>
              <Box direction="row" align="center">
                <Box pad="xsmall">
                  <FormField required label="Nome Area">
                    <TextInput
                      name="name"
                      value={formData?.name || ""}
                      placeholder="es. Barlassina"
                      onChange={e => onChangeTextInput(e)}
                    />
                  </FormField>
                </Box>
                {
                 
                  <Box pad="xsmall">
                    <FormField required label="Padre">
                      <Select
                        name="father_id"
                        value={formData?.father_id  || ""}
                        options={listAree}
                        labelKey={(option)=>option.name +" "+option.id}
                        onChange={e => onChangeSelect(e)}
                      />
                    </FormField>
                  </Box>
                }
              
                <Box pad="xsmall">
                  <FormField required label="Tipo Area">
                    <Select
                      name="type_id"
                      value={formData?.type_id  || ""}
                      options={areaTypeList}
                      labelKey="type_name"
                      onChange={e => onChangeSelect(e)}
                    />
                  </FormField>
                </Box>
              </Box>
              <Box direction="row" align="center">
                  <Box pad="xsmall">
                    <FormField required label="Codice gestionale esterno">
                      <TextInput
                        name="external_erp_code"
                        value={formData?.external_erp_code || ""}
                        placeholder="es. Barlassina"
                        onChange={e => onChangeTextInput(e)}
                      />
                    </FormField>
                  </Box>
                  <CheckBox
                    name="archive"
                    checked={formData?.archive || false}
                    label="Archivia?"
                    onChange={(e) => onChangeCheckBox(e)}
                  />

              {
                  /*
              <Box pad="xsmall">
                  <FormField required label="Latitudine Home">
                    <TextInput
                      placeholder="es. 45.1143043"
                      value={newLat}
                      onChange={e => setNewLat(e.target.value)}
                    />
                  </FormField>
                </Box>
                
                <Box pad="xsmall">
                  <FormField required label="Longitudine Home">
                    <TextInput
                      placeholder="es. 10.6855563"
                      value={newLng}
                      onChange={e => setNewLng(e.target.value)}
                    />
                  </FormField>
                </Box>
                <Box pad="xsmall">
                  <FormField required label="Codice Gestionale (ESTERNO)">
                    <TextInput
                      placeholder="es. "
                      value={newCode}
                      onChange={e => setNewCode(e.target.value)}
                    />
                  </FormField>
                </Box>
                */}
              </Box>
               <Box direction="row" align="center">
                  <CheckBox
                    name="assignedAutomaticallyToAllJobs"
                    checked={formData?.assignedAutomaticallyToAllJobs || false}
                    label="Assegna a tutti i lavori"
                    onChange={(e) => onChangeCheckBox(e)}
                  />
                 <CheckBox
                    name="assignedAutomaticallyToAllMachine"
                    checked={formData?.assignedAutomaticallyToAllMachine || false}
                    label="Assegna a tutte le macchine"
                    onChange={(e) => onChangeCheckBox(e)}
                  />
                  <CheckBox
                    name="assignedAutomaticallyToAllUser"
                    checked={formData?.assignedAutomaticallyToAllUser || false}
                    label="Assegna a tutti gli utenti"
                    onChange={(e) => onChangeCheckBox(e)}
                  />
                </Box>
                <FormControlLabel
                  control={
                    <Switch checked={homeWithMap} onChange={(e)=>setHomeWithMap(e.target.checked)} name="homeWithMap" />
                  }
                  label={homeWithMap?"Seleziona posizione con mappa":"Inserisci manualmente posizione"}
                />
              {
                homeWithMap ?
                   <Box width="80%" margin={"small"} >
                      <MapWithADirectionsRenderer />
                  </Box>
                    :
                  <Box direction={"raw"} gap={"small"}>
                     <FormField required label="Latitudine">
                      <TextInput
                        name="lat"
                        value={formData?.homeCoordinatesJson?.lat || ""}
                        placeholder=""
                        onChange={e => {
                          const homeCoordApp=formData?.homeCoordinatesJson? {...formData?.homeCoordinatesJson}:{}
                          homeCoordApp["lat"]=e.target.value;
                          setFormData({...formData,homeCoordinatesJson:homeCoordApp})
                        }}
                      />
                    </FormField>
                     <FormField required label="Longitudine">
                      <TextInput
                        name="lng"
                        value={formData?.homeCoordinatesJson?.lng || ""}
                        placeholder=""
                          onChange={e => {
                          const homeCoordApp=formData?.homeCoordinatesJson? {...formData?.homeCoordinatesJson}:{}
                          homeCoordApp["lng"]=e.target.value;
                          setFormData({...formData,homeCoordinatesJson:homeCoordApp})
                        }}
                      />
                     </FormField>
                  </Box>
              }

            </Form>
            <Box direction="row" gap="medium" alignSelf="end">
                <Button label={!props.nodeModify?"Aggiungi":"Aggiorna"} onClick={!props.nodeModify?addArea:updateArea} style={{width:'138px',height:'34px',fontSize:'15px',background:'#0C6B13',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}/>  
            </Box>
          </Box>
        </Box>
      </Box>
    </Layer>
  )
}
