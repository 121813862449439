import React from "react";
import {Box, Heading, Form, FormField, TextInput, Button, Layer, DateInput} from "grommet";
import { useMutation } from '@apollo/client';
import {UPSERT_MOVEMENT, UPSERT_PRODUCT} from "./ProductGQL.jsx";

export default function AddMovement(props) {

  const [formData,setFormData]=React.useState({product:props?.prodSel?.id,user:sessionStorage.getItem("userId")});
  const [mutationAddMovement]=useMutation(UPSERT_MOVEMENT);



  const onChangeTextInput=(event)=>{
      if(event.target.name==="quantity") {
          setFormData({...formData, [event.target.name]: parseFloat(event.target.value)})
      }else{
          setFormData({...formData, [event.target.name]: event.target.value})
      }
  }

  function onUpsertMovement(){
      let formDataApp={...formData};
      if(formDataApp?.quantity){
            if(props.movementType==="carico"){
              let quantity= parseFloat(formDataApp["quantity"])
              formDataApp["quantity"] = quantity>=0? quantity : -quantity //Se è minore di zero moltiplico per -1
          }else{
              let quantity= parseFloat(formDataApp["quantity"])
              formDataApp["quantity"] = quantity<0 ? quantity : -quantity //Se è maggiore di zero moltiplico per -1
          }
        mutationAddMovement({variables: formDataApp})
        .then((value)=>{
          alert("Movimento aggiunto correttamente");
          if(props.getProducts){
              props.getProducts();
          }
          onEscLayer();
        }).catch((value)=>{
            console.error(value);
          alert("Errore durante l'operazione");
        })
      }
  }

  function onEscLayer(){
    if(props.onEscLayer){
        props.onEscLayer();
    }
    setFormData({});
  }



  return (
      <Layer onEsc={onEscLayer} onClickOutside={onEscLayer}>
          <Box height="100%" round="medium" pad="15px" background="#ecedf3">
              <Box round pad="medium" direction="column" background="white" align="center" overflow="auto">
              <Heading level="2" margin="none" size="small">
                <Box direction="row" gap="large">
                    {`Inserisci Movimento di ${props.movementType}`}
                  {/*<Button label="Scarica modello" href={"./modelliCsv/Modello_Lavoro.csv"} style={{width:'200px',fontSize:'20px',background:'#397aa3',color:'white',fontWeight:'bold',textTransform: 'uppercase',textAlign:"center"}}></Button> */}
                </Box>
              </Heading>
              <Box fill gap="medium" pad={{ vertical: "small" }}>
                <Form>
                  <Box pad="xsmall">
                        <FormField required label="Data">
                            <DateInput
                              format="dd/mm/yyyy"
                              value={formData?.data}
                              onChange={({ value }) => {
                                // Creo un oggetto Data dalla stringa
                                let date = new Date(value);
                                const currentHours = new Date().getHours();
                                const currentMinutes = new Date().getMinutes();

                                date.setHours(currentHours);
                                date.setMinutes(currentMinutes);
                                console.log(date.toISOString());
                                setFormData({...formData,data: date.toISOString()})}}
                            />
                        </FormField>
                        <FormField required label={props.movementType==="carico"? "Quantità da aggiungere": "Quantità da rimuovere"}>
                          <TextInput
                              type={"number"}
                            name="quantity"
                            value={ formData?.quantity }
                            onChange={e => onChangeTextInput(e)}
                          />
                        </FormField>
                  </Box>
                </Form>

                <Box direction="row" gap="medium" alignSelf="end" >
                  <Button margin="small" label= {!props.product?"Aggiungi":"Aggiorna"} onClick={onUpsertMovement} style={{width:'138px',height:'34px',fontSize:'15px',background:'#397aa3',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}/>
                </Box>
              </Box>
            </Box>
          </Box>
      </Layer>
  )
}