import React, {useState, useEffect} from "react";
import { Grommet,ResponsiveContext, Button,Box,Text,Select,Layer} from "grommet";
import { LinkPrevious, Location, Close } from "grommet-icons";
import { GoogleMap, useJsApiLoader, DrawingManager, Polygon } from '@react-google-maps/api';
import { tenant,tenantType,tenantTypeFirstUpperCase } from "../tenant";
import { GET_HOME ,TRACK_AREA} from "./geoRefGQL";
import { useLazyQuery ,useMutation } from '@apollo/client';
import SideBar from "../SideBar.js";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu.js";

const theme = {
  global: {
    colors: {
      brand: '#397aa3',
      'accent-1': 'white',
      'focus': '#397aa3',
      "my-text-color": "",
      background: '#ecedf3',
    },
    font: {
      family: '',
      size: '18px',
      height: '20px',
    },
    menu:{
      icons:{
        color:'white',
        },
    },
  },
};
const AreaTrack = (props) => {

const [color, setColor] = React.useState(null);
const colors = [
  {key: 'Verde', value: '#009900'},
  {key: 'Giallo', value: '#eed000'},
  {key: 'Blu', value: '#0066ff'},
  {key: 'Rosso', value: '#cc3300'},
  {key: 'Viola', value: '#800080'},
  {key: 'Porpora', value: '#ee82ee'},
  {key: 'Marrone', value: '#a52a2a'},
  {key: 'Nero', value: '#000000'},
  {key: 'Oro', value: '#ffd700'},
  {key: 'Azzurro', value: '#add8e6'},
  {key: 'Verde Chiaro', value: '#90ee90'},
  {key: 'Rosa', value: '#ffc0cb'},
  {key: 'Grigio', value: '#808080'},
  {key: 'Arancione', value: '#ffa500'},
  {key: 'Verde Acqua', value: '#00cc99'},
  {key: 'Verde Erba', value: '#99cc00'},
  {key: 'Argento', value: '#c0c0c0'},
  {key: 'Giallo Ocra', value: '#aea04b'},
  {key: 'Bronzo', value: '#cd7f32'},
  {key: 'Beige', value: '#f5f5dc'},
];

const [poligoni, setPoligoni] = useState([]);
const [polygonRef, setPolygonRef] = useState([]);
const [formData,setFormData]=React.useState({});
const [homeArea,setHomeArea]=React.useState({lat:0,lng:0});
const [savePolygon]=useMutation(TRACK_AREA)

const [ queryGetAreaData
] = useLazyQuery(GET_HOME, {
  fetchPolicy: "no-cache",
  onCompleted: (data) => {
   //   console.log(data);
   setFormData({...formData,id:data[tenant][`area${tenantTypeFirstUpperCase}`].id,name:data[tenant][`area${tenantTypeFirstUpperCase}`]["name"],type:data[tenant][`area${tenantTypeFirstUpperCase}`]?.type?.id})
    let coordinates=data[tenant][`area${tenantTypeFirstUpperCase}`]?.coordinates;
   if(coordinates){
       coordinates=coordinates.coordinates?.map((polygon)=>polygon)
      const coordsApp=[];
			for(let i=0;i<coordinates.length;i++){
				for(let z=0;z<coordinates[i].length;z++){
					const polygonPath=coordinates[i][z];
					const path=[];
					for(let j=0;j<polygonPath.length;j++){
                        	if(polygonPath[j][0]>30){
                                path.push({lat:polygonPath[j][0],lng:polygonPath[j][1]})
                            }else{
                                path.push({lat:polygonPath[j][1],lng:polygonPath[j][0]})
                            }


					}
					coordsApp.push({path:path,color:data[tenant][`area${tenantTypeFirstUpperCase}`]?.color || "#000000"});
				}
			}
      if(coordsApp){
        allShapes=[...coordsApp]
        setPoligoni(coordsApp);
      }
      const homeCoordinate=data[tenant][`area${tenantTypeFirstUpperCase}`]["recursiveHomeCoords"];
      if(homeCoordinate){
          let homeCoordApp=JSON.parse(homeCoordinate);
        setHomeArea({lat:homeCoordApp?.lat,lng:homeCoordApp?.lng})
      }else{
        setHomeArea(coordsApp[0])
      }
    }else{
       const homeCoordinate=data[tenant][`area${tenantTypeFirstUpperCase}`]["recursiveHomeCoords"];
        if(homeCoordinate) {
            let homeCoordApp = JSON.parse(homeCoordinate);
            setHomeArea({lat: homeCoordApp?.lat, lng: homeCoordApp?.lng})
        }

   }

  },
  notifyOnNetworkStatusChange: true, // did the work
});

    React.useEffect(()=>{
        queryGetAreaData({variables:{id:props?.id}})
    },[])

 var allShapes = []; //Contiene tutti i poligoni tracciati sulla mappa
 var selectedShape; //Poligono selezionato 

/**
 * Funzione che permette il salvataggio dei tracciati distingunedo due casi:
 * props.match==undefined: Non stiamo modificando un tracciato quindi aggiungo un nuovo documento
 * props.mtch!=undefined: Stiamo modificando un tracciato, aggiorno l'array e il documento
 */
  function handleSave(){
   let coords = [];
      for(let j=0;j<allShapes.length;j++){ //Prendo il path di ogni poligono
        let path = allShapes[j].getPath();
        let coordsPoligono=[[]];
        for (let i = 0; i < path.length; i++) { //Per ogni path prendo le coordinate di ogni punto
          coordsPoligono[0].push([path.getAt(i).lng(),path.getAt(i).lat()])
        }
        coordsPoligono[0].push([path.getAt(0).lng(),path.getAt(0).lat()])
        //coordsPoligono[0].push([path[0].getAt(0).lat(),path[0].getAt(0).lng()])
        coords.push(coordsPoligono); //In percorso c'è un array contente le coordinate in formato lat e lng di ogni singolo poligono, l'array è un array di poligoni
      }
      
      const multiPolygonJSON={
        "type": "MultiPolygon",
        "crs": {
          "type": "name",
          "properties": {
            "name": "urn:ogc:def:crs:EPSG::4326"
          }
        },
        "coordinates": coords
      }
       savePolygon({variables:{id:formData.id,coordinates:JSON.stringify(multiPolygonJSON),color:color}})
      .then((value)=> {
          //  console.log("value",value);
         //   queryGetHome({variables: {id: formData.id}});
            window.location.href = window.location.origin + "/modificaTraccia/" + value['data'][`update_${tenant}_area_area${tenantType}_by_pk`]?.id
            alert("Area salvata correttamente");
          })
      .catch((error)=>{alert("Errore durante l'operazione");console.error(error)})
      if(props?.match?.params?.id !== formData?.id){
        savePolygon({variables:{id:props?.match?.params?.id,coordinates:null,color:null}})
              .then((value)=> {
         //   console.log("value",value);
            //queryGetHome({variables: {id: formData.id}});
            window.location.href = window.location.origin + "/modificaTraccia/" + value['data'][`update_${tenant}_area_area${tenantType}_by_pk`]?.id
            alert("Area salvata correttamente");
          })
      }
  }

  /**
   * Funzione che cancella il poligono selezionato
   */
  function deleteSelectedShape() {
    if (!selectedShape) {
      alert("Non ci sono poligoni selezionati");
      return;
    }

    if (window.confirm("Sei sicuro di voler eliminare il poligono selezionato?")) {
      var index = allShapes.indexOf(selectedShape);
      allShapes.splice(index, 1);
      selectedShape.setMap(null);
      
    }else{
      alert("Operazione annulata")
    }
  }

  /**
   * Componente che implementa il Drawing Manager
   */
    const MapWithADirectionsRenderer =()=>{

    const [libraries] = useState(['drawing']);
    const { isLoaded } = useJsApiLoader({
      id: 'google-map-script',
      libraries,
      googleMapsApiKey: "AIzaSyBH__gEjglI0DzkCuq33ioG3_ayXHP4ojo"
    })

    const onMapLoad = map => {};


    /**
     * Annulla la selezione dei poligoni
     */
    function clearSelection() {
      if (selectedShape) {
        selectedShape.setEditable(false);
        selectedShape = null;
      }
    }


    /**
     * Cambia poligono selezionato
     */
    function setSelection(shape) {
      clearSelection();
      selectedShape = shape;
      //labelList.push(labelBuca);
      shape.setEditable(true);
    }

    /**
     * Funzione che inizializza i listener che permetton l'aggiornamento dell'array contente
     * i nuovi tracciati
     */
    const onLoad = polygon => {
      var newShape = polygon;
      polygonRef.push(polygon.getPath().getArray());
      window.google.maps.event.addListener(polygon, 'click', function() {
        setSelection(newShape);
        window.google.maps.event.addListener(polygon.getPath(), 'insert_at', function() {

        });
        window.google.maps.event.addListener(polygon.getPath(), 'set_at', function() {

        });
      });
      allShapes.push(polygon);
      setSelection(newShape);
  }


    return isLoaded?(
      <GoogleMap
      center={new window.google.maps.LatLng(homeArea.lat,homeArea.lng)}
      zoom={16}
      onLoad={map => onMapLoad(map)}
      mapContainerStyle={{ height: "700px", width: "1000px" }}
    >
      <DrawingManager
        options={{
          drawingControl: true,
          drawingControlOptions: {
            style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: window.google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [window.google.maps.drawing.OverlayType.POLYGON]
          },
          polygonOptions: {
            fillColor: color,
            fillOpacity: 0.2,
            strokeWeight: 2,
            strokeColor: color,
            clickable: true,
            editable: true,
            geodesic: false,
            visible: true,
            zIndex: 1
          }
        }}
        onOverlayComplete={(poly)=>{
          var newShape=poly.overlay;
          window.google.maps.event.addListener(newShape, 'click', function() {
            setSelection(newShape);
          });
          window.google.maps.event.addListener(newShape.getPath(), 'insert_at', function() {

          });
          window.google.maps.event.addListener(newShape.getPath(), 'set_at', function() {

          });
          allShapes.push(newShape);
          setSelection(newShape);
        }
      }
      />
            {
              poligoni.map((coordinata,index)=>{
                console.log(coordinata);
                return(
                <Polygon
                  key={index}
                  onLoad={onLoad}
                  paths= {coordinata.path}
                  editable={true}
                  zIndex={index}
                  strokeColor= {coordinata.color}
                  strokeOpacity={0.8}
                  strokeWeight={2}
                  fillColor= {coordinata.color}
                  fillOpacity={0.35}
                  >
                </Polygon>
              )}
              )
            }
    </GoogleMap>
    ):<></>}


	return(
		<Grommet theme={theme} themeMode="dark" className="font-link">
      <ResponsiveContext.Consumer>

        {size => (
        <Box direction={"raw"} gap={"medium"}>
            <Box flex="false">
                <SideBar  pathLogo="./images/Logo1.png" page="Attività" />
            </Box>
              <Box >
                  <MapWithADirectionsRenderer/>
                <Box direction="row" gap="small" alignSelf="center" >
                  <Button label={props?.match?"Salva":"Aggiorna"} size="medium" icon={<Location color="white"/>} onClick={() => handleSave()}  style={{fontSize:'20px',background:'#397aa3',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}></Button>
                  <Button label="Cancella poligono" size="medium" icon={<Close color="white"/>} onClick={() => deleteSelectedShape()}  style={{fontSize:'20px',background:'#ff0000',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}></Button>
                </Box>
              </Box>
        </Box>
        )}
      </ResponsiveContext.Consumer>
    </Grommet>   
	)}

export default AreaTrack;