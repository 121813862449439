import {Box, Button, Layer, Select, Text} from "grommet";
import TreeViewAree from "../treeView/TreeView.jsx";
import React from "react";
import {useLazyQuery} from "@apollo/client";
import {GET_TREE} from "../registryComponent/aree/areeGQL.js";
import {tenant} from "../tenant.js";

function LayerTreeFilter(props){

    const [tree,setTree]=React.useState();

    const [ queryGetTree
    ] = useLazyQuery(GET_TREE, {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
         const tree=data[`${tenant}`]["whoami"].profile.treeArea
            if(tree){
              setTree(JSON.parse(tree).tree);
            }
      },
      notifyOnNetworkStatusChange: true, // did the work
    });

    return(
        <Layer onClickOutside={props.onEscLayer}>
            <Box flex={false} style={{overflowX:"hidden"}} height="70vh" width="50vw"  pad="medium" margin="small" direction='column' gap="small" >
              <Text> Seleziona area da filtrare: </Text>
              <TreeViewAree checkAssignedAree={true} loadTree={queryGetTree} selectedNodes={props.selectedNodes} setSelectedNodes={props.setSelectedNodes} tree={tree} multiSelect={true}/>
            </Box>
            <Button margin='medium' alignSelf="center" label="Chiudi" onClick={props.onEscLayer} style={{ borderColor:"#c51d34",padding:'7px',fontSize:'15px',/*marginLeft: 'auto',*/background:'#c51d34',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}></Button>
        </Layer>
    )
}

export default LayerTreeFilter;