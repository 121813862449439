import React, { useState, useEffect } from "react";
import {DataGrid} from "@mui/x-data-grid";
import {useLazyQuery,useMutation} from "@apollo/client";
import {DELETE_MAINTENANCE, QUERY_MACHINE} from "../registryComponent/maintenance/maintenanceGQL.jsx";
import {tenant, tenantType} from "../tenant.js";
import {Box,Text} from "grommet";
import {Button as ButtonMui} from "@mui/material";
import moment from "moment";

function ExecutedMaintenanceList(props){

    const [executedMaintenances,setExecutedMaintenances] = React.useState([]);
    const [deleteMaintenance] = useMutation(DELETE_MAINTENANCE)
    const [ queryGetMaintenance
      ] = useLazyQuery(QUERY_MACHINE, {
        fetchPolicy: "no-cache",
        onCompleted: (data) => {
          const maintenance=data[`${tenant}_machines_machinearea`].map((machine)=>machine[`machines_machine${tenantType}`]["machines_maintenancegolves"]);
          if(maintenance){
              const maintenanceData=unisciListe(maintenance);
              const maintenanceApp=[];
              for (let i=0;i<maintenanceData.length;i++){
                  maintenanceApp.push({
                      id:maintenanceData[i]["id"],
                      code:maintenanceData[i]["machineType_interventiontype"]?.code,
                      datetime:moment(maintenanceData[i]["datetime"]).format("DD/MM/yyyy"),
                      machine_name:maintenanceData[i]["machines_machine"+tenantType]?.name,
                      machine_hour:maintenanceData[i].machine_hour
                  })
              }
              setExecutedMaintenances(maintenanceApp);
          }
        },
        notifyOnNetworkStatusChange: true, // did the work
      });

      React.useEffect(()=>{
        queryGetMaintenance({variables:{area:props.aree.length>0? props.aree : JSON.parse(sessionStorage.getItem("areeId"))}});
      },[props.aree])

  function unisciListe(liste) {
    const risultato = [];

    // Itera su ogni lista all'interno della lista principale
    for (let i = 0; i < liste.length; i++) {
      const listaCorrente = liste[i];

      // Aggiungi gli elementi della lista corrente al risultato
      for (let j = 0; j < listaCorrente.length; j++) {
        risultato.push(listaCorrente[j]);
      }
    }

    return risultato;
  }


  const columnsExecutedMaintenance = [
        { field: 'code', headerName: 'Codice', width: 300 },
        { field: 'datetime', headerName: 'Data', width: 300 },
        { field: 'machine_name', headerName: 'Macchinario', width: 300},
        { field: 'machine_hour', headerName: 'Ore Macchina', width: 300 },
        {
        field: 'Modifica',
        headerName: 'Modifica',
        width: 400,
        renderCell: (cellValues) => {
          return (
              <ButtonMui
                variant="contained"
                color="error"
                onClick={(event) => {
                  let res=window.confirm("Sei sicuro di voler eliminare l'intervento di manutenzione?");
                  if(res){
                      deleteMaintenance({variables:{id:cellValues.row.id}})
                          .then((val)=>{
                               queryGetMaintenance({variables:{area:props.aree.length>0? props.aree : JSON.parse(sessionStorage.getItem("areeId"))}});
                          })
                          .catch((error)=>{
                              alert("Errore nella cancellazione dell'intervento di manutenzione");
                          });
                  }
                }}
              >
                Cancella
              </ButtonMui>
          );},
        }
    ];

    return(
        <Box pad={"small"} overflow="auto"  height="97vh" width="100%" alignSelf='center' style={{marginTop:'40px'}}>
        <div style={{ height: '75vh', width: '100%' }}>
          <DataGrid
            rows={executedMaintenances || []}
            columns={columnsExecutedMaintenance}
          />
        </div>
      </Box>
    )
}

export default ExecutedMaintenanceList;