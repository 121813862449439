import * as React from 'react';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Box,CheckBox } from 'grommet';
import { CustomRightClickMenu } from '../rightClickMenu/RightClickMenu';
import PropTypes from 'prop-types';
import {shadeColor} from "../../utilis/utils";
import {Typography} from "@mui/material";
function TreeViewAree(props){

    const [anchorEl,setAnchorEl]=React.useState(null)
    const [nodeSel,setNodeSel]=React.useState({});   
    const [nodeModify,setNodeModify]=React.useState(null); 
    const [showLayerAddArea,setShowLayerAddArea]=React.useState(false);
    const [tree,setTree]=React.useState(null);
    const open = Boolean(anchorEl); 
    const Form=props.form || null; 
    const menuItem=[
        {name:"Aggiungi figlio" ,onClick: ()=>
          {
            if(props.singleChildForNode && nodeSel.children.length>0){          
              alert("L'elemento non può avere più di un figlio")
            }else{
              setAnchorEl(null);
              setShowLayerAddArea(true);
            }
          }
        },
        {name:"Modifica" ,onClick: ()=>
         {           
            setNodeModify(nodeSel);
            setAnchorEl(null);
            setShowLayerAddArea(true);   
          }
        },
        {name:"Elimina nodo",onClick:()=>{
            let confirm=window.confirm("Sei sicuro di voler eliminare "+nodeSel.name)
            if(confirm && props.deleteMutation){
                props.deleteMutation({
                  variables:{
                    id:nodeSel && nodeSel.id
                  }
                })
                .then((value)=>{
                  if(props.loadTree){
                    props.loadTree();
                  }
                  setAnchorEl(null);
                })
                .catch((err)=>{
                  console.log(err);
                  alert("Errore, controlla di non star cancellando un nodo con dei figli")
                })
            }         
        }},
        {name:"Chiudi",onClick:()=>{setAnchorEl(null); setShowLayerAddArea(false);}}
      ]

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
      
      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box sx={{ p: 3 }}>
              <Typography>{children}</Typography>
            </Box>
          )}
        </div>
      );
    }
    
    TabPanel.propTypes = {
      children: PropTypes.node,
      index: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
    };

    function onEscLayer(){
      setAnchorEl(null);
      setShowLayerAddArea(false);
      setNodeModify(null);
    }

    React.useEffect(()=>{
      if(props.loadTree){
        props.loadTree();
      }
    },[])

    React.useEffect(()=>{
      if(props.tree){
        setTree(props.tree);
      }
    },[props.tree])

    const renderTree = (node,shadow, fatherIds) => {
        let fatherIdsUpdated=[...fatherIds];
        fatherIdsUpdated.push(node.id);
        if(tree){
              const sortedChildren = Array.isArray(node.children)
                ? node.children.slice().sort((a, b) => a.name.localeCompare(b.name))
                : [];

          return(      
            <TreeItem key={node.id} nodeId={node.id} label={customLabel(node,shadow,fatherIds)}>
              {Array.isArray(sortedChildren)                            //shadow indica la prima iterazione in questo caso
                ? sortedChildren.map((child) =>{
                    return(
                        renderTree(child,shadow+15, fatherIdsUpdated))
                  })
                : null}
            </TreeItem>
          )
        }
      return null;
    };   
    
    //BFS algorithm to find node by his ID
  const bfsSearch = (graph, targetId) => {
    const queue = [...graph];

    while (queue.length > 0) {
      const currNode = queue.shift();
      if (currNode.id === targetId ) {
        return currNode;
      }
      if (currNode.children) {
        queue.push(...currNode.children);
      }
    }
    return []; // Target node not found
  };

  function getAllIds(node, idList = []) {
      idList.push(node.id);
      if (node.children) {
        node.children.forEach((child) => getAllIds(child, idList));
      }
      return idList;
    }

    const getAllChild = (id) => {
      return getAllIds(bfsSearch(tree, id));
    };
  
    // Get all father IDs from specific node
    const getAllFathers = (id, list = []) => {
      const node = bfsSearch(tree, id);
      if (node.parent) {
        list.push(node.parent);
  
        return getAllFathers(node.parent, list);
      }
  
      return list;
    };
  
    function isAllChildrenChecked(node, list) {
      const allChild = getAllChild(node.id);
      const nodeIdIndex = allChild.indexOf(node.id);
      allChild.splice(nodeIdIndex, 1);
  
      return allChild.every((nodeId) =>
       props.selectedNodes.concat(list).includes(nodeId)
      );
    }

    const handleNodeSelect = (event, nodeId, fathersIds) => {
        if(props.clickedNodes && event.target.checked){ //Caso in cui mi servano solo i nodi cliccati dall'utente
            let clickedNodes=[...props.clickedNodes];
            clickedNodes.push(nodeId);
            props.setClickedNodes(clickedNodes)
        }else if(props.clickedNodes && !event.target.checked){ //Caso in cui mi servano solo i nodi cliccati dall'utente e il nodo viene deselezionato
            let clickedNodes=[...props.clickedNodes];
            const index = clickedNodes.indexOf(nodeId);
            clickedNodes.splice(index, 1);
            props.setClickedNodes(clickedNodes)
        }
      if(props.multiSelect){
        event.preventDefault();
        event.stopPropagation();
        const allChild = getAllChild(nodeId);
        const fathers = getAllFathers(nodeId);

        if (props.selectedNodes.includes(nodeId)) {
          // Need to de-check
          props.setSelectedNodes((prevSelectedNodes) =>
            prevSelectedNodes.filter((id) => !allChild.concat(fathers).includes(id))
          );
        } else {
          // Need to check
          const ToBeChecked = allChild;
          for (let i = 0; i < fathers.length; ++i) {
            if (isAllChildrenChecked(bfsSearch(tree, fathers[i]), ToBeChecked)) {
              ToBeChecked.push(fathers[i]);
            }
          }
          props.setSelectedNodes((prevSelectedNodes) =>
            [...prevSelectedNodes].concat(ToBeChecked).concat(fathersIds)
          );
        }
      }else if(props.singleSelect){
        if(props.selectedNodes && props.selectedNodes[0] && props.selectedNodes[0]===nodeId){
          props.setSelectedNodes([]);
        }else{
          props.setSelectedNodes([nodeId])
        }
      }
      
    };

    const customLabel=(node,shadow,fatherIds)=>{
        return(
          <Box 
            pad="small"
            round="medium"
            elevation='medium'
            width="95%"
            direction='row'
            onContextMenu={(e) => {
              e.preventDefault(); // prevent the default behaviour when right clicked
              setNodeSel(node); 
              setAnchorEl(e.currentTarget);
            }}
           background={shadeColor("#397aa3",shadow)}
           style={{
            display: 'inline-flex',
            VerticalAlign: 'text-bottom',
            BoxSizing: 'inherit',
            textAlign: 'start',
            AlignItems: 'start',
            justifyContent: 'space-between'
        }}>   
            {
              props.multiSelect || (props.singleSelect && node.children.length===0)?
              <CheckBox
                checked={props?.selectedNodes?.indexOf(node?.id) !== -1 || false}
                tabIndex={-1}
                disableRipple
                onClick={(event) => handleNodeSelect(event, node.id, fatherIds || [])}
              />
              :
              null
            } 
            <Typography align="start" style={{fontWeight:"bold",color:"white"}} variant="inherit">{node.name.toUpperCase()}</Typography>      
        </Box>  
          );
      }

    return(
    <Box height="80vh" width="100%">
        <TreeView
          multiSelect={props.multiSelect}
          defaultCollapseIcon={<ArrowDropDownIcon />}
          defaultExpandIcon={<ArrowRightIcon />}
          defaultEndIcon={<div style={{ width: 24 }} />}
          selected={props.multiSelect?props.selectedNodes:null}
        >   
          {tree && Array.isArray(tree) && tree.map((node)=>renderTree(node ,0,[]))}
        </TreeView>
        {
            showLayerAddArea && <Form onEscLayer={onEscLayer} father={nodeSel} nodeModify={nodeModify} loadTree={props.loadTree}/>
        }
        <CustomRightClickMenu node={nodeSel} open={props.addElement && open} anchorEl={anchorEl} setAnchorEl={setAnchorEl} menuItems={menuItem}/>
    </Box>
    )
}

export default TreeViewAree;