import {Button, Card, CardBody, CardFooter, CardHeader, Heading} from "grommet";
import React from "react";

const CardHome = (props) =>{
    return(
            <Card background="accent-1">
                <CardHeader alignSelf="center" pad="medium"><Heading level="3" margin="xsmall">{props?.name}</Heading></CardHeader>
                <CardBody pad="medium" alignSelf="center"><Button icon={props?.icon} onClick={() => props.setPage(props?.pageName)}/></CardBody>
                <CardFooter pad={{horizontal: "small"}} background="brand"></CardFooter>
            </Card>

    )
}

export default CardHome;